import React, { useEffect, useContext, useState, useRef, useCallback } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import type { PDFDocumentProxy, PDFPageProxy } from "pdfjs-dist";
import styles from "../ViewerPDF/ViewerPDF.module.css";
import stylesXplrr from "./XplrrBOXPDF.module.css";
import { GlobalContext } from "../../GlobalContext";
import { gfile, gpagedimensions } from "../../interfaces";
import axios from "axios";
import debounce from "lodash/debounce";
import { ProgressIndicator } from "@fluentui/react/lib/ProgressIndicator";
import smoothScrollIntoView from "smooth-scroll-into-view-if-needed";
import { ZIndexes } from "@fluentui/react";
import { ImFilesEmpty } from "react-icons/im";
import { MdErrorOutline } from "react-icons/md";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

(pdfjs.GlobalWorkerOptions as any).fontExtraProperties = true;
(pdfjs.GlobalWorkerOptions as any).disableFontFace = false;
(pdfjs.GlobalWorkerOptions as any).useSystemFonts = true;

let timeoutId: number;
interface LoadSuccess {
    pdf: PDFDocumentProxy;
}
const contentPath = (filename: string, fileid: string) => {
    return `${filename}_fid=${fileid}`;
};

const pdfCacheKey = (fId: string, modifiedDateString: string) => {
    try {
        let mdfd = new Date(modifiedDateString).getTime();
        return `${fId}_${mdfd}`;
    } catch {
        return `${fId}`;
    }
};
interface TextItem {
    pageIndex: number;
    pageNumber: number;
    itemIndex: number;
    str: string;
}
interface PDFBoxDetails {
    file: gfile | undefined;
    handleTimeout: () => void;
    handleScroll: (pageNumCur: number) => void;
    setPages: (numPages: number) => void;
    setContentList: (contentList: { title: string; dest: number }[]) => void;
    startPage: number;
    activeFileId: string;
    callBackVisibleEntries: (vis: number[]) => void;
    callBackIsLoading: (isL: boolean) => void;
    srcLoc: string;
    companyId: string;
}
interface PDFPageDetails {
    handleTimeout: () => void;
    setContentList: (contentList: { title: string; dest: number }[]) => void;
    nums: number;
    pdf: PDFDocumentProxy;
    startPage: number;
    fileId: string;
    fileIndexChunks: number[];
    filePageDimsState: gpagedimensions[] | [];
}
function areAllFlagsTrue(map: Map<number, { count: number; flag: boolean }>, numbers: number[]): boolean {
    for (const number of numbers) {
        const item = map.get(number);
        if (!item || !item.flag) {
            return false;
        }
    }
    return true;
}
function findRange(numbers: number[], target: number): number[] {
    numbers.sort((a: number, b: number) => a - b);
    let startIndex = numbers.findIndex((num: number) => num > target);
    let upper = startIndex === -1 ? numbers[numbers.length - 1] : numbers[startIndex];
    let endIndex = numbers.lastIndexOf(target);
    let lower = endIndex !== -1 ? numbers[endIndex] : numbers[startIndex - 1];
    if (lower === undefined || upper === undefined) {
        return [];
    }
    let result = [];
    for (let i = lower; i <= upper; i++) {
        result.push(i - 1);
    }
    if (target === upper) {
        result.push(upper - 1);
    }
    return result;
}
const getDesiredWidth = (srcLoc: string): number => {
    let widthFactor = 1.9;
    if (srcLoc === "modal" || srcLoc === "inv") {
        // Directly related to components\XplrrPDF\XplrrPDF.module.css - pdfViewC

        let modifierReduct = 1;
        if (srcLoc === "inv") {
            modifierReduct = 1.8;
        }

        if (window.innerWidth > 1800) {
            widthFactor = 1.8 * modifierReduct;
        } else if (window.innerWidth > 1600) {
            widthFactor = 1.6 * modifierReduct;
        } else if (window.innerWidth > 1400) {
            widthFactor = 1.4 * modifierReduct;
        } else if (window.innerWidth > 1200) {
            widthFactor = 1.3 * modifierReduct;
        } else if (window.innerWidth > 1000) {
            widthFactor = 1.2 * modifierReduct;
        } else if (window.innerWidth > 800) {
            widthFactor = 1.13 * modifierReduct;
        } else if (window.innerWidth > 600) {
            widthFactor = 1.08 * modifierReduct;
        } else if (window.innerWidth > 470) {
            widthFactor = 1.1 * modifierReduct;
        } else if (window.innerWidth > 350) {
            widthFactor = 1.11 * modifierReduct;
        } else if (window.innerWidth > 300) {
            widthFactor = 1.15 * modifierReduct;
        } else {
            //Smallest
            widthFactor = 1.15 * modifierReduct;
        }
    } else {
        // "sbs"
        if (window.innerWidth > 800) {
            widthFactor = 1.85;
        } else {
            //Smallest
            widthFactor = 1.85;
        }
    }
    try {
        return window.innerWidth / widthFactor;
    } catch (e) {
        console.log("gtDsirdWdth Error", e);
        return 800;
    }
};
const getDesiredHeight = (pageDims: gpagedimensions, srcLoc: string) => {
    const A4_ASPECT_RATIO = 297 / 210;
    const originalWidth = pageDims?.width;
    const originalHeight = pageDims?.height;
    const desiredWidth = getDesiredWidth(srcLoc);

    if (originalWidth && originalHeight) {
        const aspectRatio = originalHeight / originalWidth;
        return desiredWidth * aspectRatio;
    }

    return desiredWidth * A4_ASPECT_RATIO;
};
const XplrrBOXPDF = ({
    file,
    handleTimeout,
    handleScroll,
    setPages,
    setContentList,
    startPage,
    activeFileId,
    callBackVisibleEntries,
    callBackIsLoading,
    srcLoc,
    companyId
}: PDFBoxDetails) => {
    const [progress, setProgress] = useState<number>();
    const [pageN, setPageN] = useState<number>(startPage);
    const [fileId, setFileId] = useState<string>(activeFileId);
    const [isLoa, setIsLoa] = useState(true);
    const [fileIndexChunks, setFileIndexChunks] = useState<number[]>([]);
    const [pgs, setPgs] = useState<React.ReactElement<any, any>[]>([]);
    const [isBlobPulling, setIsBlobPulling] = useState<boolean>(false);
    const [loadingMessage, setLoadingMessage] = useState<string>(activeFileId);
    const pageRefs = useRef<(Element | null)[]>([]);
    const observerRef = useRef<IntersectionObserver | null>(null);
    const containerRef = useRef(null);

    const [resizeCount, setResizeCount] = useState(0);
    const [scrollTr, setScrollTr] = useState("");
    const [rescaleWidth, setRescaleWidth] = useState<number>(getDesiredWidth(srcLoc));

    const [visiblePages, setVisiblePages] = useState<number[]>([]);
    const [filePageDimsState, setFilePageDimsState] = useState<gpagedimensions[] | []>([]);
    const [loadMark, setLoadMark] = useState<string>();
    const [onDemandRefresh, setOnDemandRefresh] = useState<number>(0);

    const { fileList } = useContext(GlobalContext);

    useEffect(() => {
        const handleResize = debounce(() => {
            console.log("¦RESIZE¦");
            setResizeCount(prevCount => prevCount + 1);
            setRescaleWidth(getDesiredWidth(srcLoc));
        }, 100);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
            handleResize.cancel();
        };
    }, []);

    const saveEntriesToGlobal = (currentEntries: number[]) => {
        window.globalCurrentVisibleEntries = currentEntries;
        //process.env.NODE_ENV === "development" && console.log("Observer¦Visible", currentEntries);
    };

    useEffect(() => {
        //console.log("XPLR_BOX¦Observer¦activeFileId", activeFileId, "resizeCount", resizeCount, "companyID", companyId);
        if (!activeFileId) return; // !!!!!!

        let visibleEntries: number[] = [];
        let cacheEntries: number[] = [];

        observerRef.current = new IntersectionObserver(
            entries => {
                let currentEntries: number[] = [];
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const pageNumber = parseInt((entry.target as HTMLElement).dataset.pageNumber || "1", 10);
                        if (!visibleEntries.includes(pageNumber)) {
                            visibleEntries.push(pageNumber);
                        }

                        currentEntries.push(pageNumber);
                    }
                });
                visibleEntries = visibleEntries.sort((a, b) => a - b);
                if (currentEntries.length < 1) {
                    currentEntries = cacheEntries;
                }
                visibleEntries = currentEntries;
                const minVisible = Math.min(...visibleEntries);
                const maxVisible = Math.max(...visibleEntries);
                for (let i = 1; i <= 5; i++) {
                    visibleEntries.push(maxVisible + i);
                    visibleEntries.push(minVisible - i);
                }

                if (currentEntries.length > 0) {
                    cacheEntries = currentEntries;
                }

                callBackVisibleEntries(visibleEntries);
                saveEntriesToGlobal(currentEntries);
                //console.log("currentEntries", currentEntries);
                //console.log("visiblePages", visiblePages);
            },
            { root: containerRef.current, rootMargin: "1000px 0px" }
        );

        pageRefs.current.forEach(ref => {
            if (ref) observerRef?.current?.observe(ref);
        });

        /*
        return () => {
            console.log("CLEAR_setFilePageDimsState");

            setFilePageDimsState([]);
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
        };
        */
    }, [activeFileId, resizeCount]);

    const wigglePixel = async () => {
        if (window.globalWigglePixel == 1) {
            return;
        }
        window.globalWigglePixel = 1;
        while (window.globalWigglePixel == 1) {
            //console.log("¦WP¦");
            await document.getElementById("pdfmainid9")?.scrollBy(1, 0);
            await new Promise(r => setTimeout(r, 100));

            await document.getElementById("pdfmainid9")?.scrollBy(-1, 0);
            await new Promise(r => setTimeout(r, 100));
        }
        window.globalWigglePixel = 0;
    };

    useEffect(() => {
        callBackIsLoading(true);
        wigglePixel();
        setLoadMark(`${file ? "fileY" : "fileN"}_${activeFileId}_${startPage}_${window.globalBase64String?.length}`);

        if (!file) return;

        //console.log("X¦XPLR_BOX¦", file);
        if (file.indexchunks && startPage) {
            setFileIndexChunks(findRange(file.indexchunks, startPage));
        } else {
            setFileIndexChunks([startPage - 1]);
        }
        const cacheBase64Wndw = async (fl: gfile): Promise<boolean> => {
            if (!fl || !fl.fileid || !fl.modified) {
                return false;
            }
            setIsBlobPulling(true);
            process.env.NODE_ENV === "development" && console.log("cacheBase64Wndw", fl);
            if (window.globalPDFCache === undefined) {
                window.globalPDFCache = new Map<
                    string,
                    {
                        base64string: string;
                        fileid: string;
                        name: string;
                        modified: string;
                        pageContent: Array<{ page_num: string; text: string }>;
                        pageDimensions: Array<{ page_number: string; width: number; height: number }>;
                    }
                >();
            }
            if (window.globalPreLoadMap === undefined) {
                window.globalPreLoadMap = new Map<string, string>();
            }
            const cacheKey = pdfCacheKey(fl.fileid, fl.modified);
            const flPath = contentPath(fl.name, fl.fileid);
            if (!window.globalPDFCache.has(cacheKey) && window.globalPreLoadMap.has(cacheKey)) {
                // File loading right now
                process.env.NODE_ENV === "development" && console.log("B64CSH⇒File Loading Right Now");
                return false;
            }
            if (window.globalPDFCache.has(cacheKey)) {
                setLoadingMessage(`CONTENT FETCH ${Math.round(parseInt(fl.size) / (1024 * 1024))}MB`);

                process.env.NODE_ENV === "development" && console.log("XPLRBX¦Cache_Key_Found", cacheKey);
                const cacheVal = window.globalPDFCache.get(cacheKey)?.base64string;
                if (!cacheVal || cacheVal == "" || cacheVal == undefined) {
                    window.globalPDFCache.delete(cacheKey);
                    window.globalPreLoadMap.delete(cacheKey);
                    return false;
                }
                let pgDms = window.globalPDFCache.get(cacheKey)?.pageDimensions;
                if (!pgDms || pgDms.length == 0) {
                    pgDms = fl.pagedimensions;
                }
                window.globalPDFPageDimensions = pgDms;

                window.globalBase64String = `data:application/pdf;base64,${cacheVal}`;
                setOnDemandRefresh(prevNum => prevNum + 1);
                window.globalBase64StringId = cacheKey;
                return true;
            } else {
                window.globalPreLoadMap.set(cacheKey, cacheKey);

                let mdfd = new Date(fl.modified).getTime();
                const params = new URLSearchParams({
                    cachemodstring: mdfd.toString()
                });
                let flSz = Math.round(parseInt(fl.size) / (1024 * 1024));
                setLoadingMessage(`INITIAL LOAD ${flSz}MB ${flSz > 5 ? "PLEASE WAIT..." : ""}`);

                const base64 = await downloadFile(`/content/${companyId}/${flPath}` + `?${params.toString()}`);
                if (!base64 || base64 == "" || base64 == undefined) {
                    window.globalPDFCache.delete(cacheKey);
                    window.globalPreLoadMap.delete(cacheKey);
                    return false;
                }
                window.globalPDFPageDimensions = fl.pagedimensions ?? [];
                window.globalBase64String = `data:application/pdf;base64,${base64}`;
                window.globalBase64StringId = cacheKey;
                window.globalPDFCache.set(cacheKey, {
                    base64string: `${base64}`,
                    fileid: fl.fileid,
                    name: fl.name,
                    modified: fl.modified,
                    pageContent: fl.pagecontent,
                    pageDimensions: fl.pagedimensions
                });
                process.env.NODE_ENV === "development" && console.log("XPLRBX¦BASE64_SET", flPath);
                setIsBlobPulling(false);
                setIsLoa(false);
                setProgress(100);
                setLoadMark(`${file ? "fileY" : "fileN"}_${activeFileId}_${startPage}_${window.globalBase64String?.length}`);
                setOnDemandRefresh(prevNum => prevNum + 1);
                return true;
            }
            return false;
        };

        if (activeFileId != null && activeFileId != "" && activeFileId != undefined) {
            cacheBase64Wndw(file).then((opResult: boolean) => {
                if (opResult == true) {
                    //console.log("XPLRR_BOX¦CACHE_BASE64_WINDOW¦SUCCESS");
                    setFileId(activeFileId);
                    setIsLoa(false);
                }
                callBackIsLoading(false);
                window.globalWigglePixel = 0;
            });
        } else {
            callBackIsLoading(false);
            window.globalWigglePixel = 0;
        }
    }, [activeFileId, resizeCount]);

    useEffect(() => {}, [pageN, fileId]);

    const startYRef = useRef<number | null>(null);
    const startScrollTopRef = useRef<number | null>(null);

    const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>): void => {
        console.log("Touch Start");
        startYRef.current = e.touches[0].pageY;
        startScrollTopRef.current = e.currentTarget.scrollTop;
    };

    const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>): void => {
        console.log("Touch Move");
        if (startYRef.current === null || startScrollTopRef.current === null) {
            return;
        }

        const deltaY = e.touches[0].pageY - startYRef.current;
        const newScrollTop = startScrollTopRef.current - deltaY;

        const element = e.currentTarget;
        const maxScrollTop = element.scrollHeight - element.clientHeight;

        if ((newScrollTop > 0 && newScrollTop < maxScrollTop) || (newScrollTop <= 0 && deltaY < 0) || (newScrollTop >= maxScrollTop && deltaY > 0)) {
            //e.preventDefault(); // Prevent default only when scrolling within bounds
        }

        element.scrollTop = newScrollTop;
    };

    const handleTouchEnd = (): void => {
        console.log("Touch End");
        startYRef.current = null;
        startScrollTopRef.current = null;
    };

    const preparePdfPage = ({ handleTimeout, nums, setContentList, pdf, startPage, fileId, fileIndexChunks, filePageDimsState }: PDFPageDetails) => {
        process.env.NODE_ENV === "development" && console.log("BOX¦preparePdfPages");
        const pages = [];
        window.globalItemCounts = new Map<
            number,
            {
                count: number;
                flag: boolean;
            }
        >();

        for (let i = 0; i < nums; i++) {
            pages.push(
                <Page
                    key={i + 1}
                    pageNumber={i + 1}
                    style={{ overflowY: true, behavior: "smooth", border: "1px solid black" }}
                    width={getDesiredWidth(srcLoc)}
                    loadingText={<div className={styles.loadingText}>LDNG</div>}
                    noData={<div className={styles.loadingText}>LDNG</div>}
                    onPageChange={(page: any) => {
                        console.log("Page Change!, ", page);
                    }}
                    //onGetTextSuccess={({ items, styles }) => {
                    onGetTextSuccess={(items: any, styles: any) => {
                        window.globalItemCounts.set(i, {
                            count: items.items.length,
                            flag: false
                        });
                    }}
                    customTextRenderer={({ pageIndex, pageNumber, itemIndex, str }: TextItem) => {
                        const preparePdfPage = ({
                            handleTimeout,
                            nums,
                            setContentList,
                            pdf,
                            startPage,
                            fileId,
                            fileIndexChunks,
                            filePageDimsState
                        }: PDFPageDetails) => {
                            process.env.NODE_ENV === "development" && console.log("BOX¦preparePdfPages");
                            const pages = [];
                            window.globalItemCounts = new Map<
                                number,
                                {
                                    count: number;
                                    flag: boolean;
                                }
                            >();

                            for (let i = 0; i < nums; i++) {
                                pages.push(
                                    <Page
                                        key={i + 1}
                                        pageNumber={i + 1}
                                        style={{ overflowY: true, behavior: "smooth", border: "1px solid black" }}
                                        width={getDesiredWidth(srcLoc)}
                                        loadingText={<div className={styles.loadingText}>LDNG</div>}
                                        noData={<div className={styles.loadingText}>LDNG</div>}
                                        onPageChange={(page: any) => {
                                            console.log("Page Change!, ", page);
                                        }}
                                        //onGetTextSuccess={({ items, styles }) => {
                                        onGetTextSuccess={(items: any, styles: any) => {
                                            window.globalItemCounts.set(i, {
                                                count: items.items.length,
                                                flag: false
                                            });
                                        }}
                                        customTextRenderer={({ pageIndex, pageNumber, itemIndex, str }: TextItem) => {
                                            const preparePdfPage = ({
                                                handleTimeout,
                                                nums,
                                                setContentList,
                                                pdf,
                                                startPage,
                                                fileId,
                                                fileIndexChunks,
                                                filePageDimsState
                                            }: PDFPageDetails) => {
                                                process.env.NODE_ENV === "development" && console.log("BOX¦preparePdfPages");
                                                const pages = [];
                                                window.globalItemCounts = new Map<
                                                    number,
                                                    {
                                                        count: number;
                                                        flag: boolean;
                                                    }
                                                >();

                                                for (let i = 0; i < nums; i++) {
                                                    pages.push(
                                                        <Page
                                                            key={i + 1}
                                                            pageNumber={i + 1}
                                                            style={{ overflowY: true, behavior: "smooth", border: "1px solid black" }}
                                                            width={getDesiredWidth(srcLoc)}
                                                            loadingText={<div className={styles.loadingText}>LDNG</div>}
                                                            noData={<div className={styles.loadingText}>LDNG</div>}
                                                            onPageChange={(page: any) => {
                                                                console.log("Page Change!, ", page);
                                                            }}
                                                            //onGetTextSuccess={({ items, styles }) => {
                                                            onGetTextSuccess={(items: any, styles: any) => {
                                                                window.globalItemCounts.set(i, {
                                                                    count: items.items.length,
                                                                    flag: false
                                                                });
                                                            }}
                                                            customTextRenderer={({ pageIndex, pageNumber, itemIndex, str }: TextItem) => {
                                                                if (itemIndex == (window.globalItemCounts.get(i)?.count || 0) - 1) {
                                                                    setOnDemandRefresh(prevNum => prevNum + 1);
                                                                    window.globalItemCounts.set(i, {
                                                                        count: window.globalItemCounts.get(i)?.count || 0,
                                                                        flag: true
                                                                    });

                                                                    if (areAllFlagsTrue(window.globalItemCounts, fileIndexChunks)) {
                                                                        if (window.globalAllNessesaryPDFPagesLoaded == false) {
                                                                            window.globalAllNessesaryPDFPagesLoaded = true;
                                                                            try {
                                                                                //process.env.NODE_ENV === "development" && console.log("XPLRRBOX_CLEAR_TMOT");
                                                                                clearTimeout(timeoutId); // Clear previous timeout
                                                                            } catch {}
                                                                            timeoutId = setTimeout(handleTimeout, 100) as unknown as number;
                                                                        }
                                                                    }
                                                                }

                                                                // return `<span id='CSP_${pageIndex}_${itemIndex}'>${str}</span>`;
                                                                return `<span id='CSP_${pageIndex}_${itemIndex}' class='pdf_itm_spans pdf_itm_${pageIndex}'>${str}</span>`;
                                                            }}
                                                            onRenderSuccess={(pdfPage: PDFPageProxy) => {
                                                                if (pdfPage.pageNumber == startPage) {
                                                                    console.log("XPLRR_PDF_BOX¦RENDER_SUCCESS⇒startPage", startPage);
                                                                    var pageElement = document.querySelector(`[data-page-number="${startPage}"`);
                                                                    //pageElement?.scrollIntoView({ behavior: "smooth" });
                                                                    //if (pageElement) {
                                                                    //    smoothScrollIntoView(pageElement as HTMLElement, { behavior: "smooth", block: "center" });
                                                                    //}
                                                                }
                                                                if (i == nums - 1) {
                                                                    console.log("rndr_scss");
                                                                }
                                                            }}
                                                        />
                                                    );
                                                }
                                                return pages;
                                            };

                                            if (itemIndex == (window.globalItemCounts.get(i)?.count || 0) - 1) {
                                                window.globalItemCounts.set(i, {
                                                    count: window.globalItemCounts.get(i)?.count || 0,
                                                    flag: true
                                                });

                                                if (areAllFlagsTrue(window.globalItemCounts, fileIndexChunks)) {
                                                    if (window.globalAllNessesaryPDFPagesLoaded == false) {
                                                        window.globalAllNessesaryPDFPagesLoaded = true;
                                                        try {
                                                            //process.env.NODE_ENV === "development" && console.log("XPLRRBOX_CLEAR_TMOT");
                                                            clearTimeout(timeoutId); // Clear previous timeout
                                                        } catch {}
                                                        timeoutId = setTimeout(handleTimeout, 100) as unknown as number;
                                                    }
                                                }
                                            }

                                            // return `<span id='CSP_${pageIndex}_${itemIndex}'>${str}</span>`;
                                            return `<span id='CSP_${pageIndex}_${itemIndex}' class='pdf_itm_spans pdf_itm_${pageIndex}'>${str}</span>`;
                                        }}
                                        onRenderSuccess={(pdfPage: PDFPageProxy) => {
                                            if (pdfPage.pageNumber == startPage) {
                                                console.log("XPLRR_PDF_BOX¦RENDER_SUCCESS⇒startPage", startPage);
                                                var pageElement = document.querySelector(`[data-page-number="${startPage}"`);
                                                //pageElement?.scrollIntoView({ behavior: "smooth" });
                                                //if (pageElement) {
                                                //    smoothScrollIntoView(pageElement as HTMLElement, { behavior: "smooth", block: "center" });
                                                //}
                                            }
                                            if (i == nums - 1) {
                                                console.log("rndr_scss");
                                            }
                                        }}
                                    />
                                );
                            }
                            return pages;
                        };

                        if (itemIndex == (window.globalItemCounts.get(i)?.count || 0) - 1) {
                            window.globalItemCounts.set(i, {
                                count: window.globalItemCounts.get(i)?.count || 0,
                                flag: true
                            });

                            if (areAllFlagsTrue(window.globalItemCounts, fileIndexChunks)) {
                                if (window.globalAllNessesaryPDFPagesLoaded == false) {
                                    window.globalAllNessesaryPDFPagesLoaded = true;
                                    try {
                                        //process.env.NODE_ENV === "development" && console.log("XPLRRBOX_CLEAR_TMOT");
                                        clearTimeout(timeoutId); // Clear previous timeout
                                    } catch {}
                                    timeoutId = setTimeout(handleTimeout, 100) as unknown as number;
                                }
                            }
                        }

                        // return `<span id='CSP_${pageIndex}_${itemIndex}'>${str}</span>`;
                        return `<span id='CSP_${pageIndex}_${itemIndex}' class='pdf_itm_spans pdf_itm_${pageIndex}'>${str}</span>`;
                    }}
                    onRenderSuccess={(pdfPage: PDFPageProxy) => {
                        if (pdfPage.pageNumber == startPage) {
                            console.log("XPLRR_PDF_BOX¦RENDER_SUCCESS⇒startPage", startPage);
                            var pageElement = document.querySelector(`[data-page-number="${startPage}"`);
                            //pageElement?.scrollIntoView({ behavior: "smooth" });
                            //if (pageElement) {
                            //    smoothScrollIntoView(pageElement as HTMLElement, { behavior: "smooth", block: "center" });
                            //}
                        }
                        if (i == nums - 1) {
                            console.log("rndr_scss");
                        }
                    }}
                />
            );
        }
        return pages;
    };

    const handleLoadSuccess = async (pdfObject: PDFDocumentProxy) => {
        console.log("ReactPDF¦Load_Success", pdfObject.numPages);
        const numPages = pdfObject.numPages;
        window.globalNumPages = numPages;
        const pgs = preparePdfPage({ handleTimeout, nums: numPages, setContentList, pdf: pdfObject, startPage, fileId, fileIndexChunks, filePageDimsState });

        setPgs(pgs);
        setFilePageDimsState(window.globalPDFPageDimensions);
        setOnDemandRefresh(prevNum => prevNum + 1);
        setTimeout(() => {
            setOnDemandRefresh(prevNum => prevNum + 1);
        }, 100);
        setPages(numPages);
    };
    const downloadFile = async (url: string): Promise<string | null> => {
        console.log("XLRR_BOX_downloadFile", url);
        try {
            setProgress(undefined);
            const response = await fetch(url);
            if (response && response.body) {
                const reader = response.body.getReader();
                const contentLengthHeader = response.headers.get("Content-Length");
                const contentLength = contentLengthHeader ? parseInt(contentLengthHeader, 10) : 0;

                let receivedLength = 0;
                const chunks: Uint8Array[] = [];

                while (true) {
                    const { done, value } = await reader.read();
                    if (done) break;
                    chunks.push(value);
                    receivedLength += value.length;
                    setProgress(receivedLength / contentLength);
                    //console.log("DWNLD_PRGRS", receivedLength / contentLength);
                    setLoadingMessage(prevMessage => prevMessage.replace("INITIAL LOAD", "DOWNLOADING ON DEVICE"));
                }
                console.log("DWNLD_CMPLT");
                const allChunks = new Uint8Array(receivedLength);
                let position = 0;
                for (const chunk of chunks) {
                    allChunks.set(chunk, position);
                    position += chunk.length;
                }
                const chunkSize = 0x8000; // 32KB chunks
                let base64 = "";
                for (let i = 0; i < allChunks.length; i += chunkSize) {
                    const chunk = allChunks.subarray(i, i + chunkSize);
                    base64 += String.fromCharCode.apply(null, chunk as unknown as number[]);
                }

                return btoa(base64);
            }
        } catch (err) {
            console.error("Download failed:", err);
        }
        return null;
    };
    const fetchBase64 = async (url: string) => {
        try {
            const response = await axios.get(url, {
                responseType: "blob" // Change responseType to "blob"
            });
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => (reader.result ? resolve(reader.result.toString().split(",")[1]) : ""); // Get Base64 part
                reader.onerror = reject;
                reader.readAsDataURL(response.data); // Read the blob as Data URL
            });
        } catch (error) {
            console.error("Error fetching the PDF:", error);
            return null;
        }
    };
    const addToObserver = useCallback((ref: any, index: number) => {
        if (ref) {
            pageRefs.current[index] = ref;
            ref.dataset.pageNumber = (index + 1).toString();

            if (observerRef.current) {
                observerRef.current.observe(ref);
            }
        }
    }, []);

    function getCurrentPage(pageDimensions: gpagedimensions[], scrollTop: number, clientHeight: number): number {
        let cumulativeHeight = 0;
        const middleOfViewport = scrollTop + clientHeight / 2;

        for (let i = 0; i < pageDimensions.length; i++) {
            cumulativeHeight += getDesiredHeight(pageDimensions[i], srcLoc);
            if (cumulativeHeight > middleOfViewport) {
                return i + 1; // +1 because page numbers typically start at 1, not 0
            }
        }

        // If we've gone through all pages, we're at the last page
        return pageDimensions.length;
    }

    const handleScrollCallAdv = (event: any) => {
        let { scrollTop, clientHeight, scrollHeight } = event.currentTarget;

        handleScroll(getCurrentPage(filePageDimsState, scrollTop, clientHeight));
        debounce(() => {
            setScrollTr(new Date().toISOString());
        }, 50)();
    };

    const handleScrollCall = (event: any) => {
        let { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
        let pageHeight = (scrollHeight ? scrollHeight : 1) / (window.globalNumPages ? window.globalNumPages : 1);
        let pp = Math.ceil((scrollTop + clientHeight - clientHeight / 3) / pageHeight);

        handleScroll(pp);
        console.log("SCROLLING", pp);
        debounce(() => {
            setScrollTr(new Date().toISOString());
        }, 50)();
    };
    const options = {
        fontExtraProperties: true
    };
    return (
        <div id="pdf-viewer" className={stylesXplrr.pdfViewer}>
            {fileList.length == 0 ? (
                <div className={stylesXplrr.noDocs}>
                    <div className={stylesXplrr.iconNoContent}>
                        <ImFilesEmpty size={24} />
                    </div>
                    <div>Space Empty. Upload documents from the Management Tab</div>
                </div>
            ) : (
                <div className={styles.loadingIndicatorContainer}>
                    <div
                        key={rescaleWidth}
                        className={`${
                            srcLoc == "modal"
                                ? stylesXplrr.loadingIndicator
                                : srcLoc == "sbs"
                                ? stylesXplrr.loadingIndicatorStatic
                                : srcLoc == "inv" && file?.invoicedata != null
                                ? stylesXplrr.loadingIndicatorInv
                                : srcLoc == "inv"
                                ? stylesXplrr.loadingIndicatorInvEmpty
                                : stylesXplrr.loadingIndicatorPreLoad
                        } `}
                    >
                        {/*isBlobPulling ? "BLOB PULL START" : "BLOB PULL END"*/}
                        <ProgressIndicator percentComplete={progress} barHeight={20} progressHidden={progress == 1 ? true : false} />
                    </div>
                    <div
                        className={`${
                            srcLoc == "modal"
                                ? stylesXplrr.loadingInfo
                                : srcLoc == "sbs"
                                ? stylesXplrr.loadingInfoStatic
                                : srcLoc == "inv" && file?.invoicedata != null
                                ? stylesXplrr.loadingInfoStatic
                                : srcLoc == "inv"
                                ? stylesXplrr.loadingInfoStaticEmpty
                                : stylesXplrr.loadingInfo
                        }`}
                    >
                        {loadingMessage}
                        {loadingMessage == "ERROR LOADING PDF" ? null : (
                            <div className={stylesXplrr.typingDots}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        )}
                    </div>
                </div>
            )}
            {window.globalBase64String == "data:application/pdf;base64," ? null : (
                <div id={fileId} className={stylesXplrr.pdfViewerSub} ref={containerRef}>
                    {/* PDFBOX HEIGHT <PDFExtractor base64String={base64String} />*/}

                    <Document
                        key={rescaleWidth}
                        className={stylesXplrr.pdfViewerMainXPL}
                        file={`${window.globalBase64String}`}
                        onLoadSuccess={handleLoadSuccess}
                        noData={<div className={stylesXplrr.loadingText}>Loading...</div>}
                        loading={<div className={stylesXplrr.loadingText}>Loading...</div>}
                        onLoadError={(error: any) => {
                            console.log("PDF ERROR", error);
                            setLoadingMessage("ERROR LOADING PDF");
                        }}
                        error={
                            <div className={stylesXplrr.errorPDFLoadSystemMsg}>
                                <div className={stylesXplrr.iconNoContent}>
                                    <MdErrorOutline size={26} />
                                </div>
                                <div>Error Loading PDF</div>
                            </div>
                        }
                    >
                        <div className={stylesXplrr.pdfDocContainer}>
                            <div className={stylesXplrr.pdfMain} id="pdfmainid9" onScroll={handleScrollCallAdv}>
                                {filePageDimsState?.map((dim: gpagedimensions, index: number) =>
                                    (index > startPage - (window.globalpdfObservableRange || 5) &&
                                        index < startPage + (window.globalpdfObservableRange || 5)) ||
                                    (window.globalUserScrollLazyLoadEnabled == true && window.globalCurrentVisibleEntries?.includes(index)) ? (
                                        <div
                                            key={index}
                                            data-load-mark={loadMark}
                                            data-p-type={"page"}
                                            ref={(ref: any) => addToObserver(ref, index)}
                                            style={{
                                                width: getDesiredWidth(srcLoc),
                                                height: getDesiredHeight(dim, srcLoc),
                                                backgroundColor: "#f0f0f0",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}
                                        >
                                            {pgs[index]}
                                        </div>
                                    ) : (
                                        <div
                                            key={index}
                                            data-load-mark={loadMark}
                                            data-p-type={"mock"}
                                            ref={(ref: any) => addToObserver(ref, index)}
                                            style={{
                                                width: getDesiredWidth(srcLoc),
                                                height: getDesiredHeight(dim, srcLoc),
                                                backgroundColor: "#f0f0f0",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}
                                        ></div>
                                    )
                                )}
                            </div>
                        </div>
                    </Document>
                </div>
            )}
        </div>
    );
};
export default XplrrBOXPDF;
