import pps from "./assets/pps.png";
import hda from "./assets/hda.png";
import aas from "./assets/aas.png";
import ipa from "./assets/ipa.png";
import tam from "./assets/tam.png";

export interface finField {
    id: string;
    name: string;
    level: "main" | "item";
    description?: string;
    default?: boolean;
    systemDisabled: boolean;
}
export const finFieldList: finField[] = [
    // Main Fields
    {
        id: "contractor_name",
        name: "Contractor Name",
        level: "main",
        description: "The name of the Contractor/Supplier/Seller",
        default: true,
        systemDisabled: true
    },
    {
        id: "contractor_address",
        name: "Contractor Address",
        level: "main",
        description: "The address of the Contractor/Supplier/Seller",
        default: true,
        systemDisabled: true
    },
    {
        id: "contractor_vat_identification",
        name: "Contractor VAT Identification",
        level: "main",
        description: "The VAT Idenitification of the Contractor/Supplier/Seller",
        default: true,
        systemDisabled: true
    },
    { id: "customer_name", name: "Customer Name", level: "main", description: "The name of the Customer/Buyer,Reciever", default: true, systemDisabled: true },
    {
        id: "customer_address",
        name: "Customer Address",
        level: "main",
        description: "The address of the Customer/Buyer,Reciever",
        default: true,
        systemDisabled: true
    },
    {
        id: "customer_vat_identification",
        name: "Customer VAT Identification",
        level: "main",
        description: "The VAT Idenitification of the Customer/Buyer,Reciever",
        default: true,
        systemDisabled: true
    },
    { id: "invoice_total", name: "Invoice Total", level: "main", description: "The Grand Total amount of the Invoice", default: true, systemDisabled: true },
    { id: "invoice_date", name: "Invoice Date", level: "main", description: "The Date of the Invoice", default: true, systemDisabled: true },
    { id: "invoice_id", name: "Invoice ID", level: "main", description: "The ID of the Invoice", default: true, systemDisabled: true },
    { id: "invoice_currency", name: "Invoice Currency", level: "main", description: "The Currency of the Invoice", default: true, systemDisabled: true },
    { id: "vat", name: "VAT", level: "main", description: "The VAT Total amount of the Invoice", default: true, systemDisabled: true },
    { id: "purchase_order", name: "Purchase Order", level: "main", description: "The Purchase Order number", default: true, systemDisabled: false },
    { id: "iban", name: "IBAN", level: "main", description: "The IBAN number of the Customer/Buyer,Reciever", default: true, systemDisabled: false },
    { id: "page", name: "Page", level: "main", description: "The Page number of the Invoice (System Field)", default: true, systemDisabled: true },
    { id: "invoice_items", name: "Invoice Items", level: "main", description: "The Items of the Invoice", default: true, systemDisabled: true },
    // Item-Level Fields
    { id: "description", name: "Description", level: "item", description: "The Description of the Item", default: true, systemDisabled: false },
    { id: "quantity", name: "Quantity", level: "item", description: "The Quantity of the Item", default: true, systemDisabled: true },
    { id: "unit_price", name: "Unit Price", level: "item", description: "The Unit Price of the Item", default: true, systemDisabled: true },
    { id: "total_price", name: "Total Price", level: "item", description: "The Total Price of the Item", default: true, systemDisabled: true },
    { id: "item_number", name: "Item Number", level: "item", description: "The Sequential Number of the Item", default: true, systemDisabled: true },
    { id: "item_code", name: "Item Code", level: "item", description: "The Code of the Item", default: true, systemDisabled: false },
    { id: "item_measure", name: "Item Measure", level: "item", description: "The Measure of the Item", default: true, systemDisabled: false },
    { id: "item_type", name: "Item Type", level: "item", description: "The Type of the Item", default: true, systemDisabled: false }
];
export const assistantCardItems = [
    {
        title: "Policies & Procedures Assistant",
        description: "Provides quick, accurate and up-to-date information on company policies and procedures.",
        imageSrc: pps,
        creationData: {
            custaiinst:
                "You are an assistant that provides quick, accurate and up-to-date information on company policies and procedures. You are here to help employees find the information they need to do their jobs effectively and efficiently.",
            custgreetingmessage:
                "Hello! I'm here to help you with any questions you have about the company policies and procedures.<br><br>How can I help you today?",
            allowyoutube: 0,
            allowweb: 1,
            invoicesgroup: 0,
            assistanttype: "pps",
            showexamplequestionsonstart: 1
        }
    },
    {
        title: "IT Helpdesk Assistant",
        description: "Optimized for IT support, troubleshooting, FAQs. Provides solutions and sources increasing first call resolution.",
        imageSrc: hda,
        creationData: {
            custaiinst:
                "You are a Helpdesk assistant that provides solutions and sources. You are here to help employees with IT support, troubleshooting, and FAQs.",
            custgreetingmessage: "Hello! I'm here to help you with any **support**, **troubleshooting**, and **FAQs**.<br><br>How can I help you today?",
            allowyoutube: 0,
            allowweb: 1,
            invoicesgroup: 0,
            assistanttype: "hda",
            showexamplequestionsonstart: 1
        }
    },
    {
        title: "Automation API Agent",
        description: "Configured to provide programatic responses to API calls.",
        imageSrc: aas,
        creationData: {
            custaiinst: " ",
            custgreetingmessage: "Hi, this is an AI Automation API Agent.<br><br>What can I do for you?",
            allowyoutube: 0,
            allowweb: 1,
            invoicesgroup: 1,
            assistanttype: "aas",
            showexamplequestionsonstart: 1
        }
    },
    {
        title: "Invoice Processing Agent",
        description: "Fully automated invoice processing and reporting. Invoice Data extraction and validation.",
        imageSrc: ipa,
        creationData: {
            custaiinst: " ",
            custgreetingmessage: "Hi, I'm here to help you with invoice processing and reporting.<br><br>How can I help you today?",
            allowyoutube: 0,
            allowweb: 1,
            invoicesgroup: 1,
            assistanttype: "ipa",
            showexamplequestionsonstart: 1
        }
    },
    {
        title: "Technical Onboarding Mentor",
        description: "Optimized for technical documentation, onboarding and training.",
        imageSrc: tam,
        creationData: {
            custaiinst: "You are a Technical Onboarding Mentor AI assistant that provides technical documentation, onboarding and training.",
            custgreetingmessage: "Hello! I'm here to help you with any technical documentation and training.<br><br>How can I help you today?",
            allowyoutube: 0,
            allowweb: 1,
            invoicesgroup: 0,
            assistanttype: "tam",
            showexamplequestionsonstart: 1
        }
    }
];
export const multiPurposeAssistantCreationData = {
    custaiinst: "You are an assistant that helps users by answering questions and providing information.",
    custgreetingmessage: "Hello! I'm here to help you with any questions that you might have. How can I help you today?",
    allowyoutube: 0,
    allowweb: 1,
    invoicesgroup: 0,
    assistanttype: "",
    showexamplequestionsonstart: 1
};
export const detailsListContentTypeOptions = [
    { key: "document", text: "document" },
    { key: "invoice", text: "invoice" },
    { key: "form", text: "form" },
    { key: "receipt", text: "receipt" },
    { key: "contract", text: "contract" },
    { key: "book", text: "book" },
    { key: "policy", text: "policy" },
    { key: "data", text: "data" },
    { key: "other", text: "other" }
];
