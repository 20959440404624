import React from "react";
import styles from "./AssistantTypeCards.module.css";
import { DocumentCard, DocumentCardTitle, DocumentCardDetails, DocumentCardImage } from "@fluentui/react/lib/DocumentCard";
import { ThemeProvider } from "@fluentui/react";
import { ImageFit } from "@fluentui/react/lib/Image";
import { assistantCardItems } from "../../lsts";
import customTheme from "./../../theme/customTheme";

interface assistantTypeProps {
    addSpace: (specializedSettings: any) => void;
}
const AssistantTypeCards: React.FC<assistantTypeProps> = ({ addSpace }) => {
    return (
        <div key={"crdCntnA"} className={styles.cardContainer}>
            {assistantCardItems.map((item, index) => (
                <ThemeProvider theme={customTheme}>
                    <DocumentCard
                        key={index}
                        className={styles.card}
                        onClick={() => {
                            addSpace(item.creationData);
                        }}
                        styles={{
                            root: {
                                transition: "box-shadow 0.1s ease-in-out, transform 0.03s ease-in-out",
                                border: "none",
                                selectors: {
                                    ":hover": {
                                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                        border: "none"
                                    },
                                    ":hover:after": {
                                        border: "none"
                                    },
                                    ":active": {
                                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                        border: "none"
                                    },
                                    ":active:after": {
                                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                        border: "none"
                                    }
                                }
                            }
                        }}
                    >
                        <div className={styles.overlay}>
                            <span className={styles.overlayText}>Create</span>
                        </div>
                        <DocumentCardImage height={150} imageFit={ImageFit.centerCover} imageSrc={item.imageSrc} />

                        <DocumentCardDetails>
                            <DocumentCardTitle title={item.title} className={styles.cardTitle} />
                            <DocumentCardTitle title={item.description} shouldTruncate showAsSecondaryTitle className={styles.cardDesc} />
                        </DocumentCardDetails>
                    </DocumentCard>
                </ThemeProvider>
            ))}
        </div>
    );
};

export default AssistantTypeCards;
