import { renderToStaticMarkup } from "react-dom/server";
import styles from "../Answer/Answer.module.css";
import MarkdownIt from "markdown-it";
const md = new MarkdownIt();
type HtmlParsedAnswer = {
    answerHtml: string;
    citations: string[];
};
type CaptionWithoutContent = {
    filename: string;
    page: string;
    fileid: string;
};
const convertMarkdownHeadersToHTML = (markdownText: string) => {
    return markdownText
        .replace(/^####\s+(.*)$/gm, "<h4>$1</h4>")
        .replace(/^###\s+(.*)$/gm, "<h3>$1</h3>")
        .replace(/^##\s+(.*)$/gm, "<h2>$1</h2>")
        .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>");
};
function fuzzyIndexOf(str: string, search: string) {
    const searchLower = search.toLowerCase();
    const strLower = str.toLowerCase();
    let searchIndex = 0;

    for (let i = 0; i < strLower.length; i++) {
        if (strLower[i] === searchLower[searchIndex]) {
            searchIndex++;
            if (searchIndex === searchLower.length) {
                return i - searchIndex + 1; // Return the starting index of the match
            }
        }
    }

    return -1; // No match found
}
function findStringByStartAndEnd(bigText: string, start: string, end: string) {
    //console.log("¦start", start, "end", end, "bigText", bigText);

    // Function to clean text (remove everything but letters, digits, and Cyrillic)
    const cleanText = (text: string) => text.replace(/[^a-zA-Z0-9\u0400-\u04FF]/g, "").toLowerCase();

    // Clean the texts
    const cleanedBigText = cleanText(bigText);
    const cleanedStart = cleanText(start);
    const cleanedEnd = cleanText(end);
    //console.log("¦cleanedStart", cleanedStart, "cleanedEnd", cleanedEnd, "cleanedBigText", cleanedBigText);

    let cleanStartIndex = cleanedBigText.indexOf(cleanedStart);
    if (cleanStartIndex == -1) {
        cleanStartIndex = fuzzyIndexOf(cleanedBigText, cleanedStart);
    }

    let cleanEndIndex = cleanedBigText.indexOf(cleanedEnd, cleanStartIndex + cleanedStart.length);

    if (cleanEndIndex == -1) {
        cleanEndIndex =
            fuzzyIndexOf(cleanedBigText.substring(cleanStartIndex + cleanedStart.length), cleanedEnd) + cleanedBigText.substring(0, cleanStartIndex).length;
    }
    cleanEndIndex = cleanEndIndex + cleanedEnd.length;

    //let fuzzStart = cleanedStart.split("").join(".*?");
    //let fuzzEnd = cleanedEnd.split("").join(".*?");
    //let fuzzReg = `(${fuzzStart})[\\s\\S]*?(${fuzzEnd})`;

    //const match = cleanedBigText.match(fuzzReg);
    //console.log("FUZZ_MTCH¦", match);

    //console.log("¦cleanStartIndex", cleanStartIndex, "cleanEndIndex", cleanEndIndex, "cleanedStartLength", cleanedStart.length);
    //console.log("¦cleanStartIndexOG", cleanStartIndex, "cleanEndIndexOG", cleanEndIndex, "cleanedStartLength", cleanedStart.length);
    //console.log("¦cleanStartIndexText¦", cleanedBigText.substring(cleanStartIndex, cleanEndIndex));
    //console.log("¦cleanStartIndexTextOG¦", cleanedBigText.substring(cleanStartIndex, cleanEndIndex));

    if (cleanStartIndex === -1 || cleanEndIndex === -1) {
        return null; // String not found
    }

    // Function to find the original index
    const findOriginalIndex = (cleanIndex: number) => {
        let originalIndex = 0;
        let cleanedIndex = 0;

        while (cleanedIndex < cleanIndex) {
            if (/[a-zA-Z0-9\u0400-\u04FF]/.test(bigText[originalIndex])) {
                cleanedIndex++;
            }
            originalIndex++;
        }

        return originalIndex;
    };
    // Find the original start and end indices
    const originalStartIndex = findOriginalIndex(cleanStartIndex);
    const originalEndIndex = findOriginalIndex(cleanEndIndex);

    // Extract the original string
    return bigText.slice(originalStartIndex, originalEndIndex);
}
const abridgedParagraphExtraction = (abPart: string, content: string) => {
    let abridgedParagraph = abPart.trim().substring(1, abPart.length - 1); // Remove one Char from Start and End (Double Quotes) // "Nasogastric \ntube  \n(NG tube)  ...down through the nasopharynx and oesophagus."
    let parts = abridgedParagraph.split("...");

    let firstHalf;
    let secondHalf;

    if (parts.length < 2) {
        const centerIndex = Math.floor(parts[0].length / 2);
        firstHalf = parts[0].substring(0, centerIndex);
        secondHalf = parts[0].substring(centerIndex);
    } else {
        firstHalf = parts[0];
        secondHalf = parts[1];
    }
    const part1 = firstHalf
        .trim()
        .replace("...", "")
        .replace(/\\n/g, "")
        .replace(/\s+/g, " ")
        .replace(/[.,\s]+$/, "");
    const part2 = secondHalf
        .trim()
        .replace("...", "")
        .replace(/\\n/g, "")
        .replace(/\s+/g, " ")
        .replace(/[.,\s]+$/, "");
    let contentNonewLines = content.replace(/\r?\n/g, "").replace(/\s+/g, " ");

    let match = findStringByStartAndEnd(contentNonewLines, part1, part2);

    //const escapeRegex = (str: string) => str.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
    //const regex = new RegExp(`${escapeRegex(part1)}.*?${escapeRegex(part2)}`, "s"); // 's' flag to allow dot to match newlines
    //const matches = contentNonewLines.match(regex);
    //return matches ? matches[0] : null;
    return match;
};
const tableRegex = /\|(.+?)\|\s*\n\|(-+?\s*\|)+\s*\n((\|(.+?)\|\s*\n)*)/g;

// Function to apply markdown-it only on tables and leave everything else untouched
function markdownTableToHtmlOnly(markdown: string): string {
    // Replace tables in the markdown string with HTML, leaving other parts untouched
    return markdown.replace(tableRegex, (match: string) => {
        return md.render(match); // Apply markdown-it rendering only to the table section
    });
}

export function parseAnswerToHtml(
    answer: string,
    isStreaming: boolean,
    citationData: any[],
    use_integrated_search: boolean,
    answerIndex: number,
    clickedCitation: string
): HtmlParsedAnswer {
    const citations: string[] = [];
    let parsedAnswer = answer.trim();

    // Omit Citation if Still being typed during streaming
    if (isStreaming) {
        let lastIndex = parsedAnswer.length;
        for (let i = parsedAnswer.length - 1; i >= 0; i--) {
            if (parsedAnswer[i] === "]") {
                break;
            } else if (parsedAnswer[i] === "[") {
                lastIndex = i;
                break;
            }
        }
        const truncatedAnswer = parsedAnswer.substring(0, lastIndex);
        parsedAnswer = truncatedAnswer;
    }

    const parts = parsedAnswer.split(/\[([^\]]+)\]/g);
    //const parts = parsedAnswer.split(/(\[(?:doc|[^\]]+)\])/g);
    const fragments: string[] = parts.map((part, index) => {
        if (index % 2 === 0) {
            return part;
        } else {
            let citationIndex: number;
            let docIndex: number;
            let filePath: string;

            let partPreserve = part.replace(/"/g, "&quot;");

            let titleString = part;
            let titleOnly = part;
            let pageOnly = "";
            let pageRange = "";
            //console.log("PRT¦", part, citationData); // EX. doc2 "Договор с ... 23,88 лв."

            const firstSpaceIndex = part.indexOf(" ");
            let titlePresentable = part;
            let docRefPar = part.substring(0, firstSpaceIndex); // EX. doc2

            try {
                const docIndexString = docRefPar.replace(/\D/g, ""); // EX. From "doc2" to "2"
                docIndex = parseInt(docIndexString) - 1;
                let docItem = citationData[docIndex];
                if (!docItem) {
                    docItem = {};
                }
                let filteredCitationData = citationData.filter(item => item.url == docItem.url).sort((a, b) => a.chunk_id - b.chunk_id);
                let allDocChunksContent = filteredCitationData.map(item => item.content).join("\r\n");

                let filePathWithoutIdParameter = docItem.filepath; // EX. "Acceptance of Gifts or Benefits.PDF#page=2"

                let presentableSplit = filePathWithoutIdParameter.split("#page=");
                //console.log("presentableSplit¦", presentableSplit);
                if (presentableSplit.length > 1) {
                    let pagePres = presentableSplit[1];
                    titlePresentable = `${presentableSplit[0]} [Page ${pagePres}";]`;
                    titleOnly = presentableSplit[0];
                    pageOnly = pagePres.split("|")[0];
                    pageRange = pagePres.replace("|", "-");
                } else {
                    titlePresentable = filePathWithoutIdParameter;
                    titleOnly = filePathWithoutIdParameter;
                    pageOnly = "";
                    pageRange = "";
                }
                let fileId = docItem.url; // EX. "436"
                filePath = `${filePathWithoutIdParameter}_fid=${fileId}`;
                titleString = filePathWithoutIdParameter;

                if (firstSpaceIndex !== -1) {
                    let rgxResult = abridgedParagraphExtraction(part.substring(firstSpaceIndex + 1), docItem.content);
                    //console.log("RGX¦", part.substring(firstSpaceIndex + 1), "docItemContent", docItem.content, "rgxResult", rgxResult);
                    if (rgxResult) {
                        titleString = rgxResult;
                    } else {
                        rgxResult = abridgedParagraphExtraction(part.substring(firstSpaceIndex + 1), allDocChunksContent);
                        if (rgxResult) {
                            titleString = rgxResult;
                        }
                    }
                }
            } catch (e) {
                console.log("not_citation¦");
                //filePath = docRefPar;
                filePath = "";
            }
            // Get Citation Index
            if (citations.indexOf(filePath) !== -1) {
                citationIndex = citations.indexOf(filePath) + 1;
            } else {
                if (filePath.length > 0) {
                    citations.push(filePath);
                    citationIndex = citations.length;
                } else {
                    return "";
                }
            }

            try {
                let pageparsed = parseInt(pageOnly);
                if (isNaN(pageparsed)) {
                    return "";
                }
            } catch {
                return "";
            }

            return renderToStaticMarkup(
                <div className="supContainerWrap">
                    <a>
                        <sup
                            id={`spid_${answerIndex}_${index}`}
                            className={
                                clickedCitation == `spid_${answerIndex}_${index}`
                                    ? `${styles.supContainer} citation-link activeCitFlash`
                                    : `${styles.supContainer} citation-link`
                            }
                            data-citation-link={`${filePath}`}
                            data-answer-index={`${index}`}
                            data-integrated-citation={`${titleString}`}
                            data-integrated-title={`${titlePresentable}`}
                            data-tippy-content={`
                                    <div class="tooltipsBakedIn">

                                        <div class="tooltipTitleInfoContainer">
                                        <div class='sourceDocInMessage'>
                                            <sup class='tooltipZ' title="This is an excerp from the document \nthat had the most influence over \nthe answer in this paragraph.">
                                            ?
                                            </sup>
                                            <div class="tooltipTitle">
                                                ${titleOnly}
                                            </div>
                                            <div class="docSourceCitationInMessage" title="${partPreserve}">
                                                ${pageRange}
                                            </div>

                                        </div>


                                        </div>

                                        <div class="tooltipContent">${titleString}</div>
                                        <div class="tooltipLinkContainer citation-tooltip-link btnfos-4">
                                            <span style="z-index:9999" >See In Document</span>
                                        </div>
                                        <div class="tooltipLinkContainer citation-tooltip-link btnfos-4">
                                            <span style="z-index:9999">Explore in Side-By-Side View</span>
                                        </div>
                                    </div>
                                `}
                        >
                            {citationIndex}
                        </sup>
                    </a>
                </div>
            );
        }
    });

    return {
        answerHtml: fragments
            .map((fragment, index) => {
                //return convertMarkdownHeadersToHTML(fragment);
                return markdownTableToHtmlOnly(convertMarkdownHeadersToHTML(fragment))?.replace(new RegExp("```html|```", "g"), "");
            })
            .join(""),
        citations
    };
}
