import React, { useEffect, useState } from "react";
import { Modal, TextField, PrimaryButton, Pivot, PivotItem, fontFace, Label, FontSizes } from "@fluentui/react";
import { ITag } from "@fluentui/react/lib/Pickers";
import styles from "../../pages/chat/Chat.module.css";
import UserTagPicker from "../../components/UserTagPicker/UserTagPicker"; // Adjust the path to your UserTagPicker component
import AssistantTypeCards from "../../components/AssistantTypeCards/AssistantTypeCards"; // Adjust the path to your UserTagPicker component
import { qAddComp } from "../../api/qpi";
import { toast, ToastContainer, Bounce } from "react-toastify";

import { TailoredCards } from "../../components/TailoredCards";

import { gnewcomp, gcompflat, gloggedinuser } from "../../interfaces";
import { set } from "lodash";

interface NewCompModalProps {
    refreshComps: (newComp: gnewcomp) => void;
    comps: gcompflat[];
    loggedInUser: gloggedinuser;
}

const textFieldStyles = {
    root: {
        borderRadius: "5px"
    },
    fieldGroup: {
        borderRadius: "5px",
        selectors: {
            "::after": {
                borderRadius: "5px "
                //border: "2px solid #404040"
            }
        }
    },
    subComponentStyles: {
        label: {
            root: {
                fontSize: "14px",
                fontFamily: "Urbanist"
            }
        }
    }
};
const AddCompanyModal: React.FC<NewCompModalProps> = ({ refreshComps, comps, loggedInUser }) => {
    const [newComp, setNewComp] = useState<gnewcomp>({
        companyid: "",
        companyname: "",
        licensingkind: 0
    });
    const [isLoa, setIsLoa] = useState<boolean>(false);
    const checkString = (str: string) => {
        console.log("str", str);
        return str.length > 2 || !/^[a-zA-Z0-9]+$/.test(str);
    };
    const handleAddComp = () => {
        if (newComp.companyid == "") {
            console.log("a", newComp.companyid);
            toast.error("Please enter a Company Id");
            return;
        }
        if (newComp.companyname == "") {
            toast.error("Please enter a Company Name");
            return;
        }
        if (comps.find(comp => comp.companyid == newComp.companyid)) {
            toast.error("Please choose different Company Id");
            return;
        }
        if (checkString(newComp.companyid) == true) {
            toast.error("Company Id should be 2 alphanumerics");
            return;
        }
        setIsLoa(true);
        qAddComp(loggedInUser, newComp).then(res => {
            console.log("res", res);
            if (!res || res.ok == false) {
                toast.error("Error Adding Company");
                setIsLoa(false);
            } else {
                toast.success(`Company ${newComp.companyid} Added`);
                refreshComps(newComp);
                setIsLoa(false);
            }
        });
    };

    const handleChange = (field: keyof gnewcomp, value: string | number | boolean) => {
        setNewComp(prev => ({ ...prev, [field]: value }));
    };
    const onStateChange = (state: string) => {};
    useEffect(() => {
        setNewComp({
            companyid: "",
            companyname: "",
            licensingkind: 0
        });
    }, []);
    return (
        <div className={styles.newCompModalCompWrap}>
            <h3 className={styles.modalTitle}>New Company</h3>

            <TextField
                styles={textFieldStyles}
                label="Company Name"
                defaultValue={newComp.companyid}
                onChange={(event, newValue) => handleChange("companyname", newValue || "")}
                className={styles.spaceTextFields}
            />
            <TextField
                styles={textFieldStyles}
                className={styles.spaceTextFields}
                defaultValue={newComp.companyname}
                label="Company ID"
                onChange={(event, newValue) => handleChange("companyid", newValue || "")}
            />
            <PrimaryButton text="Create Company" className={styles.spacePicker} onClick={handleAddComp} />
        </div>
    );
};

export default AddCompanyModal;
