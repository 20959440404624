import anya from "../../frontend/src/assets/anya.png";
import tam from "../../frontend/src/assets/tam.png";
import ipa from "../../frontend/src/assets/ipa.png";
import aas from "../../frontend/src/assets/aas.png";
import hda from "../../frontend/src/assets/hda.png";
import pps from "../../frontend/src/assets/pps.png";

export interface guser {
    userDisplayName: string;
    userId: string;
    userMail: string;
    companyid: string;
    name: string;
    tier: number;
    subscriptionid: string;
    status: string;
}
export interface gcontentlist {
    fileid: string;
    srcPageNum: number;
    srcText: string;
    destPageId: number;
    destPageNum: number;
}
export interface gcontentlistai {
    text: string;
    page_id: number;
    page_number: string;
    hierarchical_numbering: string;
}
export interface gpagemap {
    [key: string]: number;
}
export interface gpagecontent {
    page_num: string;
    text: string;
}
export interface gpagedimensions {
    page_number: string;
    width: number;
    height: number;
}
export interface gfile {
    fileid: string;
    name: string;
    size: string;
    full_path: string;
    modified: string;
    groupids: string;
    groupnames: string;
    filerole: string;
    ownerid: string;
    filepermlevel: number;
    pagemap: gpagemap;
    contentlist: gcontentlist[];
    pagecontent: gpagecontent[];
    globalXplrrOrder?: number;
    indexchunks?: number[];
    pagedimensions: gpagedimensions[];
    sourcetype: string;
    sourceurl: string;
    docstatus: string;
    invoicedata: string;
    gcontentlistai: string;
    doc_category: string;
}
export interface gXplrrExtendedFileList {
    globalXplrrOrder: number;
    fileid: string;
    name: string;
    size: string;
    full_path: string;
    modified: string;
    groupids: string;
    groupnames: string;
    filerole: string;
    ownerid: string;
    filepermlevel: number;
    pagemap: gpagemap;
    contentlist: gcontentlist[];
    pagecontent: gpagecontent[];
    pagedimensions: gpagedimensions[];
    sourcetype: string;
    sourceurl: string;
    docstatus: string;
    invoicedata: string;
    gcontentlistai: string;
    doc_category: string;
}
export interface ggroup {
    groupid: string;
    groupname: string;
    userid: string;
    permissionlevel: number;
    filecount: number;
    description: string;
    sharewithcode: boolean;
    custaiinst: string;
    custgreetingmessage: string;
    isintegrateddata: number;
    allowyoutube: number;
    allowweb: number;
    enabledocaudio: number;
    retrievecount: number;
    sectionoverlap: number;
    maxsectionlength: number;
    sentencesearchlimit: number;
    inscope: number;
    temperature: number;
    responsetokenlimit: number;
    disablecitationhighlighting: number;
    strictness: number;
    invoicesgroup: number;
    questionsuggestions: number;
    nosidebysideview: number;
    isowner: number;
    dailymsgs: number;
    premiumby: string;
    oid: number;
    tier: number;
    premwhen: number;
    companyid: string;
    apikey: string;
    producecontentlist: number;
    producequestions: number;
    assistanttype: string;
    showexamplequestionsonstart: number;
    headerimageurl: string;
    manualquestionsuggestions: string;
    usedocintel: number;
    extractionfieldlist: string;
}
export interface gselectedgroup {
    selectionId: string;
    selectionText: string;
    permissionlevel: number;
    description: string;
    sharewithcode: boolean;
    filecount: number;
    custaiinst: string;
    custgreetingmessage: string;
    isintegrateddata: number;
    allowyoutube: number;
    allowweb: number;
    enabledocaudio: number;
    retrievecount: number;
    sectionoverlap: number;
    maxsectionlength: number;
    sentencesearchlimit: number;
    inscope: number;
    temperature: number;
    responsetokenlimit: number;
    disablecitationhighlighting: number;
    strictness: number;
    invoicesgroup: number;
    questionsuggestions: number;
    nosidebysideview: number;
    isowner: number;
    dailymsgs: number;
    premiumby: string;
    oid: number;
    tier: number;
    premwhen: number;
    companyid: string;
    apikey: string;
    producecontentlist: number;
    producequestions: number;
    assistanttype: string;
    showexamplequestionsonstart: number;
    headerimageurl: string;
    manualquestionsuggestions: string;
    usedocintel: number;
    extractionfieldlist: string;
}
export interface gcompusrs {
    company: string;
    userid: string;
    usermail: string;
    username: string;
}
export interface gallusrs {
    companyid: string;
    userid: string;
    usermail: string;
    username: string;
    permissionlevel: number;
    tier: number;
}
export interface guseraddition {
    membermail: string;
    memberdisplayname: string;
    membercompany: string;
    memberlevel: number;
}
export interface gcompmbrshps {
    groupid: string;
    groupname: string;
    memberid: string;
    memberusername: string;
    membermail: string;
    membercompany: string;
    memberlevel: number;
    isinvite: number;
}
export interface gactivecomp {
    companyid: string;
    companyname: string;
    permissionlevel: number;
    licensingkind: number;
}
export interface gcompflat {
    companyid: string;
    companyname: string;
    permissionlevel: number;
    licensingkind: number;
}
export interface gnewcomp {
    companyid: string;
    companyname: string;
    licensingkind: number;
}
export interface gloggedinuser {
    userId: string;
    mail: string;
    token: string;
}
export interface ispdfmodalopen {
    defaultFile: string;
    startPage: string;
    isOpen: boolean;
    searchString: string;
    contentlist: gcontentlist[];
    pagemap: gpagemap;
    fileId: string;
    refPageNum: string;
    isCitation: boolean;
    rndo: string;
    activeGroupId: string;
    fileList: gfile[];
    fl: gfile | undefined;
}
export interface gpdfcache {
    [key: string]: gpdfcachevalue;
}
export interface gpdfcachevalue {
    base64string: string;
    fileid: string;
    name: string;
    modified: string;
}
export interface gXplrrCitationChangeCallback {
    fileid: string;
    destPageNum: string;
    isCitation: boolean;
    rndo: string;
}

// Space ⇒ File ⇒ Order
export interface gXplrrContentOrder {
    [key: string]: gXplrrSpaceOrder;
}
// Keep Files in Order
export interface gXplrrSpaceOrder {
    [key: number]: gXplrrFileOrder;
}
export interface gXplrrFileOrder {
    order: number;
    opened: boolean;
    fileItemOrder: gXplrrFileItemHash;
}
export interface gXplrrFileItemHash {
    [key: number]: gXplrrFileItemOpened;
}
export interface gXplrrFileItemOpened {
    opened: boolean;
    currentValue: string;
    splitValues: string[];
    splitLength: number;
    itemLevel: number;
    pageNumExtracted: number;
}
export interface gXplrrSpaceIndex {
    [key: string]: gXplrrContentListFileOrder;
}
export interface gXplrrContentListFileOrder {
    [key: string]: number;
}
export interface gChatlogItem {
    key: string;
    usermail: string;
    question: string;
    response: string;
}
export interface gConf {
    promptTemplate: string;
    retrieveCount: number;
    retrievalMode: string;
    useSemanticRanker: boolean;
    shouldStream: boolean;
    useSemanticCaptions: boolean;
    searchOnly: boolean;
    useHighlightsAsSource: boolean;
    useSuggestFollowupQuestions: boolean;
    isDark: boolean;
    experimentalFeatures: boolean;
    showDocLibInChatTab: boolean;
    sourceHighlighting: boolean;
}
export interface ggroupcardprops {
    selectedGroup: gselectedgroup;
    handleGroupListClick: (groupid: string, groupname: string, permissionlevel: number) => void;
}
export interface ggroupcardslistprops {
    grL: ggroupcardprops[];
    onNewGroupClick: () => void;
    compLvl: number;
    permissionLevelsMap: { [key: number]: string };
    newSpacePyro: string;
    user: guser;
    activeComp: gactivecomp;
    navChatLib: (loc: string, groupid: string) => void;
}
export interface greindexparams {
    fileString: string;
    selectedGroup: gselectedgroup;
    userId: string;
    company: string;
    header: any;
    modString: string;
    idString: string;
}
export interface gnewgroup {
    id: string;
    groupname: string;
    description: string;
    appid: string;
    scrt: string;
    specializedSettings: any;
}
export interface ginvoiceextractiondata {
    name: string;
    invoicedata?: string;
}
export interface gsuggestedquestions {
    fileid: number;
    suggestedquestions?: string;
}
export interface gingestioniteminfo {
    fileSpace: string;
    company: string;
    userId: string;
    fdFlName: string;
    fdFlSize: string;
    sectionoverlap: string;
    maxsectionlength: string;
    sentencesearchlimit: string;
    invoicesgroup: string;
    usedocintel: string;
}
export interface gtiers {
    tier: number;
    msgsplmt: number;
    docsplmt: number;
    spslmt: number;
    name: string;
    monthlypayment: number;
    demosps: number;
}
export interface gInnerMap {
    [key: string]: boolean;
}
export interface gOuterMap {
    [key: string]: gInnerMap;
}
export interface grecentconvosaccrossspaces {
    groupid: string;
    lastinteractiontimestamp: string;
    name: string;
    groupname: string;
    key: string;
}
export const imgMap = {
    pps: pps,
    hda: hda,
    aas: aas,
    ipa: ipa,
    tam: tam,
    anya: anya,
    "": anya
};
export const nmeMap = {
    pps: "Policies & Procedures Assistant",
    hda: "IT Helpdesk Assistant",
    aas: "Automation API Agent",
    ipa: "Invoice Processing Agent",
    tam: "Technical Onboarding Mentor",
    anya: "Multi-Purpose Assistant",
    "": "Multi-Purpose Assistant"
};
export type ImgMapKeys = "pps" | "hda" | "aas" | "ipa" | "tam" | "";

export interface gfeedback {
    feedback: string;
    pos: number | null;
    interaction_guid: string;
}
export interface gcustominvoiceinstructions {
    id: number;
    groupid: string;
    contractorid: string;
    fieldname: string;
    instruction: string;
    userid: string;
}
export interface gcustominvoiceinstructionscallout {
    isOpen: boolean;
    path: string;
    instructions: gcustominvoiceinstructions[];
}
