import { useRef, useState, useEffect } from "react";

import {
    Checkbox,
    Panel,
    DefaultButton,
    TextField,
    SpinButton,
    Dropdown,
    IDropdownOption,
    PanelType,
    Modal,
    TagPicker,
    ITag,
    PrimaryButton,
    IconButton,
    ContextualMenu,
    IContextualMenuItem,
    Pivot,
    PivotItem,
    List,
    ICheckboxStyles,
    Toggle,
    Icon,
    TooltipHost,
    DirectionalHint,
    ThemeProvider,
    IModalStyles
} from "@fluentui/react";

import RModal from "react-modal";

import axios from "axios";

import smoothScrollIntoView from "smooth-scroll-into-view-if-needed";
import { FiUser } from "react-icons/fi";
import kgnfLogoOnly from "../../assets/kgnfLogoOnly.svg";
import blankLogoOnly from "../../assets/blankLogoOnly.png";
import kognifyBanner from "../../assets/kognifyBannerTextOnly.png";
import kognifyLogo from "../../assets/kognifyLogo.png";
import { useNavigate, useLocation } from "react-router-dom";
import readNDJSONStream from "ndjson-readablestream";
import { AuthenticationResult } from "@azure/msal-browser";
import { MdArrowBackIos } from "react-icons/md";
import { IoShareSocialOutline } from "react-icons/io5";
import { LuPanelRightClose } from "react-icons/lu";
import { TbGridDots } from "react-icons/tb";
import { HiOutlineViewList } from "react-icons/hi";
import styles from "./Chat.module.css";
import { chatApi, RetrievalMode, ChatAppResponse, ChatAppResponseOrError, ChatAppRequest, ResponseMessage, ResponseChoice } from "../../api";
import { Answer, AnswerError, AnswerLoading, AnswerHi } from "../../components/Answer";
import NoSpacesCard from "../../components/NoSpacesCard/NoSpacesCard";
import { QuestionInput } from "../../components/QuestionInput";
import { UserChatMessage } from "../../components/UserChatMessage";
import { AnalysisPanel, AnalysisPanelTabs } from "../../components/AnalysisPanel";
import { SettingsButton } from "../../components/SettingsButton";
import SpacePanel from "../../components/SpacePanel/SpacePanel";
import AddSpaceModal from "../../components/AddSpaceModal/AddSpaceModal";
import InviteUserCallout from "../../components/InviteUserCallout/InviteUserCallout";
import { ListItemButton } from "../../components/ListItemButton";
import SpaceEditForm from "../../components/SpaceEditForm/SpaceEditForm";
import AccountInfo from "../../components/AccountInfo/AccountInfo";
import { useMsal } from "./../../MsalContext";
import { loginRequest } from "../../authConfig";
import customTheme from "./../../theme/customTheme";
import React, { useContext } from "react";
import { GlobalContext } from "../../GlobalContext"; // Tests¦Context Accross Routes (ex. Chat/Search)
import CompEditForm from "../../components/CompEditForm/CompEditForm";
import { DetailsListDocumentsExample } from "../../components/detailedList"; // Files List
import XplrrPDF from "../../components/XplrrPDF/XplrrPDF";
import UserList from "../../components/UserList/UserList";
import CompList from "../../components/CompList/CompList";
import GroupCardsList from "../../components/GroupCardsList/GroupCardsList";
import SpaceUserListV2 from "../../components/SpaceUserListV2/SpaceUserListV2";
import AnalyticsConvoList from "../../components/AnalyticsConvoList/AnalyticsConvoList";
import * as DarkReader from "darkreader";
import { toast, ToastContainer, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import debounce from "lodash/debounce";
import {
    gfile,
    ggroup,
    gselectedgroup,
    gcompflat,
    ispdfmodalopen,
    gXplrrCitationChangeCallback,
    gChatlogItem,
    gConf,
    gnewgroup,
    gsuggestedquestions,
    gtiers,
    grecentconvosaccrossspaces,
    gfeedback
} from "../../interfaces";
import XplrrPDFSideByChat from "../../components/XplrrPDFSideByChat/XplrrPDFSideByChat";
import FieldSelection from "../../components/FieldSelection/FieldSelection";
import { uid } from "chart.js/dist/helpers/helpers.core";

const defaultPromptTemplateAsInPythonBackend = `"Assistant helps find answers to questions about information in the available documents. If there isn't enough information below, ask for more specific information."`;
const defaultGreetingsMessage = `<div class="answerText">Hey,</div>
<div class="answerText">I'm <b>Kognify </b> and I'm here to help you understand and get insights from your content.</div>
<div class="answerText">You can chat with me or you can check the Documents that I know about in the Manage Section</div>
<div class="answerCardContainer">
</answerSuggestions>
</div>`;
const msgLmtHit = "Space Messages Exhausted for the day. Please Consider Upgrading to Premium";
const Chat = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { msalInstance, getToken } = useMsal();
    const [token, setToken] = useState<AuthenticationResult | null>();
    const {
        user,
        loggedInUser,
        fileList,
        groupList,
        selectedGroup,
        allUsrs,
        compMbrshps,
        activeComp,
        setUser,
        setLoggedInUser,
        setFileList,
        setGroupList,
        setSelectedGroup,
        setCompUsrs,
        setAllUsrs,
        setCompMbrshps,
        setActiveComp,
        permissionLevels,
        setSourceHighlighting
    } = useContext(GlobalContext);

    // Company List with Users Permission
    const [compFlatList, setCompFlatList] = useState<gcompflat[]>([]);
    const [fileCount, setFileCount] = useState<number>(0);

    // Company List with Users Permission
    const [excludeCategory, setExcludeCategory] = useState<string>("");
    const [includeCategory, setIncludeCategory] = useState<string>("");
    const [includeCategoryGroups, setIncludeCategoryGroups] = useState<string>("");

    const [greetingMessageHtml, setGreetingMessageHtml] = useState<string>("");
    const [subSplsh, setSubSplsh] = useState<string>("");

    const [useOidSecurityFilter, setUseOidSecurityFilter] = useState<boolean>(false);
    const [useGroupsSecurityFilter, setUseGroupsSecurityFilter] = useState<boolean>(false);

    const [isLib, setIsLib] = useState<string>("");
    const [lTiers, setLTiers] = useState<gtiers[]>();

    const lastQuestionRef = useRef<string>("");
    const answersLength = useRef<number>(0);
    const clickedCitation = useRef<any>(null);
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
    const xplrrMsgStrm = useRef<HTMLDivElement | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isStreaming, setIsStreaming] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();
    const [isShareCalloutVisible, setIsShareCalloutVisible] = useState<boolean>(false);
    const [activeCitation, setActiveCitation] = useState<string>();
    const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<AnalysisPanelTabs | undefined>(undefined);
    const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
    const [accMenuItems, setAccMenuItems] = useState<IContextualMenuItem[]>([]);
    const [newSpacePyro, setNewSpacePyro] = useState<string>("");
    const [addSpaceMenuItems, setAddSpaceMenuItems] = useState<IContextualMenuItem[]>([
        {
            key: "Kognify",
            text: "Kognify Space",
            onClick: () => {
                onNewGroupClick();
            },
            iconProps: { iconName: "Add" }
        },
        {
            key: "InfoBip",
            text: "InfoBip Space",
            onClick: () => {
                setIsNewSpaceInfoBip(true);
                setIsSpaceModalOpen(true);
            },
            iconProps: { iconName: "Add" }
        },
        {
            key: "SharePoint",
            text: "SharePoint Library",
            onClick: () => {},
            iconProps: { iconName: "Add" },
            disabled: true
        },
        {
            key: "Confluence",
            text: "Confluence Space",
            onClick: () => {},
            iconProps: { iconName: "Add" },
            disabled: true
        }
    ]);
    const [answers, setAnswers] = useState<[user: string, response: ChatAppResponse][]>([]);
    const [streamedAnswers, setStreamedAnswers] = useState<[user: string, response: ChatAppResponse][]>([]);

    const [xplrrChatVisible, setXplrrChatVisible] = useState<boolean>(false);
    const [isLeftPanelOpen, setIsLeftPanelOpen] = useState(false); // History Panel Open

    const [isRightPanelOpen, setIsRightPanelOpen] = useState(false); // Legacy Library Flyout
    const toggleRightPanel = () => setIsRightPanelOpen(!isRightPanelOpen); // Toggle Legacy Library Flyout

    const [isSpaceModalOpen, setIsSpaceModalOpen] = useState(false); // New Space Modal Open
    const [isNewSpaceInfoBip, setIsNewSpaceInfoBip] = useState(false); // New Space Modal Open
    const [isSpaceEditModalOpen, setIsSpaceEditModalOpen] = useState(false); // Edit Space Modal Open
    const [isSpaceAnalyticsModalOpen, setIsSpaceAnalyticsModalOpen] = useState(false); // Edit Space Modal Open

    const [isShareWithUsersOpen, setIsShareWithUsersOpen] = useState(false); // Edit Space Modal Open
    const [isUserListOpen, setIsUserListOpen] = useState(false); // Edit Space Modal Open

    const [activeConvoKey, setActiveConvoKey] = useState<string>(""); // Active Conversation Key [Bigint yyyyMMddHHmmss]
    const [convos, setConvos] = useState<{ key: string; name: string }[]>([]); // Historic Convos

    const [aPanelTimeStamp, setAPanelTimeStamp] = useState(""); // Timestamp for Analysis Panel

    // Space Description Field Value for Space New/Edit Modal
    const [newSpaceDescription, setNewSpaceDescription] = useState("");
    const [newAIInst, setNewAIInst] = useState("");
    const [newCustGreetingMessage, setNewCustGreetingMessage] = useState("");
    const [newIsIntegratedData, setNewIsIntegratedData] = useState<number | undefined>();
    const [suggestedQuestions, setSuggestedQuestions] = useState<gsuggestedquestions[]>();
    const [recentConvosAccrosSpaces, setRecentConvosAccrosSpaces] = useState<grecentconvosaccrossspaces[]>();

    const [newSpaceShareWithCode, setNewSpaceShareWithCode] = useState<boolean | undefined>();
    const addUserButtonRef = useRef<HTMLDivElement>(null);
    // Space Name Field Value for Space New/Edit Modal
    const [newSpaceName, setNewSpaceName] = useState("");
    const [spaceChatLog, setSpaceChatLog] = useState<gChatlogItem[]>([]);

    const [isLibTracker, setIsLibTracker] = useState("");

    const [isCancellingMidway, setIsCancellingMidway] = useState<boolean>(false);
    const [loadingChatModeSwitch, setLoadingChatModeSwitch] = useState(false); // Edit Space Modal Open
    const [isIframe, setIsIframe] = useState(false);
    const [groupListRan, setGroupListRan] = useState(false);
    const [shouldPlayAudio, setShouldPlayAudio] = useState<string>("");
    const redeemURLRef = useRef<string | null>(null);
    const redeemURLSecondPathRef = useRef<string | null>(null);
    const getTokenDyn = async () => {
        const utok = localStorage.getItem("msalAuth4Tok");
        if (utok) {
            const utokcache: AuthenticationResult = JSON.parse(utok);
            setToken(utokcache);
            return utokcache;
        }
        if (!msalInstance) return null;
        if (token) {
            return token;
        }
        let tkn = await getToken(loginRequest.scopes);
        setToken(tkn);
        return tkn;
    };
    const getTokenMail = (token: any) => {
        //import.meta.env.DEV === true && console.log("LYT¦getTokenMail", localStorage.getItem("msalAuth4"), token);
        return token.idTokenClaims?.emails?.length > 0 ? token.idTokenClaims?.emails[0] : token.idTokenClaims?.email;
    };
    const qCodeChk = async (userCode: string, userMail: string, mE: string, convCode?: string) => {
        const params = new URLSearchParams({
            userCode: userCode,
            userMail: userMail,
            convCode: convCode || "",
            mE: mE
        });
        const url = "/qCodeChk" + `?${params.toString()}`;

        const response = await fetch(url, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        if (!response.ok) {
            throw Error("Failed to Validate Mail");
        }
        let userCheck = await response.json();
        import.meta.env.DEV === true && console.log("¦userCheck¦", userCheck);
        return userCheck;
    };
    const redeemCode = async (userMail: string) => {
        let params = new URLSearchParams(window.location.search);
        let rdmCd = params.get("redeemCode");
        let convCode = params.get("convCode") || undefined;
        let mE = params.get("mE") || "0";
        let anon = params.get("A") || "0";
        let sub = params.get("sub") || "";
        setSubSplsh(sub);

        if (rdmCd !== null) {
            import.meta.env.DEV == true && console.log("C¦rdmCd", userMail, redeemCode);

            params.delete("redeemCode");
            params.delete("spt");
            params.delete("A");
            params.delete("convCode");
            params.delete("mE");
            params.delete("sub");

            const newUrl = window.location.pathname + (params.toString() ? "?" + params.toString() : "");
            if (userMail !== "") {
                let userCheck = await qCodeChk(rdmCd, userMail, mE, convCode);

                import.meta.env.DEV === true && console.log("C¦rdmCd⇒RESPONSE", userCheck, userMail, rdmCd);
                if (userCheck.cmp) {
                    if (!groupList.some((g: ggroup) => g.groupid == rdmCd)) {
                        let navU: string;
                        if (convCode) {
                            navU = `/${userCheck.cmp}/${rdmCd}/chat/${convCode}`;
                        } else {
                            navU = `/${userCheck.cmp}/${rdmCd}/chat`;
                        }
                        redeemURLRef.current = navU;
                        redeemURLSecondPathRef.current = navU;
                        import.meta.env.DEV === true && console.log("rdmCd¦", navU);
                    }
                } else {
                    window.history.replaceState({}, "", newUrl);
                }
            } else {
                window.history.replaceState({}, "", newUrl);
            }
        }
        return rdmCd;
    };
    const getHeader = (token?: any) => {
        if (token) {
            return {
                ...(loggedInUser?.token ? { Authorization: `Bearer ${token.accessToken}` } : {}),
                "X-MS-CLIENT-PRINCIPAL-NAME": getTokenMail(token),
                "X-MS-CLIENT-PRINCIPAL-ID": getTokenAccountID(token),
                "Content-Type": "application/json"
            };
        } else {
            return {
                ...(loggedInUser?.token ? { Authorization: `Bearer ${loggedInUser.token}` } : {}),
                "X-MS-CLIENT-PRINCIPAL-NAME": loggedInUser?.mail ? loggedInUser.mail : "",
                "X-MS-CLIENT-PRINCIPAL-ID": loggedInUser?.userId ? loggedInUser.userId : "",
                "Content-Type": "application/json"
            };
        }
    };

    // Space Membership User Picker Field Viewer
    const [newSpaceUsrsViewer, setNewSpaceUsrsViewer] = useState<{
        members: ITag[];
        modded: boolean;
    }>({ members: [], modded: false });
    // Space Membership User Picker Field Member
    const [newSpaceUsrsMember, setNewSpaceUsrsMember] = useState<{
        members: ITag[];
        modded: boolean;
    }>({ members: [], modded: false });
    // Space Membership User Picker Field Owner
    const [newSpaceUsrsOwner, setNewSpaceUsrsOwner] = useState<{
        members: ITag[];
        modded: boolean;
    }>({ members: [], modded: false });

    const [isAccountMenuVisible, setIsAccountMenuVisible] = useState<boolean>(false);
    const [isAddSpaceMenuVisible, setIsAddSpaceMenuVisible] = useState<boolean>(false);
    const [initialLoadComleted, setInitialLoadComleted] = useState<boolean>(false);
    // User List Modal State
    const [isUserListModalOpen, setIsUserListModalOpen] = useState(false);
    const [isCompListModalOpen, setIsCompListModalOpen] = useState(false);

    const [isBlurred, setIsBlurred] = useState(false);

    const [initLoad, setInitLoad] = useState(false);
    const [noMembership, setNoMembership] = useState(false);
    const [noGroupsShared, setNoGroupsShared] = useState(false);
    // PDF Viewer Modal State
    const [isPDFModalOpen, setIsPDFModalOpen] = useState<ispdfmodalopen>({
        defaultFile: "",
        startPage: "1",
        isOpen: false,
        searchString: "",
        contentlist: [
            {
                fileid: "",
                srcPageNum: 0,
                srcText: "",
                destPageId: 0,
                destPageNum: 0
            }
        ],
        pagemap: {},
        fileId: "",
        refPageNum: "",
        isCitation: false,
        rndo: "",
        activeGroupId: "",
        fileList: [],
        fl: undefined
    });

    const setChatTab = () => {
        navigate(`${activeComp.companyid}/${selectedGroup.selectionId}/chat`);
    };

    const setLibTab = () => {
        navigate(`/${activeComp.companyid}/${selectedGroup.selectionId}/library`);
    };

    const navChatLib = (loc: string, groupid: string) => {
        if (loc == "chat") {
            navigate(`/${activeComp.companyid}/${groupid}/chat`);
        } else {
            navigate(`/${activeComp.companyid}/${groupid}/library`);
        }
    };

    const setGrpTab = () => {
        setIsSpacePanelOpen(false);
        navigate(`${activeComp.companyid}`);
    };
    //Open History Panel
    const toggleLeftPanel = () => {
        qHistGetList(user.userId, selectedGroup.selectionId, activeComp.companyid, 5);
        setIsLeftPanelOpen(!isLeftPanelOpen);
    };
    const toggleSpacePanel = () => {
        qHistGetRecent(user.userId, activeComp.companyid);
        setIsSpacePanelOpen(!isSpacePanelOpen);
    };

    // Close New Space Panel
    const closeSpacesPanel = () => {
        setIsShareWithUsersOpen(false);
        setIsUserListOpen(false);
        setIsSpaceModalOpen(false);
        setNewSpaceName("");
        setNewSpaceDescription("");
        setNewSpaceShareWithCode(undefined);
        setNewAIInst("");
        setNewCustGreetingMessage("");
        setNewIsIntegratedData(undefined);
        clearUserTagPickers();
    };

    const openSpacesAnalyticsPanel = () => {
        qGetChatLog();
        setIsSpaceAnalyticsModalOpen(true);
    };
    // Open Edit Space Panel
    const openSpacesEditPanel = () => {
        setIsSpaceEditModalOpen(true);
    };
    // Close Edit Space Panel
    const closeSpacesEditPanel = () => {
        setIsShareWithUsersOpen(false);
        setIsUserListOpen(false);
        setIsSpaceEditModalOpen(false);
        setNewSpaceName("");
        setNewSpaceDescription("");
        setNewAIInst("");
        setNewSpaceShareWithCode(undefined);
        clearUserTagPickers();
        setNewCustGreetingMessage("");
        setNewIsIntegratedData(undefined);
    };
    const closeSpacesAnalyticsPanel = () => {
        setIsSpaceAnalyticsModalOpen(false);
    };
    const toggleShareWithUsers = () => {
        //if (!isShareWithUsersOpen) setIsUserListOpen(false);
        setIsShareWithUsersOpen(!isShareWithUsersOpen);
    };
    const toggleSpaceUserList = () => {
        //if (!isUserListOpen) setIsShareWithUsersOpen(false);
        setIsUserListOpen(!isUserListOpen);
    };
    // Clear User Picker Tags on Close
    const clearUserTagPickers = () => {
        setNewSpaceUsrsViewer({ members: [], modded: false });
        setNewSpaceUsrsMember({ members: [], modded: false });
        setNewSpaceUsrsOwner({ members: [], modded: false });
    };
    // Close User Edit  Modal
    const closeUserListModal = () => {
        setIsUserListModalOpen(false);
    };
    // Close Company Chooser Modal
    const closeCompListModal = () => {
        setIsCompListModalOpen(false);
    };
    const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
        if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveAnalysisPanelTab(tab);
        }
        setSelectedAnswer(index);
    };
    // Manage Include Category
    const updateCatsState = (newVal: string) => {
        // After file is Uploaded. Toast Notification
        import.meta.env.DEV === true && console.log("UPD_CATS_STATE");
        if (newVal !== "") {
            if (newVal.includes("¦Unsuported URL ⇛ Only Youtube Supported for Now")) {
            } else {
                let subpaths = window.location.hash.split("/").filter(Boolean);
                if (subpaths.length > 2) {
                    if (decodeURIComponent(subpaths[2]) == selectedGroup.selectionId) {
                        qGetSpaceFiles().then((fls: Array<gfile>) => {
                            setInitLoad(false);
                            closeSBSWhenScrenTooSmall(true);
                            if (fls && fls.length > 0) {
                                const dFl = fls[0];
                                const fileStrng = `${dFl.name}_page=${1}_fid=${dFl.fileid}`;
                                console.log("Effect⇒[selectedGroup]⇒setIsPDFModalOpen", fileStrng);
                                setIsPDFModalOpen({
                                    defaultFile: fileStrng,
                                    startPage: "1",
                                    isOpen: false,
                                    searchString: "",
                                    contentlist: dFl?.contentlist || [],
                                    pagemap: dFl?.pagemap || {},
                                    fileId: dFl.fileid,
                                    refPageNum: "1",
                                    isCitation: false,
                                    rndo: Math.random().toString(36).substring(2, 6),
                                    activeGroupId: selectedGroup.selectionId,
                                    fileList: fls,
                                    fl: dFl
                                });
                            } else {
                                console.log("Effect⇒[selectedGroup]⇒setIsPDFModalOpen [NO_FILE]");
                                setIsPDFModalOpen({
                                    defaultFile: "",
                                    startPage: "1",
                                    isOpen: false,
                                    searchString: "",
                                    contentlist: [],
                                    pagemap: {},
                                    fileId: "",
                                    refPageNum: "1",
                                    isCitation: false,
                                    rndo: Math.random().toString(36).substring(2, 6),
                                    activeGroupId: selectedGroup.selectionId,
                                    fileList: fls,
                                    fl: undefined
                                });
                            }
                        });
                    }
                }
            }
        }
    };
    const listRefreshRequest = () => {
        console.log("lstRfrshRqst¦", selectedGroup, window.location.hash.split("/").filter(Boolean));
        let subpaths = window.location.hash.split("/").filter(Boolean);
        if (subpaths.length > 2) {
            if (decodeURIComponent(subpaths[2]) == selectedGroup.selectionId) {
                import.meta.env.DEV === true && console.log("list_refresh_request¦qGtFls");
                qGetSpaceFiles();
            }
        }
    };
    const [isDark, setIsDark] = useState(false);

    const handleIsDark = (isDark: boolean) => {
        if (isDark == true) {
            DarkReader.enable({
                mode: 1,
                brightness: 100,
                contrast: 100,
                grayscale: 0,
                sepia: 0,
                useFont: false,
                fontFamily: "Urbanist, Roboto",
                textStroke: 0,
                darkSchemeBackgroundColor: "#252525",
                darkSchemeTextColor: "#e8e6e3",
                lightSchemeBackgroundColor: "#dcdad7",
                lightSchemeTextColor: "#181a1b",
                scrollbarColor: "auto",
                selectionColor: "auto",
                styleSystemControls: true
            });
        } else {
            DarkReader.disable();
        }
    };
    // Config Panel State
    const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false);
    const [isSpacePanelOpen, setIsSpacePanelOpen] = useState(false);

    const [config, setConfig] = useState<gConf>(
        JSON.parse(
            localStorage.getItem("configObj6") ||
                `{
                    "promptTemplate": "Assistant helps find answers to questions about information in the available documents. If there isn't enough information below, ask for more specific information.",
                    "retrieveCount": 5,
                    "retrievalMode": "hybrid",
                    "useSemanticRanker": true,
                    "shouldStream": true,
                    "useSemanticCaptions": false,
                    "searchOnly": false,
                    "useHighlightsAsSource": false,
                    "useSuggestFollowupQuestions": true,
                    "isDark": false,
                    "experimentalFeatures": false,
                    "showDocLibInChatTab": false,
                    "sourceHighlighting":false
                }`
        )
    );

    const pathGetValidComp = (cFlatList: gcompflat[], subpaths: string[]) => {
        if (cFlatList.length > 0) {
            //console.log("HPN¦CompFlatList 1", cFlatList);
            const comp = cFlatList.filter((comp: any) => comp.companyid === subpaths[0])[0];
            if (comp) {
                console.log("COMP¦pathGetValidComp", comp);
            } else {
                navigate("/");
            }
        }
    };
    function waitForGroupList(): Promise<void> {
        return new Promise<void>(resolve => {
            function checkGroupList() {
                if (groupListRan == true) {
                    resolve();
                } else {
                    setTimeout(checkGroupList, 300);
                }
            }
            checkGroupList();
        });
    }

    const handlePathNavigation = async (cFlatList: gcompflat[], userObj: any, redeemResult: string | null) => {
        console.log("handlePathNavigation¦", location);
        let subpaths = location.pathname.split("/").filter(Boolean);
        if (redeemURLRef.current) {
            import.meta.env.DEV === true && console.log("¦redeemURLRef¦", redeemURLRef.current);
            let rU = redeemURLRef.current;
            subpaths = rU.split("/").filter(Boolean);
            console.log("ru_splt", subpaths);
            window.history.replaceState({}, "", `${window.location.origin}/#${rU}`);
            redeemURLRef.current = null;
        }
        console.log("hndlPthNvgton¦subpaths", subpaths);
        // Validate Company Path
        if (subpaths.length > 0) {
            pathGetValidComp(cFlatList, subpaths);
        }

        if (subpaths.length == 0) {
            console.log("HPN¦subpaths", subpaths);
            const homeComps = cFlatList.filter((comp: any) => comp.companyid == userObj?.companyid);
            if (homeComps && homeComps.length > 0) {
                let homeComp = homeComps[0];
                navigate(`/${homeComp.companyid}`);
            } else if (cFlatList.length > 0) {
                navigate(`/${cFlatList[0].companyid}`);
            } else {
                setNoMembership(true);
                uMenuEmpty();
                return;
            }
        } else if (subpaths.length === 1) {
            setIsLib("grp");
            if (config.showDocLibInChatTab == true) {
                setConfig(prevConfig => ({
                    ...prevConfig,
                    showDocLibInChatTab: false
                }));
            }
            if (activeComp.companyid !== subpaths[0]) {
                if (cFlatList.length > 0) {
                    const comp = cFlatList.filter((comp: any) => comp.companyid === subpaths[0])[0];
                    if (comp) {
                        setActiveComp(comp);
                    } else {
                        navigate("/");
                    }
                }
            }
        } else if (subpaths.length >= 2) {
            console.log("HPN¦subpaths", subpaths);
            let groupPath = decodeURIComponent(subpaths[1]);
            let isUserMenu = groupPath == "acc" || groupPath == "adm";
            if (activeComp.companyid !== subpaths[0]) {
                if (config.showDocLibInChatTab == true) {
                    setConfig(prevConfig => ({
                        ...prevConfig,
                        showDocLibInChatTab: false
                    }));
                }
                if (userObj && userObj.userId && userObj.userId !== "") {
                    applyLocalConfig();
                    if (cFlatList.length > 0) {
                        const comp = cFlatList.filter((comp: any) => comp.companyid === subpaths[0])[0];
                        if (comp) {
                            setActiveComp(comp);
                            if (groupPath == "acc") {
                                await qGetTiers();
                                //await waitForGroupList();
                                setIsLib("acc");
                            } else if (groupPath == "adm") {
                                await qGetAllUsers();
                                setIsLib("adm");
                            } else {
                                setIsLib("grp");
                            }
                        }
                        setIsLoading(false);
                    } else {
                        setIsLoading(false);
                    }
                }
            } else {
                let groupPath = decodeURIComponent(subpaths[1]);
                console.log("groupPath¦", groupPath, groupList);
                if (selectedGroup.selectionId !== groupPath) {
                    if (config.showDocLibInChatTab == true) {
                        setConfig(prevConfig => ({
                            ...prevConfig,
                            showDocLibInChatTab: false
                        }));
                    }
                    if (groupList && groupList.length > 0) {
                        let gr = groupList.filter((group: any) => group.groupid === groupPath);
                        if (gr && gr.length > 0) {
                            setSelectedGroup(groupListToSelectedGroup(gr[0]));
                        } else if (groupPath == "acc") {
                            await qGetTiers();
                            //await waitForGroupList();
                            setIsLib("acc");
                        } else if (groupPath == "adm") {
                            await qGetAllUsers();
                            setIsLib("adm");
                        } else {
                            setSelectedGroup(groupListToSelectedGroup());
                            window.location.href = "/";
                            return;
                        }
                    } else {
                        if (groupPath == "acc") {
                            await qGetTiers();
                            //await waitForGroupList();
                            setIsLib("acc");
                        } else if (groupPath == "adm") {
                            await qGetAllUsers();
                            setIsLib("adm");
                        } else {
                            setSelectedGroup(groupListToSelectedGroup());
                            window.location.href = "/";
                            return;
                        }
                    }
                } else {
                    if (subpaths?.[3]) {
                        qHistGetByKey(user.userId, subpaths?.[3]).then(() => {
                            setLoadingChatModeSwitch(false);
                        });
                    } else {
                        setIsLoading(false);
                    }
                }
            }

            if (subpaths?.[2]) {
                if (subpaths[2] === "chat") {
                    if (subpaths?.[3]) {
                        console.log("NAV¦CHT", subpaths[3]);
                        //setActiveConvoKey(subpaths[3]);
                    }
                    setIsLib("chat");
                } else if (subpaths[2] === "library") {
                    setIsLib("lib");
                } else if (subpaths[2] === "explorer") {
                    setIsLib("xplr");
                }
            } else if (isUserMenu == false) {
                setIsLib("grp");
            }
        }
    };

    const groupListToSelectedGroup = (group?: ggroup) => (selectedgroup: gselectedgroup) => {
        return {
            selectionId: group ? group.groupid || "" : "",
            selectionText: group ? group.groupname || "" : "",
            permissionlevel: group ? group.permissionlevel || 0 : 0,
            description: group ? group.description || "" : "",
            sharewithcode: group ? group.sharewithcode || false : false,
            filecount: group ? group.filecount || 0 : 0,
            custaiinst: group ? group.custaiinst || "" : "",
            custgreetingmessage: group ? group.custgreetingmessage || "" : "",
            isintegrateddata: group ? group.isintegrateddata || 0 : 0,
            allowyoutube: group ? group.allowyoutube ?? 1 : 1,
            allowweb: group ? group.allowweb ?? 1 : 1,
            enabledocaudio: group ? group.enabledocaudio ?? 1 : 1,
            retrievecount: group ? group.retrievecount ?? 5 : 5,
            sectionoverlap: group ? group.sectionoverlap ?? 200 : 200,
            maxsectionlength: group ? group.maxsectionlength ?? 3000 : 3000,
            sentencesearchlimit: group ? group.sentencesearchlimit ?? 200 : 200,
            inscope: group ? group.inscope ?? 1 : 1,
            temperature: group ? group.temperature ?? 0.7 : 0.7,
            responsetokenlimit: group ? group.responsetokenlimit ?? 3024 : 3024,
            disablecitationhighlighting: group ? group.disablecitationhighlighting ?? 0 : 0,
            strictness: group ? group.strictness ?? 3 : 3,
            invoicesgroup: group ? group.invoicesgroup ?? 0 : 0,
            questionsuggestions: group ? group.questionsuggestions ?? 0 : 0,
            nosidebysideview: group ? group.nosidebysideview ?? 0 : 0,
            isowner: group ? group.isowner ?? 0 : 0,
            dailymsgs: group ? group.dailymsgs ?? 0 : 0,
            premiumby: group ? group.premiumby ?? "" : "",
            oid: group ? group.oid ?? 0 : 0,
            tier: group ? group.tier ?? 0 : 0,
            premwhen: group ? group.premwhen ?? 0 : 0,
            companyid: group ? group.companyid ?? "" : "",
            apikey: group ? group.apikey ?? "" : "",
            producecontentlist: group ? group.producecontentlist ?? 0 : 0,
            producequestions: group ? group.producequestions ?? 0 : 0,
            assistanttype: group ? group.assistanttype ?? "anya" : "anya",
            showexamplequestionsonstart: group ? group.showexamplequestionsonstart ?? 0 : 0,
            headerimageurl: group ? group.headerimageurl ?? "" : "",
            manualquestionsuggestions: group ? group.manualquestionsuggestions ?? "" : "",
            usedocintel: group ? group.usedocintel ?? 0 : 0,
            extractionfieldlist: group ? group.extractionfieldlist ?? "" : ""
        };
    };

    useEffect(() => {
        if (initialLoadComleted == true) {
            import.meta.env.DEV === true && console.log("handlePathNavigation¦ALTERNATE_PATH");
            handlePathNavigation(compFlatList, user, null);
        }
    }, [location]);

    useEffect(() => {
        setGreetingMessageHtml(createSuggestedQuestionsHTML());
    }, [suggestedQuestions]);

    function removeTaskIdFromLocal(taskId: string) {
        let taskIds = localStorage.getItem("taskIds")?.split(";");
        taskIds = taskIds?.filter(id => id != taskId);
        localStorage.setItem("taskIds", taskIds ? taskIds.join(";") : "");
    }

    const getIfrmParam = () => {
        console.log("href", window.location.href);
        if (window.location.href?.indexOf("ifrm=true") > 0 == true) {
            return "?ifrm=true";
        } else {
            return "";
        }
    };
    const hndlIfrm = () => {
        if (window.location.href?.indexOf("ifrm=true") > 0 == true) {
            console.log("IS_IFRAME");
            setIsIframe(true);
        } else {
            setIsIframe(false);
        }
    };

    const pollTaskStatus = async (taskId: string, progressCallback?: () => void) => {
        const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
        const fetchTaskStatus = async (taskId: string) => {
            const statusResponse = await fetch(`/task-status/${taskId}`);
            if (!statusResponse.ok) {
                console.log("Task ID Not Found: ", taskId);
                removeTaskIdFromLocal(taskId);
                return;
            }
            return await statusResponse.json();
        };

        let taskCompleted = false;
        let taskResult = false;
        let taskMessage = "";
        let taskMessagePrev = "";
        const toastId = toast("Uploading Files...", { autoClose: false });

        while (!taskCompleted) {
            await wait(1000);
            const chkResp = await fetchTaskStatus(taskId);
            import.meta.env.DEV === true && console.log("pldFlAp¦Next_Response", chkResp);

            if (chkResp.status == "success" || chkResp.status == "partial" || chkResp.status == "error") {
                taskResult = chkResp.status == "success" || chkResp.status == "partial";
                taskMessage = chkResp.message;
                console.log("Task Completed:", chkResp);
                taskCompleted = true;
                removeTaskIdFromLocal(taskId);
                toast.update(toastId, {
                    render: chkResp.message,
                    autoClose: 7000,
                    progress: 1
                });
            } else {
                try {
                    let progress = chkResp.progressCount / chkResp.fileCount;
                    taskMessage = `Processing Files... ${chkResp.progressCount + 1} of ${chkResp.fileCount}`;
                    toast.update(toastId, {
                        render: taskMessage,
                        progress: progress
                    });
                } catch {
                    taskMessage = `Processing Files... ${chkResp.progressCount + 1} of ${chkResp.fileCount}`;
                    toast.update(toastId, {
                        render: taskMessage
                    });
                }
            }
            if (taskMessagePrev !== taskMessage && progressCallback) {
                progressCallback();
            }
            taskMessagePrev = taskMessage;
        }

        return { taskResult, taskMessage };
    };
    const startAllTasks = async (taskIds: string[], progressCallback?: () => void) => {
        let taskIdsNoEmpties = taskIds.filter(id => id !== "");
        const taskPromises = taskIdsNoEmpties.map(taskId => pollTaskStatus(taskId, progressCallback));

        try {
            const results = await Promise.all(taskPromises);
            return results;
        } catch (error) {
            console.error("One or more tasks failed:", error);
            throw error;
        }
    };

    // Effect⇒[client] (INIT LOAD)
    useEffect(() => {
        if (!msalInstance) return;

        hndlIfrm();
        setInitLoad(true);
        if (initialLoadComleted === false) {
            console.log("UE0¦INIT¦3.17");

            applyLocalConfig();
            qGetUserInfo().then(userObj => {
                if (userObj === null) {
                    uMenuEmpty();
                    setNoMembership(true);
                    if (userObj === null) {
                        uMenuEmpty();
                        setNoMembership(true);
                        fetch(import.meta.env.VITE_EXT_SERV_ANIP)
                            .then(response => response.json())
                            .then(data => {
                                if (data && data.ip && data.ip != "") {
                                    qLi("land", `user_is_null ${data.ip}`);
                                } else {
                                    qLi("land", `user_is_null nip`);
                                }
                                return;
                            });
                    }
                    return;
                }

                qGetCompFlatList().then((cFlatList: gcompflat[]) => {
                    setInitialLoadComleted(true);
                    import.meta.env.DEV === true && console.log("UE¦qGtCmpFltLst", cFlatList, location);
                    qLi("land", `init_load`, userObj.userMail);
                    handlePathNavigation(cFlatList, userObj, null);

                    //qGetAllUsers();
                });
            });
        }
        const handleSpsExtrStng = () => {
            qUSp("dataEx", "1");
        };
        window.greetingMessageAddNewSpace = (param: string) => {
            if (isIframe == true) {
                toast.error("This feature is not available in the embedded version of the app.");
            } else {
                //setIsSpaceModalOpen(true);
                handleSpsExtrStng();
            }
        };
        window.greetingMessageViewDocs = (param: string) => {
            if (isIframe == true) {
                toast.error("This feature is not available in the embedded version of the app.");
            } else {
                var currentPath = window.location.hash.split("/");
                currentPath[currentPath.length - 1] = "library";
                window.location.hash = currentPath.join("/");
                return false;
            }
        };
        qGetTiers();
    }, [msalInstance]);

    // Effect⇒[activeComp]
    useEffect(() => {
        //console.log("activeComp¦", activeComp);
        if (activeComp.companyid !== "") {
            //console.log("UE0¦activeComp", activeComp, compFlatList, user);
            import.meta.env.DEV == true && console.log("activeComp¦UE", activeComp);
            uMenu(user, compFlatList, activeComp);
            qGetGroups().then((groupList: any) => {
                if (user && user.userId && user.userId !== "") {
                    let subpaths;
                    if (redeemURLSecondPathRef.current) {
                        let rU = redeemURLSecondPathRef.current;
                        subpaths = rU.split("/").filter(Boolean);
                        console.log("¦ctvCmp¦rdeemUrl¦SPLIT¦", subpaths);
                        redeemURLSecondPathRef.current = null;
                    } else {
                        subpaths = location.pathname.split("/").filter(Boolean);
                    }

                    const groupPath = decodeURIComponent(subpaths[1]);

                    if (selectedGroup.selectionId !== groupPath || groupPath == undefined) {
                        if (groupList && groupList.length > 0) {
                            console.log("comp¦groups gt 0");
                            let gr = groupList.filter((group: any) => group.groupid === groupPath)[0];
                            if (gr) {
                                setSelectedGroup(groupListToSelectedGroup(gr));
                            } else {
                                // No Group, Navigate to Default Group
                                //if (activeComp.companyid == user.companyid) {
                                //    setSelectedGroup({ selectionId: user.userId, selectionText: "Personal Space", permissionlevel: 2, description: "" });
                                //} else {
                                if (groupList && groupList.length > 0) {
                                    let gr = groupList[0];
                                    setSelectedGroup(groupListToSelectedGroup(gr));
                                } else {
                                    groupListToSelectedGroup();
                                }
                                //}
                            }
                        } else {
                            setSelectedGroup(groupListToSelectedGroup());
                        }
                    }
                    if (groupList.length == 0) {
                        setNoGroupsShared(true);
                    } else {
                        setNoGroupsShared(false);
                    }
                }
            });
            qGetCompanyUsers();
        }
    }, [activeComp]);

    useEffect(() => {
        window.globalFL = fileList;
    }, [fileList]);

    //Effect⇒[selectedGroup] ON SPACE CHANGE
    useEffect(() => {
        if (selectedGroup.selectionId !== "") {
            setConvos([]);
            setLoadingChatModeSwitch(true);
            newConverson();
            import.meta.env.DEV === true && console.log("CHT_UE¦selectedGroup", selectedGroup);
            qGetSpaceFiles(true, true).then((fls: Array<gfile>) => {
                setInitLoad(false);

                document.title = `kgnf ¦ ${selectedGroup.selectionText}`;

                closeSBSWhenScrenTooSmall(true);
                dealWithFilesForPDFViewers(fls);

                if (fls?.length > 50) {
                    localStorage.setItem(`kgnf_chs_${selectedGroup.oid}`, JSON.stringify(fls));
                }
            });
            const subpaths = location.pathname.split("/").filter(Boolean);
            if (subpaths?.[3]) {
                qHistGetByKey(user.userId, subpaths?.[3]).then(() => {
                    setLoadingChatModeSwitch(false);
                });
            } else {
                qHistGetLatest(user.userId, selectedGroup.selectionId, activeComp.companyid, 1).then((lConv: any) => {
                    if (lConv && lConv.length > 0) {
                        console.log("afsdsdffdgfdgdfg", lConv);
                        setActiveConvoKey(lConv[0].key); // Set the active conversation key to the latest key (Datetime string)
                        const histObj = JSON.parse(lConv[0].content);
                        setAnswersFunction(histObj, true);
                        setStreamedAnswers(histObj);
                    } else {
                        const newKey = new Date() // Convo Key (Datetime String)
                            .toISOString()
                            .replace(/[-:.TZ]/g, "")
                            .slice(0, 14);

                        setActiveConvoKey(newKey); // Set the active conversation key to the latest key (Datetime string)
                        setAnswersFunction([], true);
                        setStreamedAnswers([]);
                    }
                    setLoadingChatModeSwitch(false);
                });
            }
            if (selectedGroup?.permissionlevel >= 2) {
                qGetGroupsMemberships();
            }
        }
    }, [selectedGroup]);

    const dealWithFilesForPDFViewers = (fls: Array<gfile>) => {
        if (fls && fls.length > 0) {
            const dFl = fls[0];
            const fileStrng = `${dFl.name}_page=${1}_fid=${dFl.fileid}`;

            //console.log("Effect⇒[selectedGroup]¦selectedGroup¦", dFl, "¦fileStrng¦", fileStrng);
            setIsPDFModalOpen({
                defaultFile: fileStrng,
                startPage: "1",
                isOpen: false,
                searchString: "",
                contentlist: dFl?.contentlist || [],
                pagemap: dFl?.pagemap || {},
                fileId: dFl.fileid,
                refPageNum: "1",
                isCitation: false,
                rndo: Math.random().toString(36).substring(2, 6),
                activeGroupId: selectedGroup.selectionId,
                fileList: fls,
                fl: dFl
            });
        } else {
            closeSBSWhenNoFiles();
            console.log("Effect⇒[selectedGroup]⇒setIsPDFModalOpen [NO_FILE]");
            setIsPDFModalOpen({
                defaultFile: "",
                startPage: "1",
                isOpen: false,
                searchString: "",
                contentlist: [],
                pagemap: {},
                fileId: "",
                refPageNum: "1",
                isCitation: false,
                rndo: Math.random().toString(36).substring(2, 6),
                activeGroupId: selectedGroup.selectionId,
                fileList: fls,
                fl: undefined
            });
        }
    };

    const pushUrlLevel = (newLevel: string) => {
        const currentPath = `${window.location.pathname}${window.location.hash}`;
        const newPath = currentPath.endsWith("/") ? `${currentPath}${newLevel}` : `${currentPath}/${newLevel}`;

        window.history.pushState(null, "", newPath);
    };
    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [isLoading]);

    useEffect(() => {
        if (initLoad == false) {
            if (!activeConvoKey) return;
            setLoadingChatModeSwitch(true);
            const oldConvo = activeConvoKey;
            setConversation(oldConvo);
        }
        setShouldPlayAudio(Math.random().toString(36).substring(2, 6));
    }, [config.showDocLibInChatTab]);

    useEffect(() => {
        chatMessageStreamEnd.current?.scrollIntoView({ behavior: "auto" }), [answers];

        setIsLibTracker(isLib);
        setShouldPlayAudio(Math.random().toString(36).substring(2, 6));
    }, [isLib]);

    useEffect(() => {
        chatMessageStreamEnd.current?.scrollIntoView({ behavior: "auto" }), [answers];
    }, [xplrrChatVisible]);
    useEffect(() => {
        if (window.globalStreamOneSecondFlag == false || typeof window.globalStreamOneSecondFlag === "undefined") {
            window.globalStreamOneSecondFlag = true;
            if (xplrrMsgStrm.current) {
                const element = xplrrMsgStrm.current;
                console.log("scrollHeight", element.scrollHeight, xplrrMsgStrm.current.scrollTop + xplrrMsgStrm.current.clientHeight);
                if (xplrrMsgStrm.current.scrollHeight - (xplrrMsgStrm.current.scrollTop + xplrrMsgStrm.current.clientHeight) < 250) {
                    if (chatMessageStreamEnd.current) {
                        smoothScrollIntoView(chatMessageStreamEnd.current, { behavior: "smooth" });
                    }
                }
            }
            const timeoutId = setTimeout(() => {
                window.globalStreamOneSecondFlag = false;
                clearTimeout(timeoutId);
            }, 500);
        }

        //if (isStreaming) {
        //    if (window.globalAnswerStreamingFirstCitationFlag == false || typeof window.globalAnswerStreamingFirstCitationFlag === "undefined") {
        //        const lastIndex = streamedAnswers.length - 1;
        //        //scrollToFirstCitation(lastIndex, isLib, config, onShowCitationXplrr);
        //    }
        //}
    }, [streamedAnswers]);

    const scrollToFirstCitation = debounce((lastIndex: number, isLib: string, config: any, onShowCitationXplrr: Function) => {
        try {
            let citRefs = document.getElementById(`cit_answid_${lastIndex}_citinx_0`);
            if (citRefs) {
                window.globalAnswerStreamingFirstCitationFlag = true;
                let citId = `/content/${citRefs.title}`;
                if (isLib === "chat" && config.showDocLibInChatTab === true) {
                    onShowCitationXplrr(citId, null, lastIndex);
                }
            }
        } catch (error) {
            console.error("Error in scrollToFirstCitation:", error);
        }
    }, 400); // 300ms debounce time, adjust as needed
    useEffect(() => {
        if (isStreaming == false) {
            window.globalAnswerStreamingFirstCitationFlag = false;
        }
    }, [isStreaming]);

    useEffect(() => {
        if (xplrrMsgStrm.current) {
            const element = xplrrMsgStrm.current;
            element.scrollTop = element.scrollHeight;
        }
    }, [answers, config.showDocLibInChatTab]);

    const closeSBSWhenScrenTooSmall = (dontSendToast?: boolean) => {
        if (window.innerWidth < 768) {
            if (config.showDocLibInChatTab == true) {
                if (dontSendToast != true) {
                    toast.warning("Side-by-Side View for Chat and Documents is currently not supported on screens with less than 768px of width");
                }

                setConfig(prevConfig => ({
                    ...prevConfig,
                    showDocLibInChatTab: false
                }));

                return;
            }
        }
    };
    const closeSBSWhenNoFiles = () => {
        if (config.showDocLibInChatTab == true) {
            setConfig(prevConfig => ({
                ...prevConfig,
                showDocLibInChatTab: false
            }));
        }
        return;
    };
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.altKey && event.key.toLowerCase() === "p") {
                event.preventDefault();
                setIsConfigPanelOpen(!isConfigPanelOpen);
            }
        };
        const handleResize = debounce(() => {
            closeSBSWhenScrenTooSmall();
        }, 500);
        window.addEventListener("resize", handleResize);
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("resize", handleResize);
            window.removeEventListener("keydown", handleKeyDown);
            handleResize.cancel();
        };
    }, []);

    const uMenuEmpty = () => {
        const newAccountMenuItems = [
            {
                key: "Logout",
                text: "Logout",
                onClick: () => {
                    if (msalInstance) {
                        msalInstance
                            .logoutPopup({
                                postLogoutRedirectUri: "/", // redirects the top level app after logout
                                account: msalInstance.getActiveAccount(),
                                onRedirectNavigate: url => {
                                    return true;
                                }
                            })
                            .then(() => {
                                localStorage.removeItem("msalAuth4");
                                localStorage.removeItem("msalAuth4Tok");
                                if (isIframe == true) {
                                    window.location.reload();
                                } else {
                                    window.location.href = window.location.href;
                                }
                            });
                    }
                },
                iconProps: { iconName: "SignOut" }
            }
        ];
        setAccMenuItems(newAccountMenuItems);
    };

    const uMenu = (userObj: any, cFlatList: gcompflat[], homeComp: any) => {
        const newAccountMenuItems = [
            {
                key: "User",
                disabled: true,
                text: userObj.userMail,
                iconProps: { iconName: "Contact" }
            },
            {
                key: "DarkMode",
                disabled: false,
                text: "User Settings",

                iconProps: { iconName: "Settings" },
                onClick: () => {
                    setIsConfigPanelOpen(true);
                }
            },
            {
                key: "Logout",
                text: userObj.userMail.indexOf("quest_") > -1 ? "Create Account" : "Logout",
                onClick: () => {
                    if (msalInstance) {
                        msalInstance
                            .logoutPopup({
                                postLogoutRedirectUri: "/", // redirects the top level app after logout
                                account: msalInstance.getActiveAccount(),
                                onRedirectNavigate: url => {
                                    return true;
                                }
                            })
                            .then(() => {
                                localStorage.removeItem("msalAuth4");
                                localStorage.removeItem("msalAuth4Tok");
                                if (isIframe == true) {
                                    window.location.reload();
                                } else {
                                    window.location.href = window.location.href;
                                }
                            });
                    }
                },
                iconProps: { iconName: "SignOut" }
            }
        ];
        try {
            if (cFlatList && cFlatList.length > 0 && cFlatList.some(a => a.permissionlevel >= 3 && a.companyid === homeComp.companyid) && isIframe != true) {
                newAccountMenuItems.splice(1, 0, {
                    key: "ManageUsers",
                    text: "Administrative",
                    onClick: () => {
                        openUserList();
                    },
                    iconProps: { iconName: "Admin" }
                });
            }
            if (userObj.userMail.indexOf("quest_") == -1 && isIframe != true && activeComp.licensingkind == 0) {
                newAccountMenuItems.splice(1, 0, {
                    key: "ManageAccount",
                    text: "Manage Account",
                    onClick: () => {
                        //qGetGroupsAdmin();
                        //setIsLib("acc");
                        navigate(`${activeComp.companyid}/acc`);
                    },
                    iconProps: { iconName: "AccountManagement" }
                });
            }
            if (cFlatList && cFlatList.length > 1 && isIframe != true) {
                newAccountMenuItems.splice(1, 0, {
                    key: "ManageCompanies",
                    text: homeComp.companyname,
                    onClick: () => {
                        setIsCompListModalOpen(true);
                    },

                    iconProps: { iconName: "Group" }
                });
            }
        } catch (err) {
            console.log("UMENU¦Error", err);
        }

        setAccMenuItems(newAccountMenuItems);
    };
    const openUserList = () => {
        qGetAllUsers().then((users: any) => {
            //setIsUserListModalOpen(true);
            //setIsLib("adm");
            navigate(`${activeComp.companyid}/adm`);
        });
    };
    // Effect⇒[answers]
    // Save Convo to Local Storage (Convo History) (Indexed Hash Array of Arrays)
    useEffect(() => {
        if (answers && answers.length > 0) {
            console.log("UE¦[answers]¦answers⇒", answers);
            if (answersLength.current != answers.length) {
                console.log("¦qHistAdd¦");
                qHistAdd(parseInt(activeConvoKey), user.userId, selectedGroup.selectionId, activeComp.companyid, answers[0][0]);
                answersLength.current = answers.length;
            }

            if (activeConvoKey) {
            } else {
                newConverson();
            }
        }
        window.greetingMessageBeginConversation = (param: string) => {
            console.log("Greeting Message Begin Conversation", param);
            if (param != "") {
                makeApiRequest(param);
            } else {
                let dcs = fileList.map((f: gfile) => f.name);
                let shfl = shuffleArray([...dcs]).slice(0, 3);
                makeApiRequest(shfl.join(", "));
            }
        };
    }, [answers]);

    useEffect(() => {
        clickedCitation.current = "";
    }, [activeConvoKey]);
    // Effect⇒[config]
    //UE Save Config to Local Storage
    useEffect(() => {
        //import.meta.env.DEV === true && console.log("UE preserveLocalConfig", config);
        preserveLocalConfig();
        setSourceHighlighting(config.sourceHighlighting);
    }, [config]);

    // applyLocalConfig
    const applyLocalConfig = () => {
        const configObj = localStorage.getItem("configObj6");
        if (configObj) {
            //import.meta.env.DEV === true && console.log("applyLocalConfig⇒GTE CONFIG", configObj);
            let configParsed = JSON.parse(configObj);
            configParsed.showDocLibInChatTab = false;
            handleIsDark(configParsed.isDark);
            setConfig(configParsed);
            closeSBSWhenScrenTooSmall();
        } else {
            import.meta.env.DEV === true && console.log("applyLocalConfig⇒NO CONFIG set DEFAULT");
            let configDefault = JSON.stringify(config);
            localStorage.setItem("configObj6", configDefault);
            closeSBSWhenScrenTooSmall();
        }
    };
    const preserveLocalConfig = () => {
        let configString = JSON.stringify(config);
        //import.meta.env.DEV === true && console.log("preserveLocalConfig", configString);
        localStorage.setItem("configObj6", configString);
    };
    const setAnswersFunction = (histObj: any, setAnswersLength?: boolean) => {
        if (setAnswersLength == true) {
            answersLength.current = (histObj ?? []).length;
        }
        setAnswers(histObj);
        console.log("SET_ANSWERS", histObj);
    };
    // History
    const qHistGetByKey = async (userid: string, key: string) => {
        try {
            const params = new URLSearchParams({
                userid: userid,
                key: key
            });
            const url = "/qHistGetByKey" + `?${params.toString()}`;
            const response = await fetch(url, {
                headers: getHeader()
            });
            if (!response.ok) {
                throw Error("Failed to Get Latest History");
            }
            let keyConvo = await response.json();
            const histObj = JSON.parse(keyConvo[0].content);
            console.log("QHGB¦HistObj", histObj);

            setActiveConvoKey(keyConvo[0].key); // Set the active conversation key to the latest key (Datetime string)

            setAnswersFunction(histObj, true);
            setStreamedAnswers(histObj);
            setLoadingChatModeSwitch(false);
        } catch {
            setLoadingChatModeSwitch(false);
        }
    };
    // History
    const qHistGetRecent = async (userid: string, company: string) => {
        try {
            const params = new URLSearchParams({
                userid: userid,
                company: company
            });
            const url = "/qHistGetRecent" + `?${params.toString()}`;
            const response = await fetch(url, {
                headers: getHeader()
            });
            if (!response.ok) {
                throw Error("Failed to Get Latest History");
            }

            let recentConvosUserWide = await response.json();

            setRecentConvosAccrosSpaces(recentConvosUserWide);
        } catch (err) {
            console.log("Failed to Get Recent Convos For User", err);
        }
    };
    const qGetSuggestedQuestions = async (fLst: gfile[]) => {
        try {
            if (fLst.length == 0) {
                setSuggestedQuestions([]);
                return;
            }
            const params = new URLSearchParams({
                fileids: fLst
                    .map(f => f.fileid)
                    .sort(() => 0.5 - Math.random())
                    .slice(0, 3)
                    .join(",")
            });
            const url = "/qGetSuggestedQuestions" + `?${params.toString()}`;
            const response = await fetch(url, {
                headers: getHeader()
            });
            if (!response.ok) {
                throw Error("Failed to Get Suggested Questions");
            }
            let suggestedQuestions = await response.json();

            setSuggestedQuestions(suggestedQuestions);
            console.log("SQ¦", suggestedQuestions);
        } catch {}
    };
    const shuffleArray = (array: string[]) => {
        const shuffled = [...array];
        for (let i = shuffled.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
        }
        return shuffled;
    };
    const encodeHTML = (str: string): string => {
        return str.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, " ");
    };
    const createSuggestedQuestionsHTML = () => {
        // Join all suggestedquestions with ';' and then split
        let defaultContent = "";
        if (selectedGroup.permissionlevel >= 2) {
            if (selectedGroup.filecount > 0) {
                defaultContent = "";
            } else {
                defaultContent = `
                <div class="greetingPretext">It looks like there is no content for this Assistant and you have permissions to add content. Head on to the Content Section</div>
                <div class="answerCard" onclick="window.greetingMessageViewDocs('')">
                  <div class="greetingItemTitle" style="font-size: 24px; color: ">View Content List</div>
                  <div class="topGreetingText">View what documents are available to this Assistant. Add Content to get started.</div>
                </div>
                `;
            }
        } else {
            if (selectedGroup.filecount > 0) {
                defaultContent = "";
            } else {
                defaultContent = `<div class="greetingPretext">It looks like there is no content for this Assistant yet.</div>`;
            }
        }

        const allQuestions = (suggestedQuestions || [])
            .map((q: any) => q.suggestedquestions)
            .join(";")
            .split(";")
            .filter((question: string) => question !== "" && question != null && question !== undefined);
        if (allQuestions && allQuestions.length > 0 && selectedGroup?.showexamplequestionsonstart == 1) {
            let selectedQuestions = shuffleArray([...allQuestions]).slice(0, 3);

            if (selectedGroup.manualquestionsuggestions != "") {
                selectedQuestions = [...selectedGroup.manualquestionsuggestions.split(";"), ...selectedQuestions];
            }

            const html = selectedQuestions.map(
                question =>
                    `
            <div class="answerCard" onclick="window.greetingMessageBeginConversation('${encodeHTML(question)}');">
                <div class="topGreetingText">Suggested Question</div>
                <hr class="hrzntlLn">
                <i class="fas fa-lightbulb" style="font-size: 24px; color: #ffc107;"></i>
                <h3 class="greetingItemTitle">${encodeHTML(question)}</h3>

            </div>
        `
            );

            return `<div horizontal wrap tokens=5 class='answerCardContainer'>${html.join("")}</div>`;
        } else if (selectedGroup.manualquestionsuggestions != "") {
            const html = selectedGroup.manualquestionsuggestions.split(";").map(
                question =>
                    `
            <div class="answerCard" onclick="window.greetingMessageBeginConversation('${encodeHTML(question)}');">
                <div class="topGreetingText">Suggested Question</div>
                <hr class="hrzntlLn">
                <i class="fas fa-lightbulb" style="font-size: 24px; color: #ffc107;"></i>
                <h3 class="greetingItemTitle">${encodeHTML(question)}</h3>

            </div>
        `
            );

            return `<div horizontal wrap tokens=5 class='answerCardContainer'>${html.join("")}</div>`;
        } else {
            return defaultContent;
        }
    };
    const qHistGetLatest = async (userid: string, groupid: string, companyid: string, topn: number) => {
        console.log("qHstGtLtst");
        const params = new URLSearchParams({
            userid: userid,
            groupid: groupid,
            companyid: companyid,
            topn: topn.toString()
        });
        const url = "/qHistGetLatest" + `?${params.toString()}`;
        const response = await fetch(url, {
            headers: getHeader()
        });
        if (!response.ok) {
            throw Error("Failed to Get Latest History");
        }
        let latestConvo = await response.json();
        return latestConvo;
    };
    const qGetTiers = async () => {
        const url = "/qGetTiers";
        const response = await fetch(url, {
            headers: getHeader()
        });
        if (!response.ok) {
            throw Error("Failed to Get Latest History");
        }
        let tiers = await response.json();
        console.log("QGT¦Tiers", tiers);
        setLTiers(tiers);
    };
    const qHistGetList = async (userid: string, groupid: string, companyid: string, topn: number) => {
        const params = new URLSearchParams({
            userid: userid,
            groupid: groupid,
            companyid: companyid,
            topn: topn.toString()
        });
        const url = "/qHistGetList" + `?${params.toString()}`;
        const response = await fetch(url, {
            headers: getHeader()
        });
        if (!response.ok) {
            throw Error("Failed_to_Get_Latest_History");
        }
        let convos = await response.json();
        console.log("QHGL¦Convos", convos);
        setConvos(convos);
    };
    const qHistAdd = async (key: number, userid: string, groupid: string, companyid: string, name: string, aa?: any) => {
        console.log("qha¦", answers);
        fetch("/qHistAdd", {
            method: "POST",
            headers: getHeader(),
            body: JSON.stringify({
                key: key,
                userid: userid,
                groupid: groupid,
                companyid: companyid,
                content: aa || answers,
                name: name
            })
        });
    };
    const qChatLogAdd = async (
        key: number,
        userid: string,
        groupid: string,
        companyid: string,
        question: string,
        answer: string,
        responseTokens?: number,
        promptTokens?: number,
        interaction_guid?: string
    ) => {
        fetch("/qChatLogAdd", {
            method: "POST",
            headers: getHeader(),
            body: JSON.stringify({
                key: key,
                userid: userid,
                groupid: groupid,
                companyid: companyid,
                content: answer,
                name: question,
                responseTokens: responseTokens ?? 0,
                promptTokens: promptTokens ?? 0,
                interaction_guid: interaction_guid ?? ""
            })
        });
    };
    const newConverson = () => {
        const newKey = new Date() // Convo Key (Datetime String)
            .toISOString()
            .replace(/[-:.TZ]/g, "")
            .slice(0, 14);

        import.meta.env.DEV === true && console.log("¦newConverson¦", newKey);
        setActiveConvoKey(newKey); // Set the active conversation key to the latest key (Datetime string)
        setAnswersFunction([], true);
        setStreamedAnswers([]);
        setError(undefined);
    };
    const setConversation = (dateString: string) => {
        import.meta.env.DEV === true && console.log("¦setConversation¦", dateString);
        setLoadingChatModeSwitch(true);
        newConverson();

        qHistGetByKey(user.userId, dateString).then(() => {
            setLoadingChatModeSwitch(false);
        });
        setActiveConvoKey(dateString);
        setIsLeftPanelOpen(false);
    };
    //  qFuncs ¦ compList ⇒ Get List of Companies
    const qGetCompFlatList = async () => {
        const token = await getTokenDyn();
        if (!token) {
            return [];
        }
        const response = await fetch("/qGetCompFlatList", {
            headers: getHeader(token)
        });
        if (!response.ok) {
            throw Error("Failed to Get User Info");
        }
        let compList = await response.json();
        console.log("¦COMPFLATLIST¦", compList);
        setCompFlatList(compList);
        return compList;
    };
    // CompMbrshps ⇒ Space Memberships to Show in Space Edit (qGetGroupsMemberships, qGetCompanyUsers) [Per Comp]
    const qGetGroupsMemberships = async () => {
        const params = new URLSearchParams({
            compd: activeComp.companyid
        });
        const url = "/qGetGroupsMemberships" + `?${params.toString()}`;
        const response = await fetch(url, {
            headers: getHeader()
        });
        if (!response.ok) {
            throw Error("QGETGRPS¦Failed to Groups");
        }
        let mbrshpsResp = await response.json();
        if (mbrshpsResp) {
            setCompMbrshps(mbrshpsResp);
        } else {
            setCompMbrshps([]);
        }
        return mbrshpsResp;
    };
    // CompUsrs ⇒ User Picker Suggestions  [Per Comp]
    const qGetCompanyUsers = async () => {
        const params = new URLSearchParams({
            compd: activeComp.companyid
        });
        const url = "/qGetCompanyUsers" + `?${params.toString()}`;
        const response = await fetch(url, {
            headers: getHeader()
        });
        if (!response.ok) {
            throw Error("Failed to Get User Info");
        }
        let compUsers = await response.json();
        setCompUsrs(compUsers);
        //console.log("QGETCOMPUSRS¦", compUsers);
        return compUsers;
    };
    // AllUsrs ⇒ Company Users List (Owner Panel)[Gets all For User - Filters down t Comp Levl in List]
    const qGetAllUsers = async () => {
        const token = await getTokenDyn();
        const response = await fetch("/qGetAllUsers", {
            headers: getHeader(token)
        });
        if (!response.ok) {
            throw Error("Failed to Get User Info");
        }
        let allUsers = await response.json();
        console.log("¦allUsers¦", allUsers);
        setAllUsrs(allUsers);
        return allUsers;
    };
    // groupList ⇒ Spaces List + Permissions Level [Per Comp]
    const qGetGroups = async (cmp?: string) => {
        const params = new URLSearchParams({
            compd: cmp ?? activeComp.companyid
        });
        const url = "/qGetGroups" + `?${params.toString()}`;

        const response = await fetch(url, {
            headers: getHeader()
        });
        if (!response.ok) {
            throw Error("QGETGRPS¦Failed to Groups");
        }
        let grpsresp = await response.json();

        import.meta.env.DEV === true && console.log("setGroupList¦", grpsresp);
        setGroupListRan(true);
        if (grpsresp) {
            setGroupList(grpsresp);
        }
        return grpsresp;
    };
    const getTokenAccountID = (token: any) => {
        //import.meta.env.DEV === true && console.log("LYT¦getTokenMail", localStorage.getItem("msalAuth"), token);
        return token.uniqueId || token.localAccountId || token.oid;
    };
    // qGetUSRINF ⇒ User + Default Company
    const qGetUserInfo = async () => {
        const token = await getTokenDyn();
        import.meta.env.DEV === true && console.log("C¦qGtUsrInf¦token", getTokenMail(token), token);
        if (!token) {
            return null;
        }
        let redeemResult = await redeemCode(getTokenMail(token));
        import.meta.env.DEV === true && console.log("¦redeemResult¦", redeemResult);
        setLoggedInUser({
            userId: getTokenAccountID(token),
            mail: getTokenMail(token),
            token: token.accessToken
        });

        console.log("¦getTokenAccountID¦", getTokenAccountID(token), "¦getTokenMail¦", getTokenMail(token));

        const response = await fetch("/qGetUserInfo", {
            headers: {
                ...getHeader(token),
                ...{ kgnfhsh: window.location.hash?.replace(/[^a-zA-Z0-9]/g, " ") }
            }
        });
        if (!response.ok) {
            console.log("USR_INF_ERR", response);
        }
        let userRes = await response.json();

        setUser({
            userDisplayName: userRes.userDisplayName,
            userId: userRes.userId,
            userMail: userRes.userMail,
            companyid: userRes.companyid,
            name: (token.idTokenClaims as any).name,
            tier: userRes.tier,
            subscriptionid: userRes.subscriptionid,
            status: userRes.status
        });
        import.meta.env.DEV === true &&
            console.log("stUsr¦", {
                userDisplayName: userRes.userDisplayName,
                userId: userRes.userId,
                userMail: userRes.userMail,
                companyid: userRes.companyid,
                name: (token.idTokenClaims as any).name,
                tier: userRes.tier
            });

        return userRes;
    };
    const checkForDataPoints = (event: any) => {
        if (event["choices"] && event["choices"][0]["context"]) {
            if (event["choices"][0]["context"]["data_points_integrated"]) {
                console.log("DATA_POINTS_INTEGRATED", event["choices"][0]["context"]["data_points_integrated"]);
                return true;
            }
        }
    };
    // handleAsyncRequest
    const handleAsyncRequest = async (question: string, answers: [string, ChatAppResponse][], setAnswers: Function, responseBody: ReadableStream<any>) => {
        let answer: string = "";
        let askResponse: ChatAppResponse = {} as ChatAppResponse;
        window.globalIsCancellingMidway = false;
        const updateState = (newContent: string) => {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    answer += newContent;
                    const latestResponse: ChatAppResponse = {
                        ...askResponse,
                        choices: [{ ...askResponse.choices[0], message: { content: answer, role: askResponse.choices[0].message.role } }]
                    };
                    setStreamedAnswers([...answers, [question, latestResponse]]);
                    //if (askResponse?.choices[0]?.context?.data_points?.length > 0) {
                    //    try {
                    //        debounce(() => {
                    //            preCache(askResponse.choices[0].context.data_points);
                    //        }, 1000);
                    //    } catch (e) {
                    //        import.meta.env.DEV === true && console.log("ERR¦cacheOnlyBase64Wndw", e);
                    //    }
                    //}
                    resolve(null);
                }, 10);
            });
        };
        try {
            setIsStreaming(true);
            for await (const event of readNDJSONStream(responseBody)) {
                if ((window.globalIsCancellingMidway as any) == true) {
                    console.log("¦CANCELLING_MIDWAY_01¦");
                    break;
                }
                if (event?.choices?.[0]?.context?.data_points_integrated?.citations) {
                    event["choices"][0]["message"] = event["choices"][0]["delta"];
                    askResponse = event;
                } else if (event?.choices?.[0]?.delta?.content) {
                    setIsLoading(false);
                    await updateState(event["choices"][0]["delta"]["content"]);
                } else if (event?.choices?.[0]?.context) {
                    try {
                        askResponse.choices[0].context = { ...askResponse.choices[0].context, ...event["choices"][0]["context"] };
                    } catch (e) {}
                } else if (event["error"]) {
                    if ((window.globalIsCancellingMidway as any) == true) {
                        console.log("¦cancelling_midway_01¦");
                    } else {
                        throw Error(event["error"]);
                    }
                }
            }
        } catch (e) {
            console.error("An_Error_Occurred⇒", e);
        } finally {
        }
        const fullResponse: ChatAppResponse = {
            ...askResponse,
            choices: [{ ...askResponse.choices[0], message: { content: answer, role: askResponse.choices[0].message.role } }]
        };

        setIsStreaming(false);
        console.log("FULLRESP¦", fullResponse);

        console.log("FULLMESSAGE¦", fullResponse.choices[0].message.content);

        return fullResponse;
    };
    const makeApiRequest = async (question: string) => {
        if ((selectedGroup.premiumby ?? "") == "" && selectedGroup.dailymsgs > (lTiers?.at(0)?.msgsplmt || 0)) {
            toast.warning(msgLmtHit);
            return;
        }
        if (isStreaming) {
            import.meta.env.DEV === true && console.log("CHAT REQUEST⇒ALREADY STREAMING PREVIOUS");
            return;
        }
        lastQuestionRef.current = question;

        error && setError(undefined);

        setIsLoading(true);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        //const token = await getTokenDyn();
        const dynFiltering = determineCategoryAndGroups();

        import.meta.env.DEV === true && console.log("mk_rqst", question);
        const ovrrds = {
            prompt_template: selectedGroup.custaiinst,
            exclude_category: excludeCategory.length === 0 ? undefined : excludeCategory,
            include_category: dynFiltering.category.length === 0 ? undefined : dynFiltering.category,
            top: selectedGroup.retrievecount,
            retrieval_mode: config.retrievalMode as RetrievalMode,
            semantic_ranker: config.useSemanticRanker,
            semantic_captions: config.useSemanticCaptions,
            highlights_as_source: config.useHighlightsAsSource,
            suggest_followup_questions: selectedGroup.questionsuggestions == 1 ? true : undefined,
            use_oid_security_filter: useOidSecurityFilter,
            use_groups_security_filter: useGroupsSecurityFilter,
            user_id: dynFiltering.groups,
            inscope: selectedGroup.inscope,
            temperature: selectedGroup.temperature,
            responsetokenlimit: selectedGroup.responsetokenlimit,
            disablecitationhighlighting: selectedGroup.disablecitationhighlighting,
            strictness: selectedGroup.strictness,
            companyid: activeComp.companyid,
            oid: selectedGroup.oid,
            invoicesgroup: selectedGroup.invoicesgroup,
            uid: user.userId
        };
        console.log("ovrds", ovrrds);
        try {
            const messages: ResponseMessage[] = answers.flatMap(a => [
                { content: a[0], role: "user" },
                { content: a[1].choices[0].message.content, role: "assistant" }
            ]);

            const request: ChatAppRequest = {
                messages: [...messages, { content: question, role: "user" }],
                stream: config.shouldStream,
                use_integrated_search: selectedGroup.isintegrateddata == 1 ? true : false,
                context: {
                    overrides: ovrrds
                },
                session_state: answers.length ? answers[answers.length - 1][1].choices[0].session_state : null
            };
            let response;
            if (xplrrMsgStrm.current) {
                const element = xplrrMsgStrm.current;
                element.scrollTop = element.scrollHeight;
            }

            response = await chatApi(request, loggedInUser);

            if (!response.body) {
                throw Error("No response body");
            }
            //let interaction_guid = crypto.randomUUID();
            if (config.shouldStream) {
                import.meta.env.DEV === true && console.log("hndl_rqst¦answers", answers);
                const parsedResponse: ChatAppResponse = await handleAsyncRequest(question, answers, setAnswers, response.body);
                //parsedResponse.datecode = new Date().toISOString();
                //parsedResponse.interaction_guid = interaction_guid;
                parsedResponse.pos = null;
                parsedResponse.use_integrated_search = true;

                console.log("api¦parsed_response", parsedResponse);

                /*
                qChatLogAdd(
                    parsedResponse?.datekey ||
                        parseInt(
                            new Date()
                                .toISOString()
                                .replace(/[-:.TZ]/g, "")
                                .slice(0, 14)
                        ),
                    user.userId,
                    selectedGroup.selectionId,
                    activeComp.companyid,
                    question,
                    parsedResponse?.choices[0]?.message?.content,
                    parsedResponse?.choices[0]?.context?.response_tokens,
                    parsedResponse?.choices[0]?.context?.prompt_tokens,
                    parsedResponse?.interaction_guid
                );
                */
                setAnswersFunction([...answers, [question, parsedResponse]], false);
            } else {
                const ResponseContext = await response.json();
                const joinedAnswers = ResponseContext.data_points
                    .map((a: string) => {
                        const [documentName, content] = a.split(/:(.+)/);
                        return `[${documentName}]<div>${content}</div>`;
                    })
                    .join("");

                const emptyResponseChoice: ResponseChoice = {
                    index: 0,
                    message: { content: joinedAnswers, role: "assistant" },
                    context: {
                        thoughts: "",
                        data_points: ResponseContext.data_points_captions,
                        data_points_captions: ResponseContext.data_points_captions,
                        data_points_integrated: ResponseContext.data_points_integrated,
                        followup_questions: [],
                        response_tokens: 0,
                        prompt_tokens: 0,
                        message_type: "",
                        sql_query: "",
                        chartjs_json: "",
                        sql_result: "",
                        no_info_message: {}
                    },
                    session_state: answers.length ? answers[answers.length - 1][1].choices[0].session_state : null
                };

                const emptyChatAppResponse: ChatAppResponse = {
                    choices: [emptyResponseChoice],
                    datecode: new Date().toISOString(),
                    interaction_guid: "",
                    pos: null
                };

                const parsedResponse: ChatAppResponseOrError = emptyChatAppResponse;
                if (response.status > 299 || !response.ok) {
                    throw Error(parsedResponse.error || "Unknown error");
                }
                setAnswersFunction([...answers, [question, parsedResponse as ChatAppResponse]], false);
            }
        } catch (e) {
            setError(e);
            setIsStreaming(false);
        } finally {
            setIsLoading(false);
        }
    };
    // Before chat Req Determine Overrides for Groups and Users
    const determineCategoryAndGroups = () => {
        let cat = "";
        let groups = "";
        if (selectedGroup.selectionId == "FileSelect") {
            cat = includeCategory; // Set File Filter to Selected Files
            groups = includeCategoryGroups; // Set Group Filter to Selected File's Groups
        } else {
            cat = "";
            groups = selectedGroup.selectionId;
        }
        return { category: cat, groups: groups };
    };
    const clearChat = () => {
        lastQuestionRef.current = "";
        error && setError(undefined);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        setAnswersFunction([], true);
        setStreamedAnswers([]);
        setIsLoading(false);
        setIsStreaming(false);
    };
    const getLatestModifiedFile = (flCache?: gfile[]) => {
        return flCache?.reduce((latest, current) => {
            const currentDate = new Date(current.modified);
            return currentDate > new Date(latest.modified) ? current : latest;
        });
    };
    const qGetGroupLastModifiedFileDate = async (groupid: string) => {
        const params = new URLSearchParams({
            groupid: groupid
        });
        const url = "/qGetLatestModified" + `?${params.toString()}`;

        const response = await fetch(url, {
            headers: getHeader()
        });
        if (!response.ok) {
            throw Error("QGETGRPS¦Failed to Groups");
        }
        let modresp = await response.json();
        return modresp;
    };
    const qGetSpaceFiles = async (preWipe?: boolean, pullCacheFirst?: boolean) => {
        console.log("qgtfls", selectedGroup);
        if (pullCacheFirst == true) {
            console.log("init_load_true");
            let flCache = JSON.parse(localStorage.getItem(`kgnf_chs_${selectedGroup.oid}`) ?? "[]");
            if (flCache.length > 0) {
                setFileCount(flCache?.length || 0);
                setFileList(flCache);
                setInitLoad(false);
                let latestModified = getLatestModifiedFile(flCache)?.modified;
                if (latestModified) {
                    qGetGroupLastModifiedFileDate(selectedGroup.selectionId).then(lastMod => {
                        console.log("¦last_mod¦", lastMod);
                        console.log("¦latestModified¦", latestModified);
                        if (lastMod.length > 0 && lastMod[0]?.updated && latestModified && lastMod[0]?.updated == latestModified) {
                            console.log("NO_UPDATES");
                        } else {
                            qGetSpaceFiles();
                        }
                    });
                } else {
                    qGetSpaceFiles();
                }

                qGetSuggestedQuestions(flCache);

                return flCache;
            }
        }
        if (preWipe == true) {
            setFileList([]);
        }

        const params = new URLSearchParams({
            groupid: selectedGroup.selectionId,
            isinvoicegroup: selectedGroup.invoicesgroup == 1 ? "true" : "false",
            companyid: activeComp.companyid
        });

        const url = "/qGetSpaceFiles" + `?${params.toString()}`;
        const response = await fetch(url, {
            headers: getHeader()
        });
        if (!response.ok) {
            throw Error("QGETFLS¦Failed to Get User Files");
            setInitLoad(false);
        }
        let fileListResp = await response.json();
        console.log("FLS¦setFleLst", fileListResp);
        setFileCount(fileListResp?.length || 0);
        setFileList(fileListResp);
        setInitLoad(false);
        qGetSuggestedQuestions(fileListResp);
        return fileListResp;
    };
    const qGetChatLog = async () => {
        console.log("qGetChatLog");
        const params = new URLSearchParams({
            groupid: selectedGroup.selectionId
        });
        const url = "/qGetChatLog" + `?${params.toString()}`;
        const response = await fetch(url, {
            headers: getHeader()
        });
        if (!response.ok) {
            throw Error("qGetChatLog¦Failed to Get Log");
        }
        let spaceChatLog: gChatlogItem[] = await response.json();
        console.log("qGetChatLog¦spaceChatLog", spaceChatLog);
        setSpaceChatLog(spaceChatLog);
        return spaceChatLog;
    };
    const extractPageValue = (fileCitationPath: string) => {
        const match = fileCitationPath.match(/page=(\d+)/);
        return match ? match[1] : "";
    };
    const extractFileIDValue = (fP: string) => {
        let fid = "";
        const fidSplit = fP ? fP.split("_fid=") : [];
        if (fidSplit.length > 1) {
            return fidSplit[1].trim();
        } else {
            return "";
        }
    };
    const getLegacySearchString = (pageRef: string, index: number) => {
        let searchString;
        try {
            searchString = streamedAnswers?.[index]?.[1]["choices"][0]["context"]["data_points"].find(el => el.startsWith(pageRef));
        } catch {
            let directSearchRes = answers?.[index]?.[1]["choices"][0]["context"]["data_points"] as any;
            searchString = directSearchRes.data_points.find((el: string) => el.startsWith(pageRef));
        }
        searchString = searchString?.replace(pageRef, "").substring(1)?.trim();
        return searchString;
    };
    const cleanFileIDString = (fileP: string) => {
        // A couple of types possible. EX:
        // ViomeRecommendations_F2849CD32684.pdf#page=6_fid=379
        // ViomeRecommendations_F2849CD32684.pdf_page=31_fid=379
        return fileP.replace("#", "_");
    };
    const onShowCitation = (fileP: string, integratedCitation: string | null, index: number, openSBS?: boolean) => {
        console.log("¦on_show_citation¦", openSBS);
        if (!fileP) return;

        fileP = cleanFileIDString(fileP);
        let startPage = extractPageValue(fileP);
        let fid = extractFileIDValue(fileP);
        let pageRef = fileP.replace("/content/", "");

        let srchstr;
        if (integratedCitation == null) {
            srchstr = getLegacySearchString(pageRef, index);
        } else {
            srchstr = integratedCitation;
        }

        let isOpenBool = true;
        if (config.showDocLibInChatTab == true) {
            isOpenBool = false;
        }
        console.log("onShowCitation¦startPage", startPage, "fid", fid);
        let citFl = window.globalFL.filter((file: gfile) => file.fileid == fid);
        closeSBSWhenScrenTooSmall(true);
        if (citFl.length == 0) {
            console.log("onShowCitation¦startPage", startPage, "fid", fid);
            toast.warn("File Not Found in Library");
        } else {
            setIsPDFModalOpen({
                defaultFile: fileP.replace("/content/", "").replace("#fid", "_fid").replace("#page", "_page"),
                startPage: startPage,
                isOpen: true,
                searchString: srchstr ? srchstr : "",
                contentlist: citFl[0].contentlist || [],
                pagemap: citFl[0].pagemap || {},
                fileId: fid,
                refPageNum: startPage,
                isCitation: true,
                rndo: "",
                activeGroupId: selectedGroup.selectionId,
                fileList: window.globalFL,
                fl: citFl[0]
            });
        }
    };
    const onShowCitationXplrr = (fileP: string, integratedCitation: string | null, index: number, openSBS?: boolean) => {
        setXplrrChatVisible(false);
        setIsBlurred(false);
        if (!fileP || isPDFModalOpen.isOpen === true) return;

        let startPage = extractPageValue(fileP);
        let fleid = extractFileIDValue(fileP);
        let pageRef = fileP.replace("/content/", "");

        let srchstr;
        if (integratedCitation == null) {
            srchstr = getLegacySearchString(pageRef, index);
        } else {
            srchstr = integratedCitation;
        }
        let citFl = window.globalFL.filter((file: gfile) => file.fileid == fleid);
        if (citFl.length == 0) {
            toast.warn("File Not Found in Library");
        } else {
            setIsPDFModalOpen({
                defaultFile: fileP.replace("/content/", "").replace("#fid", "_fid").replace("#page", "_page"),
                startPage: startPage,
                isOpen: false,
                searchString: srchstr ? srchstr : "",
                contentlist: citFl[0].contentlist || [],
                pagemap: citFl[0].pagemap || {},
                fileId: fleid,
                refPageNum: startPage,
                isCitation: true,
                rndo: Math.random().toString(36).substring(2, 6),
                activeGroupId: selectedGroup.selectionId,
                fileList: window.globalFL,
                fl: citFl[0]
            });
        }
    };
    const manageCitationOpen = (filePath: string, integratedCitation: string | null, index: number, el: HTMLElement | null, openSBS?: boolean) => {
        if (clickedCitation) {
            removeCitationActive();
            applyCitationActive(el?.id);
        }
        if (openSBS == true) {
            if (config.showDocLibInChatTab == false) {
                if (window.innerWidth < 768) {
                    onShowCitation(filePath, integratedCitation, index, openSBS);
                } else {
                    setConfig(prevConfig => ({
                        ...prevConfig,
                        showDocLibInChatTab: true
                    }));
                    setTimeout(() => {
                        onShowCitationXplrr(filePath, integratedCitation, index, openSBS);
                        if (clickedCitation?.current) {
                            setTimeout(() => {
                                applyCitationActive(clickedCitation.current);
                            }, 1000);
                        }
                    }, 50);
                }
            } else {
                onShowCitationXplrr(filePath, integratedCitation, index, openSBS);
            }
        } else if (openSBS == false) {
            if (config.showDocLibInChatTab == true) {
                setConfig(prevConfig => ({
                    ...prevConfig,
                    showDocLibInChatTab: false
                }));
                setTimeout(() => {
                    onShowCitation(filePath, integratedCitation, index, openSBS);
                    if (clickedCitation?.current) {
                        setTimeout(() => {
                            applyCitationActive(clickedCitation.current);
                        }, 1000);
                    }
                }, 50);
            } else {
                onShowCitation(filePath, integratedCitation, index, openSBS);
            }
        } else {
            if (config.showDocLibInChatTab == true) {
                onShowCitationXplrr(filePath, integratedCitation, index, openSBS);
            } else {
                onShowCitation(filePath, integratedCitation, index, openSBS);
            }
        }
    };
    // Callback Func to Handle Space Update for Files
    const handleSelectionChange = (newSelection: { name: string; groupIds: string; groupNames: string }[]) => {
        console.log("handleSelectionChange");
        setIncludeCategory(newSelection.map(item => item.name).join(";"));
        setIncludeCategoryGroups([...new Set(newSelection.map(item => item.groupIds))].join(";")); // Get Unique Group IDs for Selected Files
        //qGetFiles();
    };
    const pdfCacheKey = (fId: string, modifiedDateString: string) => {
        try {
            let mdfd = new Date(modifiedDateString).getTime();
            return `${fId}_${mdfd}`;
        } catch {
            return `${fId}`;
        }
    };
    const contentPath = (filename: string, fileid: string) => {
        return `${filename}_fid=${fileid}`;
    };
    const preCache = async (fls: string[]) => {
        if (fls.length < 1) return;
        for (const fl of fls) {
            const fileId = fl.split(":")[0].split("_fid=")?.[1]?.trim();

            if (window.globalPreLoadMap === undefined) {
                window.globalPreLoadMap = new Map<string, string>();
            }

            if (window.globalPDFCache === undefined) {
                window.globalPDFCache = new Map<
                    string,
                    {
                        base64string: string;
                        fileid: string;
                        name: string;
                        modified: string;
                        pageContent: Array<{ page_num: string; text: string }>;
                        pageDimensions: Array<{ page_number: string; width: number; height: number }>;
                    }
                >();
            }
            const flArr = fileList.filter((file: gfile) => file.fileid == fileId);
            if (flArr && flArr.length > 0) {
                const fl = flArr[0];
                const cacheKey = pdfCacheKey(fileId, fl.modified);
                if (window.globalPDFCache.has(cacheKey) || window.globalPreLoadMap.has(cacheKey)) {
                } else {
                    window.globalPreLoadMap.set(cacheKey, cacheKey);
                    import.meta.env.DEV === true && console.log("FLS¦Cache", fl.name);
                    const flPath = contentPath(fl.name, fl.fileid);
                    const base64 = await fetchBase64(`/content/${flPath}`);
                    window.globalBase64String = `data:application/pdf;base64,${base64}`;
                    window.globalPDFCache.set(cacheKey, {
                        base64string: `${base64}`,
                        fileid: fl.fileid,
                        name: fl.name,
                        modified: fl.modified,
                        pageContent: fl.pagecontent,
                        pageDimensions: fl.pagedimensions
                    });
                }
            }
        }
        return true;
    };
    const fetchBase64 = async (url: string) => {
        try {
            const response = await axios.get(url, {
                responseType: "blob" // Change responseType to "blob"
            });
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => (reader.result ? resolve(reader.result.toString().split(",")[1]) : ""); // Get Base64 part
                reader.onerror = reject;
                reader.readAsDataURL(response.data); // Read the blob as Data URL
            });
        } catch (error) {
            console.error("Error fetching the PDF:", error);
            return null;
        }
    };
    const checkWeAreInGeneralPlace = () => {
        return isLib == "grp" || isLib == "acc" || isLib == "adm";
    };
    const handleSpaceChange = (selectionId: string, selectionText: string, permissionlevel: number, filecount: number, description: string, aCK?: string) => {
        setAnswersFunction([], true);
        setStreamedAnswers([]);
        if (isLib == "chat") {
            //setInitLoad(true);
        }

        if (aCK) {
            navigate(`/${activeComp.companyid}/${selectionId}/chat/${aCK}`);
        } else {
            navigate(`/${activeComp.companyid}/${selectionId}/chat`);
        }
    };
    const prepSpaceAddUsers = (newSpaceUsrs: ITag[], level: number) => {
        return newSpaceUsrs.map(item => ({ userid: item.key, username: item.name, level: level, mail: (item as any).mail, isnew: false }));
    };
    //Add Space
    const addSpace = async (nGroup: gnewgroup) => {
        if (nGroup.id == "") {
            console.log("errr_no_group_id", nGroup);
            return null;
        }

        let owners = prepSpaceAddUsers(newSpaceUsrsOwner.members, 3);
        owners.push({ userid: user.userId, username: user.userDisplayName, level: 3, mail: user.userMail, isnew: false });

        const groupname = nGroup.groupname;
        const groupid = nGroup.id;
        const groupdescription = nGroup.description;
        const appid = nGroup.appid;
        const scrt = nGroup.scrt;
        const specializedSettings = nGroup.specializedSettings;
        if (!groupname) {
            closeSpacesEditPanel();
            return null;
        }

        console.log("qAddGroupZZ", specializedSettings);
        console.log("qAddGroup", {
            groupid: groupid,
            groupname: groupname,
            companyid: activeComp.companyid,
            description: groupdescription,
            sharewithcode: 0,
            custaiinst: defaultPromptTemplateAsInPythonBackend,
            custgreetingmessage: defaultGreetingsMessage,
            isintegrateddata: true,
            appid: appid,
            scrt: scrt,
            isnew: true,
            ...specializedSettings
        });

        fetch("/qAddGroup", {
            method: "POST",
            headers: getHeader(),
            body: JSON.stringify({
                groupid: groupid,
                groupname: groupname,
                companyid: activeComp.companyid,
                description: groupdescription,
                sharewithcode: 0,
                custaiinst: defaultPromptTemplateAsInPythonBackend,
                custgreetingmessage: defaultGreetingsMessage,
                isintegrateddata: true,
                appid: appid,
                scrt: scrt,
                isnew: true,
                ...specializedSettings
            })
        })
            .then(Response => {
                //qGetGroups();
            })
            .catch(error => {
                console.error("Error:", error);
            });

        fetch("/qAddMembership", {
            method: "POST",
            headers: getHeader(),
            body: JSON.stringify({
                usersArr: owners,
                groupId: groupid,
                companyid: activeComp.companyid
            })
        })
            .then(Response => {
                qGetGroups().then((grpsresp: any) => {
                    qGetGroupsMemberships();
                    setNoGroupsShared(false);
                    toast.success("New Space Created");
                    console.log("VALIDID¦", makeValidHtmlId(groupid));
                    setTimeout(() => {
                        let pageElement = document.querySelector(`#${makeValidHtmlId(groupid)}`);
                        if (pageElement) {
                            smoothScrollIntoView(pageElement, { behavior: "smooth" });
                            setNewSpacePyro(nGroup.id);
                            setTimeout(() => {
                                setNewSpacePyro("");
                            }, 5000);
                        }
                    }, 1);
                });
            })
            .catch(error => {
                console.error("Error:", error);
            });
        setNewSpaceName("");
        clearUserTagPickers();
        //qGetGroupsMemberships();
        closeSpacesPanel();
        return null;
    };
    const delSpace = async () => {
        const groupid = selectedGroup.selectionId;
        fetch("/qDelGroup", {
            method: "POST",
            headers: getHeader(),
            body: JSON.stringify({ groupid: groupid })
        }).then(Response => {
            qGetGroups();
            qGetGroupsMemberships();
            qGetSpaceFiles();
            //setSelectedGroup({ selectionId: user.userId, selectionText: "Personal Space", permissionlevel: 2 });
        });
        setNewSpaceName("");
        clearUserTagPickers();
        closeSpacesEditPanel();
        navigate(`/${activeComp.companyid}`);
    };
    const qUSp = async (n: string, v: string) => {
        try {
            let subpaths = window.location.hash.split("/").filter(Boolean);
            let groupPath = decodeURIComponent(subpaths[2]);
            let cmp = decodeURIComponent(subpaths[1]);
            fetch("/qUSp", {
                method: "POST",
                headers: getHeader(),
                body: JSON.stringify({ grpi: groupPath, n: n, v: v })
            }).then(Response => {
                window.location.href = `${window.location.href.replace("chat", "library")}`;
                window.location.reload();
            });
        } catch {
            console.log("ERR¦qUSp");
        }
    };
    const qLi = async (cat: string, det: string, usr?: string) => {
        try {
            fetch("/qLi", {
                method: "POST",
                headers: getHeader(),
                body: JSON.stringify({
                    usr: usr || user?.userMail || "no_usermail",
                    cat: cat,
                    det: det,
                    loc: window.location.hash?.replace(/[^a-zA-Z0-9]/g, " ")
                })
            });
        } catch {}
    };
    const fileSpaceChangeTrigger = (timestamp: string) => {
        qGetSpaceFiles();
    };
    const userMngmntUpdate = (timestamp: string) => {
        qGetCompanyUsers().then(() => {
            qGetGroupsMemberships().then(() => {
                qGetCompFlatList().then(() => {
                    qGetAllUsers();
                });
            });
        });
    };
    const handleCompChange = (activeComp: gcompflat) => {
        console.log("CMP¦newActiveComp", activeComp);
        navigate(`/${activeComp.companyid}`);
        closeCompListModal();
    };
    const getOFromList = () => {
        //console.log("getOFromList", selectedGroup.permissionlevel);
        return selectedGroup.permissionlevel;
    };
    const handleGroupListClick = (group: { groupid: string; groupname: string; permissionlevel: number; filecount: number }) => {
        //setSelectedGroup({ selectionId: group.groupid, selectionText: group.groupname, permissionlevel: group.permissionlevel });

        navigate(`/${activeComp.companyid}/${group.groupid}/chat`);
    };
    const handleLeftSpacePanelClick = (item: any, aCK?: string) => {
        setIsSpacePanelOpen(false);
        if (aCK) {
            setLoadingChatModeSwitch(true);
            if (config.showDocLibInChatTab == true) {
                setConfig(prevConfig => ({
                    ...prevConfig,
                    showDocLibInChatTab: false
                }));
            }
        }
        handleSpaceChange(item.groupid, item.groupname, item.permissionlevel, item.filecount, item.description, aCK);
    };
    const switchXplrrModuleVisibility = () => {
        if (xplrrChatVisible == false) {
            setXplrrChatVisible(true);
            setIsBlurred(true);
        }
    };
    //This is user click not citation change
    const xplrrCitationChangeCallback = (callBackProps: gXplrrCitationChangeCallback) => {
        import.meta.env.DEV === true && console.log("xplrrCitationChangeCallback", callBackProps);
        const fileFullPath: string = fileList.filter((file: gfile) => file.fileid == callBackProps.fileid)[0].full_path;
        const citationFullPath = fileFullPath.split("/content/").slice(-1) + "#page=" + callBackProps.destPageNum + "_fid=" + callBackProps.fileid;

        setIsPDFModalOpen(prevConfig => ({
            ...prevConfig,
            isOpen: false,
            defaultFile: citationFullPath,
            startPage: callBackProps.destPageNum,
            searchString: "",
            fileId: callBackProps.fileid,
            isCitation: callBackProps.isCitation
        }));
    };
    const toggleSearch = () => {
        let valNow = true;
        if (config.searchOnly == true) {
            valNow = false;
        }
        import.meta.env.DEV === true && console.log("toggleSearch", "");
        setConfig(prevConfig => ({
            ...prevConfig,
            searchOnly: !!valNow,
            shouldStream: !valNow, // If Search Only is Checked, Disable Streaming
            useSemanticCaptions: valNow ? valNow : !!valNow // If Search Only is Checked, Disable Semantic Captions
        }));
    };
    const toggleChatWithDocs = () => {
        if (window.innerWidth < 768) {
            toast.warning("Side-by-Side View for Chat and Documents is currently not supported on screens with less than 768px of width");
            return;
        }
        let valNow = true;
        if (config.showDocLibInChatTab == true) {
            valNow = false;
        }
        setConfig(prevConfig => ({
            ...prevConfig,
            showDocLibInChatTab: !!valNow
        }));
    };
    const onCreateClicked = () => {
        setIsSpaceModalOpen(true);
    };
    const onDelFile = () => {
        qGetSpaceFiles();
    };
    const handleIsCancellingMidway = () => {
        setIsCancellingMidway(true);
        window.globalIsCancellingMidway = true;
    };
    const integratedSearchCheck = (use_integrated_search: boolean | undefined) => {
        return use_integrated_search == true && config.searchOnly != true;
    };
    const integratedSearchCheckForStreaming = (use_integrated_search: boolean | undefined) => {
        return selectedGroup.isintegrateddata == 1;
    };
    const onEditSpaceSave = () => {
        qGetGroups().then((grpsresp: any) => {
            const gr = grpsresp.filter((group: any) => group.groupid === selectedGroup.selectionId)[0];

            setSelectedGroup(groupListToSelectedGroup(gr));
            toast.success("Space Configuration Saved");
        });
    };
    const onGroupDel = () => {
        toast.info("Space Deletion in Progress..");
        navigate(`/${activeComp.companyid}`);
    };
    const handlePivotItemClick = (item: any) => {
        console.log("PVT", item);
        if (item.props.itemKey == "back") {
            setChatTab();
            return;
        }
    };
    const handleAdmPivotItemClick = (item: any) => {
        console.log("PVT", item);
        if (item.props.itemKey == "back") {
            setGrpTab();
            return;
        }
    };
    const toggleCallout = () => {
        setIsShareCalloutVisible(!isShareCalloutVisible);
    };
    const inviteUser = async (usr: string) => {
        fetch("/qAddMember", {
            method: "POST",
            headers: getHeader(),
            body: JSON.stringify({
                usermail: usr,
                level: 1,
                groupid: selectedGroup.selectionId,
                companyid: activeComp.companyid
            })
        }).then(Response => {
            userMngmntUpdate(new Date().toISOString());
            toggleCallout();
            toast.success("User Added to Space");
        });
    };
    const onNewGroupClick = () => {
        setIsSpaceModalOpen(true);
        setIsNewSpaceInfoBip(false);
    };
    const isElementVisible = (eid: string) => {
        const element = document.getElementById(eid);
        if (!element) return false;
        return true;
    };
    const makeValidHtmlId = (str: string) => {
        // Remove any character that isn't a letter, number, hyphen, underscore, colon, or period
        let validStr = str.replace(/[^a-zA-Z0-9-_:.]/g, "");

        // Ensure it starts with a letter
        if (!/^[a-zA-Z]/.test(validStr)) {
            validStr = "id-" + validStr;
        }

        // Ensure it's not empty
        if (validStr.length === 0) {
            validStr = "id";
        }

        return validStr;
    };
    const histItemHandleEdit = (key: string, newText: string) => {
        const toastId = toast("Changing Conversation Name...", { autoClose: false });
        fetch("/qHistEditName", {
            method: "POST",
            headers: getHeader(),
            body: JSON.stringify({
                key: key,
                userid: user.userId,
                name: newText
            })
        }).then(Response => {
            toast.update(toastId, { render: "Conversation Name Changed", autoClose: 1000 });
        });
    };
    const histItemHandleDelete = (key: string) => {
        const toastId = toast("Deleting Conversation...", { autoClose: false });
        fetch("/qHistDelete", {
            method: "POST",
            headers: getHeader(),
            body: JSON.stringify({
                key: key,
                userid: user.userId
            })
        }).then(Response => {
            qHistGetList(user.userId, selectedGroup.selectionId, activeComp.companyid, 5);
            toast.update(toastId, { render: "Conversation Deleted", autoClose: 1000 });
        });
    };
    const onXplrrPDFDismiss = () => {
        window.globalBase64String = "";
        setShouldPlayAudio(Math.random().toString(36).substring(2, 6));
        setIsPDFModalOpen(prevConfig => ({
            ...prevConfig,
            isOpen: false,
            rndo: ""
        }));
    };
    const removeCitationActive = () => {
        let prevEl = document.getElementById(clickedCitation.current || "placeholder_id");
        prevEl?.classList.remove("activeCitFlash");
    };
    const applyCitationActive = (elId: string | undefined) => {
        let obtainCitationElement = document.getElementById(elId || "placeholder_id");
        obtainCitationElement?.classList.add("activeCitFlash");
        clickedCitation.current = obtainCitationElement?.id;
    };
    const onDataHighlight = (searchString: any) => {
        setIsPDFModalOpen(prevConfig => ({
            ...prevConfig,
            searchString: searchString,
            rndo: Math.random().toString(36).substring(2, 6)
        }));
    };
    const onSendFeedback = (prps: gfeedback) => {
        fetch("/qFeedback", {
            method: "POST",
            headers: getHeader(),
            body: JSON.stringify(prps)
        }).then(Response => {
            toast.success("Feedback Sent");
        });
        setAnswers(prevAnswers =>
            prevAnswers.map(([user, response]) =>
                response.interaction_guid === prps.interaction_guid ? [user, { ...response, pos: prps.pos }] : [user, response]
            )
        );
        let aa = answers.map(([user, response]) =>
            response.interaction_guid === prps.interaction_guid ? [user, { ...response, pos: prps.pos }] : [user, response]
        );
        console.log("aaaa", aa);
        setTimeout(() => {
            console.log("P_A_P", prps, answers);
            qHistAdd(parseInt(activeConvoKey), user.userId, selectedGroup.selectionId, activeComp.companyid, answers[0][0], aa);
        }, 2000);
    };
    return (
        <ThemeProvider theme={customTheme}>
            <div className={styles.container} id={"rro"}>
                <ToastContainer position="bottom-left" pauseOnHover transition={Bounce} style={{ zIndex: 99999999 }} />
                {/* HEADER */}
                {isIframe != true ? (
                    <div className={styles.headerMain}>
                        <div className={styles.headerSubMain}>
                            <div className={styles.commandsContainer}>
                                <div className={styles.gridDots} onClick={toggleSpacePanel}>
                                    <HiOutlineViewList size={25} />
                                </div>

                                {/* BANNER */}
                                <h2 className={styles.leftLogo} onClick={setGrpTab}>
                                    {activeComp.companyid == "AB" ? (
                                        <img src={kognifyBanner} height="38px" className={`${styles.logoImg} ${config.isDark ? styles.logoDark : ""}`}></img>
                                    ) : activeComp.companyid != "AB" && isLib != "grp" ? (
                                        <div className={styles.homeIconGridDots}>
                                            <TbGridDots />
                                        </div>
                                    ) : null}
                                </h2>
                                {/* LEFT HEADER BUTTONS */}
                                {checkWeAreInGeneralPlace() == false ? (
                                    <div>
                                        <div className={activeComp.companyid == "AB" ? styles.vertLineHeader : styles.vertLineHeaderWhitelabel} />
                                        <div className={activeComp.companyid == "AB" ? styles.headSpaceContainer : styles.headSpaceContainerWhiteLabel}>
                                            <div
                                                className={`${config.showDocLibInChatTab == false ? styles.headSpaceName : styles.headSpaceNameSBS}`}
                                                onClick={setChatTab}
                                            >
                                                <span>{selectedGroup.selectionText} </span>
                                            </div>

                                            <div className={styles.headSpaceButts}>
                                                <SettingsButton
                                                    className={`${styles.settingsButton} ${isLib == "chat" ? styles.settingsButtonActive : ""}`}
                                                    onClick={setChatTab}
                                                    buttonText="Chat"
                                                    iconId="PiChatTeardropDotsBold"
                                                />
                                                <SettingsButton
                                                    className={`${styles.settingsButton} ${isLib == "lib" ? styles.settingsButtonActive : ""}`}
                                                    onClick={setLibTab}
                                                    buttonText={activeComp.permissionlevel > 1 ? "Manage" : "Content"}
                                                    iconId="TbSettings2"
                                                />
                                                {/*SHARE BUTTON*/}
                                                {selectedGroup.permissionlevel >= 2 ? (
                                                    <div className={styles.headSpaceButsShare} ref={addUserButtonRef} onClick={toggleCallout}>
                                                        <IoShareSocialOutline></IoShareSocialOutline>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className={styles.headMenu}></div>
                                    </div>
                                ) : null}
                                {isAddSpaceMenuVisible && (
                                    <ContextualMenu
                                        items={addSpaceMenuItems}
                                        target={document.getElementById("addSpcBttnID")}
                                        onDismiss={() => setIsAddSpaceMenuVisible(false)}
                                    />
                                )}
                                {/* SPLIT CHAT-PDF */}
                                <div className={styles.commandsC} id="addSpcBttnID">
                                    {checkWeAreInGeneralPlace() == false &&
                                        isElementVisible("#dLM") == false &&
                                        (config.showDocLibInChatTab == true) == true && (
                                            <TooltipHost content="Close Side-by-Side View" directionalHint={DirectionalHint.topCenter}>
                                                <IconButton onClick={toggleChatWithDocs} className={styles.SBSIconWrap}>
                                                    <div className={styles.closeSBSPanelIcon}>
                                                        <LuPanelRightClose size={22}></LuPanelRightClose>
                                                    </div>
                                                </IconButton>
                                            </TooltipHost>
                                        )}
                                </div>
                                {/* USER ACCOUNT */}
                                <div id="userPhoto" onClick={() => setIsAccountMenuVisible(true)} className={styles.iconCircle}>
                                    <div className={`${styles.iconCircleIcon} ${isAccountMenuVisible == true ? styles.iconCircleIconActive : ""}`}>
                                        <FiUser size={16} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
                {isAccountMenuVisible && (
                    <ContextualMenu items={accMenuItems} target={document.getElementById("userPhoto")} onDismiss={() => setIsAccountMenuVisible(false)} />
                )}

                <div className={styles.chatRoot}>
                    {isIframe == true && <div className={styles.blurBack}></div>}
                    <div className={styles.chatContainer}>
                        {isIframe == true && (
                            <div className={styles.iframeAccountOptions}>
                                {activeComp.companyid == "AB" ? (
                                    <img
                                        onClick={() => {
                                            window.open(window.location.href.replace("ifrm=true", "").replace("&A=1", ""), "_blank", "noopener,noreferrer");
                                        }}
                                        src={kognifyLogo}
                                        height="30px"
                                        className={`${styles.logoImgIframe} ${config.isDark ? styles.logoDarkIframe : ""}`}
                                    ></img>
                                ) : activeComp.companyid != "AB" && isLib != "grp" ? (
                                    <div className={`${styles.linkImgIframe} ${config.isDark ? styles.linkDarkIframe : ""}`}>
                                        <img src={kognifyLogo} height="30px" />
                                    </div>
                                ) : null}

                                <div id="userPhoto" onClick={() => setIsAccountMenuVisible(true)} className={styles.iconCircleIframe}>
                                    <div className={`${styles.iconCircleIcon} ${isAccountMenuVisible == true ? styles.iconCircleIconActive : ""}`}>
                                        <FiUser size={16} />
                                    </div>
                                </div>
                            </div>
                        )}

                        {isLib === "lib" && initLoad == false ? (
                            <div className={styles.chatEmptyState}>
                                <Pivot
                                    onLinkClick={handlePivotItemClick}
                                    styles={{
                                        root: {
                                            width: "100%",
                                            minWidth: "100%"
                                        },
                                        itemContainer: {
                                            width: "100%"
                                        },
                                        link: {
                                            selectors: {
                                                "&::before": {},
                                                "&:hover::before": {
                                                    backgroundColor: "orange" // Change this to your desired hover color
                                                }
                                            }
                                        },
                                        linkIsSelected: {
                                            selectors: {
                                                "&::before": {
                                                    backgroundColor: "orange" // Change this to your desired selected color
                                                }
                                            }
                                        }
                                    }}
                                    defaultSelectedKey="Sources"
                                >
                                    <PivotItem
                                        headerText=""
                                        itemKey="back"
                                        onRenderItemLink={() => (
                                            <div className={styles.backPivotItemIconCont}>
                                                <MdArrowBackIos size={20} />
                                            </div>
                                        )}
                                    />

                                    <PivotItem headerText="Sources" itemKey="Sources">
                                        <div className={styles.detailsListWrapper}>
                                            {/*LIBRARY*/}
                                            <DetailsListDocumentsExample
                                                fileList={fileCount > 0 ? fileList : undefined}
                                                selectedGroup={selectedGroup}
                                                selectionPass={includeCategory}
                                                onSelectionChange={handleSelectionChange}
                                                groupList={groupList.map(
                                                    (item: { groupid: string; groupname: string; userid: string; permissionlevel: number }) => ({
                                                        key: item.groupid,
                                                        text: item.groupname,
                                                        memberlevel: item.permissionlevel
                                                    })
                                                )}
                                                updateCatsState={updateCatsState}
                                                fileSpaceChangeTrigger={fileSpaceChangeTrigger}
                                                getOFromListVal={getOFromList()}
                                                openSpacesEditPanel={openSpacesEditPanel}
                                                openSpacesAnalyticsPanel={openSpacesAnalyticsPanel}
                                                user={user}
                                                loggedInUser={loggedInUser}
                                                onDelFile={onDelFile}
                                                onCitationClicked={(filePath: string, integratedCitation: string | null) =>
                                                    onShowCitation(filePath, integratedCitation, 0)
                                                }
                                                companyId={activeComp.companyid}
                                                listRefreshRequest={listRefreshRequest}
                                            />
                                        </div>
                                    </PivotItem>
                                    {selectedGroup.permissionlevel > 2 ? (
                                        <PivotItem headerText="Security">
                                            <div className={styles.settingsPivot}>
                                                <SpaceUserListV2
                                                    users={compMbrshps}
                                                    activeComp={activeComp.companyid}
                                                    spaceSecurityUpdate={userMngmntUpdate}
                                                    companies={compFlatList}
                                                />
                                            </div>
                                        </PivotItem>
                                    ) : null}
                                    {selectedGroup.permissionlevel > 2 ? (
                                        // SETTINGS
                                        <PivotItem headerText="Settings">
                                            <div className={styles.setPivWrap}>
                                                <div className={styles.settingsPivot}>
                                                    <SpaceEditForm
                                                        selectedGroup={selectedGroup}
                                                        onEditSpaceSave={onEditSpaceSave}
                                                        defaultPromptTemplateAsInPythonBackend={defaultPromptTemplateAsInPythonBackend}
                                                        defaultGreetingsMessage={defaultGreetingsMessage}
                                                        onGroupDel={onGroupDel}
                                                    />
                                                </div>
                                            </div>
                                        </PivotItem>
                                    ) : null}

                                    {selectedGroup.permissionlevel > 2 && selectedGroup.invoicesgroup ? (
                                        <PivotItem headerText="Extraction Settings">
                                            <div className={styles.setPivWrap}>
                                                <div className={styles.settingsPivot}>
                                                    <FieldSelection
                                                        selectedGroup={selectedGroup}
                                                        loggedInUser={loggedInUser}
                                                        extractionfieldlist={
                                                            selectedGroup?.extractionfieldlist ? JSON.parse(selectedGroup?.extractionfieldlist) : {}
                                                        }
                                                        onEditSpaceSave={onEditSpaceSave}
                                                    />
                                                </div>
                                            </div>
                                        </PivotItem>
                                    ) : null}

                                    {selectedGroup.permissionlevel > 2 ? (
                                        <PivotItem headerText="Analytics">
                                            <div className={styles.anltxc}>
                                                <AnalyticsConvoList keyA={selectedGroup.selectionId} />
                                            </div>
                                        </PivotItem>
                                    ) : null}
                                </Pivot>
                            </div>
                        ) : isLib === "chat" && config.showDocLibInChatTab == false && initLoad == false ? (
                            <div className={styles.ChatOnlyWrap}>
                                <div className={styles.chatFullScreenUnderWrap}>
                                    <div className={styles.chatFullScreenMessageStream} ref={xplrrMsgStrm}>
                                        <div className={styles.chatMessageStreamInner}>
                                            {isStreaming &&
                                                streamedAnswers.map((streamedAnswer, index) => (
                                                    <div key={index}>
                                                        <UserChatMessage message={streamedAnswer[0]} />
                                                        <div className={styles.chatMessageGpt}>
                                                            {/*// DEFAULT CHAT*/}
                                                            <Answer
                                                                isStreaming={true}
                                                                key={index}
                                                                index={index}
                                                                answer={streamedAnswer[1]}
                                                                isSelected={false}
                                                                onCitationClicked={(
                                                                    filePath: string,
                                                                    integratedCitation: string | null,
                                                                    el: HTMLElement | null,
                                                                    openSBS?: boolean
                                                                ) => {
                                                                    manageCitationOpen(filePath, integratedCitation, index, el, openSBS);
                                                                }}
                                                                onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                                                onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                                                onFollowupQuestionClicked={q => makeApiRequest(q)}
                                                                showFollowupQuestions={config.useSuggestFollowupQuestions && answers.length - 1 === index}
                                                                use_integrated_search={integratedSearchCheckForStreaming(
                                                                    streamedAnswer[1].use_integrated_search
                                                                )}
                                                                selectedGroup={selectedGroup}
                                                                isSBS={false}
                                                                clickedCitation={clickedCitation.current || "placeholder_cit_id"}
                                                                totalAnswers={streamedAnswers?.length ?? 0}
                                                                onSendFeedback={onSendFeedback}
                                                                isLoading={isLoading}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                            {!isStreaming &&
                                                answers.map((answer, index) => (
                                                    <div key={index}>
                                                        <UserChatMessage message={answer[0]} />
                                                        {/*// DEFAULT CHAT NO STREAMING*/}
                                                        <div className={styles.chatMessageGpt}>
                                                            <Answer
                                                                isStreaming={false}
                                                                key={index}
                                                                index={index}
                                                                answer={answer[1]}
                                                                isSelected={selectedAnswer === index && activeAnalysisPanelTab !== undefined}
                                                                onCitationClicked={(
                                                                    filePath: string,
                                                                    integratedCitation: string | null,
                                                                    el: HTMLElement | null,
                                                                    openSBS?: boolean
                                                                ) => {
                                                                    manageCitationOpen(filePath, integratedCitation, index, el, openSBS);
                                                                }}
                                                                onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                                                onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                                                onFollowupQuestionClicked={q => makeApiRequest(q)}
                                                                showFollowupQuestions={config.useSuggestFollowupQuestions && answers.length - 1 === index}
                                                                use_integrated_search={integratedSearchCheck(answer[1].use_integrated_search)}
                                                                selectedGroup={selectedGroup}
                                                                isSBS={false}
                                                                clickedCitation={clickedCitation.current || "placeholder_cit_id"}
                                                                totalAnswers={streamedAnswers?.length ?? 0}
                                                                onSendFeedback={onSendFeedback}
                                                                isLoading={isLoading}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}

                                            {isLoading && (
                                                <>
                                                    <UserChatMessage message={lastQuestionRef.current} />
                                                    <div className={styles.chatMessageGptMinWidth}>
                                                        <AnswerLoading />
                                                    </div>
                                                </>
                                            )}
                                            {error ? (
                                                <>
                                                    <UserChatMessage message={lastQuestionRef.current} />
                                                    <div className={styles.chatMessageGptMinWidth}>
                                                        <AnswerError error={error.toString()} onRetry={() => makeApiRequest(lastQuestionRef.current)} />
                                                    </div>
                                                </>
                                            ) : null}
                                            {!isStreaming && !isLoading && !error && answers.length == 0 && loadingChatModeSwitch == false ? (
                                                <div className={styles.chatMessageGptMinWidthHI}>
                                                    <AnswerHi
                                                        content={
                                                            selectedGroup.custgreetingmessage
                                                                ? `${selectedGroup.custgreetingmessage} \n ${greetingMessageHtml}`
                                                                : `${defaultGreetingsMessage} \n ${greetingMessageHtml}`
                                                        }
                                                        selectedGroup={selectedGroup}
                                                    />
                                                </div>
                                            ) : loadingChatModeSwitch == true ? (
                                                <div className={styles.chatMessageGptMinWidthHI}>
                                                    <div className={styles.typingDots}>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            ) : null}
                                            <div ref={chatMessageStreamEnd} />
                                        </div>
                                    </div>

                                    <div className={styles.chatInput}>
                                        <div>
                                            <QuestionInput
                                                clearOnSend
                                                placeholder={window.innerWidth < 400 ? "Start..." : "Start a conversation..."}
                                                disabled={isLoading}
                                                onSend={question => makeApiRequest(question)}
                                                updateCatsState={updateCatsState}
                                                isCancellingMidway={handleIsCancellingMidway}
                                                isStreaming={isStreaming}
                                                permissionLevel={selectedGroup.permissionlevel}
                                                toggleLeftPanel={toggleLeftPanel}
                                                newConverson={newConverson}
                                                listRefreshRequest={listRefreshRequest}
                                                selectedGroup={selectedGroup}
                                                lTiers={lTiers || []}
                                                msgLmtHit={msgLmtHit}
                                            />
                                        </div>

                                        <div className={styles.underInput}>
                                            <div className={styles.chatInfo}>
                                                {/*
                                            <span>
                                                Talking with {fileList.length}{" "}
                                                {fileList.length == 0 ? "Documents" : fileList.length == 1 ? "Document" : "Documents"} in Space{" "}
                                                {selectedGroup.selectionText}
                                            </span>
                                        */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : isLib === "chat" && config.showDocLibInChatTab == true && initLoad == false ? (
                            <div className={styles.SBSWrap}>
                                {/*UI CHAT DOC SIDE BY SIDE*/}

                                <div className={styles.sideBySideUnderWrapSBS}>
                                    <div ref={xplrrMsgStrm} className={styles.chatMessageStreamSBS}>
                                        <div className={styles.chatMessageStreamInnerSBS}>
                                            {isStreaming &&
                                                streamedAnswers.map((streamedAnswer, index) => (
                                                    <div key={index}>
                                                        <UserChatMessage message={streamedAnswer[0]} />
                                                        <div className={styles.chatMessageGpt}>
                                                            {/* // SIDEBYSIDE CHAT */}
                                                            <Answer
                                                                isStreaming={true}
                                                                key={index}
                                                                index={index}
                                                                answer={streamedAnswer[1]}
                                                                isSelected={false}
                                                                onCitationClicked={(
                                                                    filePath: string,
                                                                    integratedCitation: string | null,
                                                                    el: HTMLElement | null,
                                                                    openSBS?: boolean
                                                                ) => {
                                                                    manageCitationOpen(filePath, integratedCitation, index, el, openSBS);
                                                                }}
                                                                onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                                                onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                                                onFollowupQuestionClicked={q => makeApiRequest(q)}
                                                                showFollowupQuestions={config.useSuggestFollowupQuestions && answers.length - 1 === index}
                                                                use_integrated_search={integratedSearchCheckForStreaming(
                                                                    streamedAnswer[1].use_integrated_search
                                                                )}
                                                                selectedGroup={selectedGroup}
                                                                isSBS={true}
                                                                clickedCitation={clickedCitation.current || "placeholder_cit_id"}
                                                                totalAnswers={streamedAnswers?.length ?? 0}
                                                                onSendFeedback={onSendFeedback}
                                                                isLoading={isLoading}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                            {!isStreaming &&
                                                answers.map((answer, index) => (
                                                    <div key={index}>
                                                        <UserChatMessage message={answer[0]} />
                                                        <div className={styles.chatMessageGpt}>
                                                            <Answer
                                                                isStreaming={false}
                                                                key={index}
                                                                index={index}
                                                                answer={answer[1]}
                                                                isSelected={selectedAnswer === index && activeAnalysisPanelTab !== undefined}
                                                                onCitationClicked={(
                                                                    filePath: string,
                                                                    integratedCitation: string | null,
                                                                    el: HTMLElement | null,
                                                                    openSBS?: boolean
                                                                ) => {
                                                                    manageCitationOpen(filePath, integratedCitation, index, el, openSBS);
                                                                }}
                                                                onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                                                onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                                                onFollowupQuestionClicked={q => makeApiRequest(q)}
                                                                showFollowupQuestions={config.useSuggestFollowupQuestions && answers.length - 1 === index}
                                                                use_integrated_search={integratedSearchCheck(answer[1].use_integrated_search)}
                                                                selectedGroup={selectedGroup}
                                                                isSBS={true}
                                                                clickedCitation={clickedCitation.current || "placeholder_cit_id"}
                                                                totalAnswers={streamedAnswers?.length ?? 0}
                                                                onSendFeedback={onSendFeedback}
                                                                isLoading={isLoading}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                            {isLoading && (
                                                <>
                                                    <UserChatMessage message={lastQuestionRef.current} />
                                                    <div className={styles.chatMessageGptMinWidth}>
                                                        <AnswerLoading />
                                                    </div>
                                                </>
                                            )}
                                            {error ? (
                                                <>
                                                    <UserChatMessage message={lastQuestionRef.current} />
                                                    <div className={styles.chatMessageGptMinWidth}>
                                                        <AnswerError error={error.toString()} onRetry={() => makeApiRequest(lastQuestionRef.current)} />
                                                    </div>
                                                </>
                                            ) : null}

                                            {!isStreaming && !isLoading && !error && answers.length == 0 && loadingChatModeSwitch == false ? (
                                                <>
                                                    <div className={styles.chatMessageGptMinWidthHI}>
                                                        <AnswerHi
                                                            content={
                                                                selectedGroup.custgreetingmessage
                                                                    ? `${selectedGroup.custgreetingmessage} \n ${greetingMessageHtml}`
                                                                    : `${defaultGreetingsMessage} \n ${greetingMessageHtml}`
                                                            }
                                                            selectedGroup={selectedGroup}
                                                        />
                                                    </div>
                                                </>
                                            ) : loadingChatModeSwitch == true ? (
                                                <div className={styles.chatMessageGptMinWidthHI}>
                                                    <div className={styles.typingDots}>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            ) : null}

                                            <div ref={chatMessageStreamEnd} />
                                        </div>
                                    </div>
                                    <div className={styles.chatInputSBS}>
                                        <div>
                                            <QuestionInput
                                                clearOnSend
                                                placeholder={window.innerWidth < 400 ? "Start..." : "Start a conversation..."}
                                                disabled={isLoading}
                                                onSend={question => makeApiRequest(question)}
                                                updateCatsState={updateCatsState}
                                                isCancellingMidway={handleIsCancellingMidway}
                                                isStreaming={isStreaming}
                                                permissionLevel={selectedGroup.permissionlevel}
                                                toggleLeftPanel={toggleLeftPanel}
                                                newConverson={newConverson}
                                                listRefreshRequest={listRefreshRequest}
                                                selectedGroup={selectedGroup}
                                                lTiers={lTiers || []}
                                                msgLmtHit={msgLmtHit}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.xplrrPDFZ_SBS}>
                                    <XplrrPDFSideByChat
                                        startPage={isPDFModalOpen.startPage}
                                        citationFileId={isPDFModalOpen.defaultFile}
                                        xplrrCitationChangeCallback={xplrrCitationChangeCallback}
                                        fileId={isPDFModalOpen.fileId}
                                        refPageNum={isPDFModalOpen.refPageNum}
                                        isCitation={isPDFModalOpen.isCitation}
                                        rndo={isPDFModalOpen.rndo}
                                        searchString={isPDFModalOpen.searchString}
                                        activeGroupState={isPDFModalOpen.activeGroupId}
                                        fileList={fileList}
                                        shouldPlayAudio={shouldPlayAudio}
                                    />
                                </div>
                            </div>
                        ) : isLib === "grp" ? (
                            <div className={styles.grpScrn}>
                                <div className={styles.spaceCards}>
                                    {noGroupsShared == true ? (
                                        <div>
                                            <div className={styles.spaceSplashGreetings}>
                                                <div className={styles.hi}>Hi{user.name ? `, ${user.name}` : ""}!</div>
                                                <div className={styles.hiAskAnything}>It seems there are no Assistants shared with you.</div>
                                            </div>
                                            <div>
                                                <NoSpacesCard onCreateClicked={onCreateClicked} showCreateSpacePrompt={true} />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className={styles.groupCardsListOutsideWrap}>
                                            <div className={styles.spaceSplashGreetings}>
                                                <img
                                                    src={activeComp.companyid == "AB" ? kgnfLogoOnly : blankLogoOnly}
                                                    className={styles.kgnfLogoCenter}
                                                    height="58px"
                                                ></img>
                                                {activeComp.companyid == "SE" ? (
                                                    <div className={styles.hiAskAnything}>Your SolarEdge Assistants</div>
                                                ) : (
                                                    <div className={styles.hiAskAnything}>Your Assistants</div>
                                                )}
                                            </div>
                                            <GroupCardsList
                                                key={selectedGroup.oid}
                                                grL={
                                                    groupList && groupList.length > 0
                                                        ? groupList.map(group => ({
                                                              selectedGroup: groupListToSelectedGroup(group)({} as gselectedgroup),
                                                              handleGroupListClick: (groupid: string, groupname: string, permissionlevel: number) => {
                                                                  handleGroupListClick({
                                                                      groupid: groupid,
                                                                      groupname: groupname,
                                                                      permissionlevel: permissionlevel,
                                                                      filecount: group.filecount
                                                                  });
                                                              }
                                                          }))
                                                        : []
                                                }
                                                onNewGroupClick={onNewGroupClick}
                                                compLvl={activeComp.permissionlevel}
                                                permissionLevelsMap={permissionLevels}
                                                newSpacePyro={newSpacePyro}
                                                user={user}
                                                activeComp={activeComp}
                                                navChatLib={navChatLib}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : isLib === "acc" ? (
                            <div>
                                <div className={styles.accountInfoWrap}>
                                    <AccountInfo
                                        key={groupList?.length}
                                        groups={groupList}
                                        user={user}
                                        lTiers={lTiers || []}
                                        activeComp={activeComp}
                                        selectedGroup={selectedGroup}
                                    />
                                </div>
                            </div>
                        ) : isLib === "adm" ? (
                            /* Administrative Menu */
                            <div>
                                <Pivot
                                    onLinkClick={handleAdmPivotItemClick}
                                    className={styles.spaceSecurityPivot}
                                    styles={{
                                        root: {
                                            width: "100%",
                                            minWidth: "100%"
                                        },
                                        itemContainer: {
                                            width: "100%"
                                        }
                                    }}
                                    defaultSelectedKey="usersAdm"
                                >
                                    <PivotItem
                                        headerText=""
                                        itemKey="back"
                                        onRenderItemLink={() => (
                                            <div className={styles.backPivotItemIconCont}>
                                                <MdArrowBackIos size={20} />
                                            </div>
                                        )}
                                    />
                                    <PivotItem headerText="Users" itemKey="usersAdm" className={styles.settingsPivot}>
                                        <UserList
                                            key={allUsrs.length}
                                            users={allUsrs}
                                            activeComp={activeComp}
                                            userMngmntUpdate={userMngmntUpdate}
                                            companies={compFlatList}
                                            loggedInUser={loggedInUser}
                                        />
                                    </PivotItem>
                                    <PivotItem headerText="Companies" itemKey="compAdm">
                                        <div className={styles.settingsPivot}>
                                            <CompList
                                                comps={compFlatList}
                                                newActiveComp={handleCompChange}
                                                activeComp={activeComp}
                                                loggedInUser={loggedInUser}
                                            />
                                        </div>
                                    </PivotItem>
                                    <PivotItem headerText="Company Administration" itemKey="compSett">
                                        <div>
                                            <h3 className={styles.modalTitle}>{activeComp.companyname}</h3>
                                            <CompEditForm activeComp={activeComp} selectedGroup={selectedGroup} />
                                        </div>
                                    </PivotItem>
                                </Pivot>
                            </div>
                        ) : noMembership == true ? (
                            <div>
                                <NoSpacesCard onCreateClicked={onCreateClicked} showCreateSpacePrompt={false} />
                            </div>
                        ) : (
                            <div className={styles.loadingIndicatorCenterScreen}>
                                <div className={styles.typingDots}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        )}
                    </div>
                    {answers.length > 0 && activeAnalysisPanelTab && isLib === "chat" && (
                        <AnalysisPanel
                            timestamp={aPanelTimeStamp}
                            className={styles.chatAnalysisPanel}
                            activeCitation={activeCitation}
                            onActiveTabChanged={x => onToggleTab(x, selectedAnswer)}
                            citationHeight="810px"
                            answer={answers?.[selectedAnswer]?.[1]}
                            activeTab={activeAnalysisPanelTab}
                        />
                    )}
                    {/* USER SETTINGS FROM ACCOUNT MENU */}
                    <Panel
                        headerText="Settings"
                        isOpen={isConfigPanelOpen}
                        isLightDismiss={true}
                        isBlocking={false}
                        onDismiss={() => setIsConfigPanelOpen(false)}
                        closeButtonAriaLabel="Close"
                        onRenderFooterContent={() => <DefaultButton onClick={() => setIsConfigPanelOpen(false)}>Close</DefaultButton>}
                        isFooterAtBottom={true}
                    >
                        <Checkbox
                            className={styles.chatSettingsSeparator}
                            checked={config.experimentalFeatures}
                            label="Beta Features"
                            onChange={(_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                setConfig(prevConfig => ({
                                    ...prevConfig,
                                    experimentalFeatures: !!checked
                                }));
                            }}
                        />
                        <div className={styles.betaFeaturesDetails}>Show New Features</div>

                        <Checkbox
                            className={styles.chatSettingsSeparator}
                            checked={config.isDark}
                            label="Dark Mode"
                            onChange={(_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                handleIsDark(!!checked);
                                setConfig(prevConfig => ({
                                    ...prevConfig,
                                    isDark: !!checked
                                }));
                            }}
                        />
                        <div className={styles.betaFeaturesDetails}>Apply Dark Theme</div>
                        <div className={styles.versionLabel}>
                            <div>{import.meta.env.VITE_V_TIMESTAMP}</div>
                        </div>
                    </Panel>
                </div>

                {/*HISTORY LEFT PANEL*/}
                <Panel
                    isOpen={isLeftPanelOpen}
                    onDismiss={toggleLeftPanel}
                    isLightDismiss={true}
                    closeButtonAriaLabel="Close"
                    headerText="Conversations"
                    type={PanelType.customNear}
                    customWidth="400px"
                >
                    <div className={styles.convoListBox}>
                        {convos.map(item => (
                            <ListItemButton
                                key={item.key}
                                onClick={() => {
                                    if (config.showDocLibInChatTab == true) {
                                        setConfig(prevConfig => ({
                                            ...prevConfig,
                                            showDocLibInChatTab: false
                                        }));
                                    }
                                    setConversation(item.key);
                                }}
                                onDelete={() => histItemHandleDelete(item.key)}
                                onEdit={newText => histItemHandleEdit(item.key, newText)}
                                text={item.name}
                                className={`${styles.conversationsList} ${activeConvoKey === item.key ? styles.listItemBold : ""}`}
                                disabled={isLoading || isStreaming}
                            />
                        ))}
                    </div>
                </Panel>

                {/*SPACES LEFT PANEL*/}
                <SpacePanel
                    isSpacePanelOpen={isSpacePanelOpen}
                    toggleSpacePanel={toggleSpacePanel}
                    groupList={groupList?.sort((a, b) => {
                        if (a.oid < b.oid) {
                            return 1;
                        }
                        if (a.oid > b.oid) {
                            return -1;
                        }
                        return 0;
                    })}
                    selectedGroup={selectedGroup}
                    setGrpTab={setGrpTab}
                    handleLeftSpacePanelClick={(item: any, aCK?: string) => {
                        handleLeftSpacePanelClick(item, aCK);
                    }}
                    recentConvosAccrossSpaces={recentConvosAccrosSpaces || []}
                    activeConvoKey={activeConvoKey}
                />

                {/* GLOBAL User List Modal*/}
                <Modal isOpen={isUserListModalOpen} onDismiss={closeUserListModal} isBlocking={false} containerClassName={styles.glUserLostModal}>
                    <h3 className={styles.modalTitle}>Global Users List</h3>
                    <UserList
                        users={allUsrs}
                        activeComp={activeComp}
                        userMngmntUpdate={userMngmntUpdate}
                        companies={compFlatList}
                        loggedInUser={loggedInUser}
                    />{" "}
                </Modal>

                {/* Companies Dropdown*/}
                <Modal isOpen={isCompListModalOpen} onDismiss={closeCompListModal} isBlocking={false} containerClassName={styles.spaceAddModal}>
                    <CompList comps={compFlatList} newActiveComp={handleCompChange} activeComp={activeComp} loggedInUser={loggedInUser} />
                </Modal>

                {/* ADD NEW SPACE MODAL*/}
                <AddSpaceModal
                    isSpaceModalOpen={isSpaceModalOpen}
                    closeSpacesPanel={closeSpacesPanel}
                    isNewSpaceInfoBip={isNewSpaceInfoBip}
                    user={user}
                    addSpace={addSpace}
                />
                {/*SPACE ANALYTICS*/}
                <Modal
                    isOpen={isSpaceAnalyticsModalOpen}
                    onDismiss={closeSpacesAnalyticsPanel}
                    isBlocking={false}
                    containerClassName={styles.spaceAnalyticsModal}
                >
                    <h3 className={styles.modalTitle}>Space Analytics</h3> {/*ANALYTICS Space Modal*/}
                    <AnalyticsConvoList keyA={selectedGroup.selectionId} />
                </Modal>

                {/*PDF MODAL*/}
                <RModal
                    isOpen={isPDFModalOpen.isOpen}
                    onRequestClose={onXplrrPDFDismiss}
                    shouldCloseOnOverlayClick={true}
                    closeTimeoutMS={200}
                    className={{
                        base: styles.modalContent,
                        afterOpen: styles.modalContentAfterOpen,
                        beforeClose: styles.modalContentBeforeClose
                    }}
                    overlayClassName={styles.modalOverlay}
                    shouldFocusAfterRender={true}
                >
                    <XplrrPDF
                        startPage={isPDFModalOpen.startPage}
                        citationFileId={isPDFModalOpen.defaultFile}
                        xplrrCitationChangeCallback={xplrrCitationChangeCallback}
                        fileId={isPDFModalOpen.fileId}
                        refPageNum={isPDFModalOpen.refPageNum}
                        isCitation={isPDFModalOpen.isCitation}
                        rndo={isPDFModalOpen.rndo}
                        searchString={isPDFModalOpen.searchString}
                        activeGroupState={isPDFModalOpen.activeGroupId}
                        fileList={fileList}
                        shouldPlayAudio={shouldPlayAudio}
                        isIframe={isIframe}
                        onXplrrPDFDismiss={onXplrrPDFDismiss}
                        onDataHighlight={onDataHighlight}
                        isLib={isLib}
                        loggedInUser={loggedInUser}
                        onInvValueUpdate={listRefreshRequest}
                    />
                </RModal>
                {/* INVITE USER */}
                {isShareCalloutVisible && (
                    <InviteUserCallout
                        isOpen={isShareCalloutVisible}
                        onDismiss={toggleCallout}
                        addUserButtonRef={addUserButtonRef}
                        inviteUser={inviteUser}
                        selectedGroup={selectedGroup}
                        activeConvoKey={activeConvoKey}
                    />
                )}
            </div>
        </ThemeProvider>
    );
};

export default Chat;
