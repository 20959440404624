import React, { useState } from "react";
import { Checkbox, Stack, Separator, PrimaryButton, ThemeProvider } from "@fluentui/react";
import styles from "./FieldSelection.module.css";
import { stackStyles, tooltipStyles, textFieldStylesMultiline, checkBoxStyles, dropdownStyles, sliderStyles } from "../../styles_glob";
import { finFieldList, finField } from "../../lsts";
import { gselectedgroup, gloggedinuser } from "../../interfaces";
import customTheme from "./../../theme/customTheme";

import { qSaveExtractionFieldList } from "../../api/qpi";

interface FieldSelectionProps {
    selectedGroup: gselectedgroup;
    loggedInUser: gloggedinuser;
    extractionfieldlist: string[];
    onEditSpaceSave: () => void;
}

const FieldSelection: React.FC<FieldSelectionProps> = ({ selectedGroup, loggedInUser, extractionfieldlist, onEditSpaceSave }) => {
    const [selectedFields, setSelectedFields] = useState<string[]>(
        extractionfieldlist.length > 0
            ? finFieldList.filter(field => !extractionfieldlist.includes(field.id)).map(field => field.id)
            : finFieldList.filter(field => field.default).map(field => field.id)
    );
    const [isLoading, setIsLoading] = useState(false);
    const toggleFieldSelection = (fieldId: string) => {
        setSelectedFields(prev => (prev.includes(fieldId) ? prev.filter(id => id !== fieldId) : [...prev, fieldId]));
    };

    const savePreferences = () => {
        setIsLoading(true);

        let reverseFileds = finFieldList.filter(field => !selectedFields.includes(field.id)).map(field => field.id);
        qSaveExtractionFieldList(loggedInUser, selectedGroup, reverseFileds).then(() => {
            setIsLoading(false);
            onEditSpaceSave();
        });
    };

    const renderFields = (level: "main" | "item") =>
        finFieldList
            .filter(field => field.level === level)
            .map(field => (
                <div className={styles.invSetItemContainer}>
                    <Checkbox
                        className={styles.invSetItemCheckbox}
                        key={field.id}
                        label={field.name}
                        disabled={field.systemDisabled}
                        checked={selectedFields.includes(field.id)}
                        onChange={() => toggleFieldSelection(field.id)}
                    />
                    {field.description && <div className={styles.invSetItemDetails}>{field.description}</div>}
                </div>
            ));

    return (
        <div className={styles.invSetMainWrap}>
            <Stack styles={stackStyles} tokens={{ childrenGap: 10 }} style={{ width: "90vw", maxWidth: "800px" }} key="fsonefs">
                <div className={styles.invSetSectionTitle}>
                    <span>Field Selection</span>
                </div>
                <div className={styles.invSetDedcription}>Select the fields that will get extracted from the Invoice</div>

                <div className={styles.invSetHorizontalLine} />

                <div className={styles.invSetCustLabelTagInput}>
                    <span>Main Fields</span>
                </div>
                <ThemeProvider theme={customTheme}>
                    <Stack tokens={{ childrenGap: 5 }}>{renderFields("main")}</Stack>

                    <div className={styles.invSetHorizontalLine} />

                    <div className={styles.invSetCustLabelTagInput}>
                        <span>Item-Level Fields</span>
                    </div>

                    <Stack tokens={{ childrenGap: 5 }}>{renderFields("item")}</Stack>
                </ThemeProvider>
            </Stack>

            <div className={styles.invSetModalBottomButs}>
                <div></div>
                <PrimaryButton text="Save" onClick={savePreferences} disabled={isLoading} className={styles.invSetSaveSpaceButton} />
            </div>
        </div>
    );
};

export default FieldSelection;
