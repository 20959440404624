import { useState, useEffect, useRef, useContext } from "react";
import { Icon, Stack, TextField, ActionButton, IIconProps, Callout, DirectionalHint, TooltipHost, TooltipDelay } from "@fluentui/react";

import { Button, Tooltip, Field, Textarea } from "@fluentui/react-components";
import { Send28Filled, Settings28Filled, Stop24Filled, List28Regular } from "@fluentui/react-icons";

import styles from "./QuestionInput.module.css";
import { ClearChatButton } from "../../components/ClearChatButton";
import { TogglePanelButton } from "../../components/TogglePanelButton";
import ChatControls from "../../components/ChatControls/ChatControls";
import { UploadFiles } from "../../components/UploadFiles";
import { AddURLs } from "../../components/AddURLs";

import { gselectedgroup, gtiers } from "../../interfaces";

interface Props {
    onSend: (question: string) => void;
    disabled: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
    updateCatsState: (question: string) => void;
    isCancellingMidway: () => void;
    isStreaming: boolean;
    permissionLevel: number;
    toggleLeftPanel: () => void;
    newConverson: () => void;
    listRefreshRequest: () => void;
    selectedGroup: gselectedgroup;
    lTiers: gtiers[];
    msgLmtHit: string;
}

export const QuestionInput = ({
    onSend,
    disabled,
    placeholder,
    clearOnSend,
    updateCatsState,
    isCancellingMidway,
    isStreaming,
    permissionLevel,
    toggleLeftPanel,
    newConverson,
    listRefreshRequest,
    selectedGroup,
    lTiers,
    msgLmtHit
}: Props) => {
    const [question, setQuestion] = useState<string>("");
    const [chatControlsOpen, setChatControlsOpen] = useState<boolean>(false);
    const [isFileUploadDiagOpen, setIsFileUploadDiagOpen] = useState<boolean>(false);

    const textAreaRef = useRef(null);
    const uploadButRef = useRef(null);

    const adjustHeight = () => {
        const inputContainers = document.getElementsByClassName(styles.questionInputContainer);
        for (let i = 0; i < inputContainers.length; i++) {
            const inputContainer = inputContainers[i] as HTMLTextAreaElement;

            if (parseInt(inputContainer.style.height) < inputContainer.scrollHeight) {
                inputContainer.style.height = inputContainer.scrollHeight + "px";
            } else {
                inputContainer.style.height = "auto";
                inputContainer.style.height = inputContainer.scrollHeight + "px";
            }
        }
    };

    const clipIcon: IIconProps = {
        iconName: "Attach",
        styles: {
            root: {
                color: "#333333",
                transform: "rotate(-20deg)", // Tilt the icon by -20 degrees
                fontSize: "20px"
            }
        }
    };
    const actionButtonStyles = {
        iconHovered: { color: "#333333" }
    };

    useEffect(() => {
        adjustHeight();
    }, [question]);

    const sendQuestion = () => {
        if (disabled || !question.trim()) {
            return;
        }
        onSend(question);
        if (clearOnSend) {
            setQuestion("");
        }
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendQuestion();
        }
    };

    const onQuestionChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (!newValue) {
            setQuestion("");
        } else {
            setQuestion(newValue);
        }
    };

    const sendQuestionDisabled = disabled || !question.trim();
    const onFileUploaded = (newValue: string) => {
        updateCatsState(newValue);
    };

    const handleNewConverson = () => {
        setChatControlsOpen(false);
        newConverson();
    };
    const handleToggleLeftPanel = () => {
        setChatControlsOpen(false);
        toggleLeftPanel();
    };
    const handleButtonClick = () => {
        setIsFileUploadDiagOpen(true);
    };

    const callOutVisibility = (val: boolean) => {};

    return (
        <Stack
            horizontal
            className={`${styles.questionInputContainer} ${
                (selectedGroup.premiumby ?? "") == "" && selectedGroup.dailymsgs > (lTiers?.at(0)?.msgsplmt || 0) ? styles.noMsggsConditional : ""
            }`}
        >
            <Callout
                gapSpace={0}
                styles={{
                    root: {
                        height: "auto",
                        borderRadius: "5px",
                        backgroundColor: "rgba(255, 255, 255, 0)"
                    },
                    calloutMain: {
                        borderRadius: "5px"
                    },
                    beakCurtain: { borderRadius: "5px" },
                    beak: { borderRadius: "1px" }
                }}
                isBeakVisible={false}
                target={uploadButRef.current}
                onDismiss={() => setIsFileUploadDiagOpen(false)}
                setInitialFocus
                directionalHint={DirectionalHint.leftTopEdge}
                className={styles.calloutMain}
                hidden={!isFileUploadDiagOpen}
            >
                <div className={styles.rightButtons}>
                    <div className={styles.libButs} id={`calloutButton_pdf`}>
                        <TooltipHost content="Upload Documents" directionalHint={DirectionalHint.topCenter}>
                            <AddURLs
                                className={styles.commandButtonLib}
                                updateCatsState={updateCatsState}
                                butName="Documents"
                                calloutLabelText="Upload PDFs from your Device"
                                cTy="pdf"
                                listRefreshRequest={listRefreshRequest}
                                selectedGroup={selectedGroup}
                                callOutVisibility={callOutVisibility}
                                hideCallout={isFileUploadDiagOpen == false ? true : false}
                            />
                        </TooltipHost>
                    </div>
                    {selectedGroup.allowyoutube == 1 && (
                        <div className={styles.libButs} id={`calloutButton_youtube`}>
                            <TooltipHost content="Add YouTube videos" directionalHint={DirectionalHint.topCenter}>
                                <AddURLs
                                    className={styles.commandButtonLib}
                                    updateCatsState={updateCatsState}
                                    butName="YouTube"
                                    calloutLabelText="Enter YouTube URL"
                                    cTy="youtube"
                                    listRefreshRequest={listRefreshRequest}
                                    selectedGroup={selectedGroup}
                                    callOutVisibility={callOutVisibility}
                                    hideCallout={isFileUploadDiagOpen ? false : true}
                                />
                            </TooltipHost>
                        </div>
                    )}
                    {selectedGroup.allowweb == 1 && (
                        <div className={styles.libButs} id={`calloutButton_web`}>
                            <TooltipHost content="Add Web Pages" directionalHint={DirectionalHint.topCenter}>
                                <AddURLs
                                    className={styles.commandButtonLib}
                                    updateCatsState={updateCatsState}
                                    butName="Web"
                                    calloutLabelText="Enter Web URL"
                                    cTy="web"
                                    listRefreshRequest={listRefreshRequest}
                                    selectedGroup={selectedGroup}
                                    callOutVisibility={callOutVisibility}
                                    hideCallout={isFileUploadDiagOpen ? false : true}
                                />
                            </TooltipHost>
                        </div>
                    )}
                </div>
            </Callout>

            {chatControlsOpen && (
                <ChatControls
                    isOpen={chatControlsOpen}
                    onDismiss={() => setChatControlsOpen(false)}
                    ButtonRef={textAreaRef}
                    toggleLeftPanel={handleToggleLeftPanel}
                    newConverson={handleNewConverson}
                />
            )}
            <div ref={textAreaRef} className={styles.chatControlsContainer}>
                <ClearChatButton className={styles.commandButtonTwo} onClick={newConverson} />
                <TogglePanelButton
                    className={styles.commandButton}
                    onClick={() => handleToggleLeftPanel()}
                    text=""
                    iconType={List28Regular}
                    tooltipText="View your Chat History"
                />
            </div>
            <TextField
                className={styles.questionInputTextArea}
                styles={{
                    root: {
                        verticalAlign: "middle",
                        height: "0px"
                    },

                    fieldGroup: { minHeight: "0px", height: "0px" },
                    field: {
                        paddingTop: "11px",
                        paddingBottom: "11px",
                        height: "0px",
                        minHeight: "0",
                        selectors: {
                            ":disabled": {
                                backgroundColor: "#ffaf87",
                                color: "red",
                                font: "normal normal 17px/20px Urbanist,Roboto"
                            }
                        },
                        font: "normal normal 15px/20px Urbanist,Roboto;"
                    }
                }}
                autoAdjustHeight={true}
                placeholder={(selectedGroup.premiumby ?? "") == "" && selectedGroup.dailymsgs > (lTiers?.at(0)?.msgsplmt || 0) ? msgLmtHit : placeholder}
                multiline
                disabled={(selectedGroup.premiumby ?? "") == "" && selectedGroup.dailymsgs > (lTiers?.at(0)?.msgsplmt || 0) ? true : false}
                resizable={false}
                borderless
                value={question}
                onChange={onQuestionChange}
                onKeyDown={onEnterPress}
            />

            <div ref={uploadButRef} className={styles.questionInputButtonsContainer}>
                {permissionLevel > 1 && (
                    <ActionButton
                        className={styles.btn_action}
                        id="calloutButton"
                        iconProps={clipIcon}
                        styles={actionButtonStyles}
                        allowDisabledFocus
                        onClick={handleButtonClick}
                    ></ActionButton>
                )}
            </div>
            <div className={styles.questionInputButtonsContainer}>
                {isStreaming == true ? (
                    <Button
                        size="large"
                        className={styles.sendButton}
                        icon={<Stop24Filled primaryFill="rgba(255, 255, 255, 0.6)" />}
                        onClick={isCancellingMidway}
                        style={{ backgroundColor: "#F1641E", borderRadius: "5px" }}
                    />
                ) : (
                    <Button
                        size="large"
                        className={styles.sendButton}
                        icon={<Send28Filled primaryFill="rgba(255, 255, 255, 0.6)" />}
                        disabled={sendQuestionDisabled}
                        onClick={sendQuestion}
                        style={{ backgroundColor: "#F1641E", borderRadius: "5px" }}
                    />
                )}
            </div>
        </Stack>
    );
};
