import React, { useState, useEffect, useContext } from "react";
import {
    Stack,
    TextField,
    TooltipHost,
    Checkbox,
    PrimaryButton,
    ITextFieldStyles,
    IStackStyles,
    ICheckboxStyles,
    ISliderStyles,
    DirectionalHint,
    IconButton,
    Slider,
    IDropdownOption,
    Dropdown,
    IDropdownStyles,
    ITextFieldProps,
    IRenderFunction
} from "@fluentui/react";
import { toast } from "react-toastify";
import styles from "./CompEditForm.module.css";

import { gactivecomp, gselectedgroup } from "../../interfaces";
import { GlobalContext } from "../../GlobalContext"; // Tests¦Context Accross Routes (ex. Chat/Search)

interface ICompEditFormProps {
    selectedGroup: gselectedgroup;
    activeComp: gactivecomp;
}

const CompEditForm: React.FC<ICompEditFormProps> = ({ selectedGroup, activeComp }) => {
    const { loggedInUser } = useContext(GlobalContext);
    const [editedComp, setEditedComp] = useState<gactivecomp>({ ...activeComp });
    const [isLoadingAPI, setIsLoadingAPI] = useState(false);

    useEffect(() => {
        console.log("activeComp", activeComp);
        setEditedComp({ ...activeComp });
    }, [activeComp]);

    useEffect(() => {
        setIsLoadingAPI(false);
    }, []);

    const getHeader = () => {
        return {
            ...(loggedInUser?.token ? { Authorization: `Bearer ${loggedInUser.token}` } : {}),
            "X-MS-CLIENT-PRINCIPAL-NAME": loggedInUser?.mail ? loggedInUser.mail : "",
            "X-MS-CLIENT-PRINCIPAL-ID": loggedInUser?.userId ? loggedInUser.userId : "",
            "Content-Type": "application/json"
        };
    };

    const textFieldStyles: Partial<ITextFieldStyles> = {
        wrapper: {
            selectors: {
                label: {
                    fontFamily: "Urbanist"
                }
            }
        },
        root: {
            borderRadius: "5px"
        },
        fieldGroup: {
            width: "90vw",
            maxWidth: "800px",
            borderRadius: "5px",
            selectors: {
                "::after": {
                    borderRadius: "5px ",
                    border: "2px solid #404040"
                }
            }
        },
        subComponentStyles: {
            label: {
                root: {
                    fontSize: "14px",
                    fontFamily: "Urbanist"
                }
            }
        }
    };
    const textFieldStylesMultiline: Partial<ITextFieldStyles> = {
        wrapper: {
            selectors: {
                label: {
                    fontFamily: "Urbanist"
                }
            }
        },
        root: {
            borderRadius: "8px"
        },
        fieldGroup: {
            width: "90vw",
            maxWidth: "800px",
            height: 100,
            borderRadius: "8px",
            selectors: {
                "::after": {
                    borderRadius: "8px ",
                    border: "2px solid #333"
                }
            }
        },
        subComponentStyles: {
            label: {
                root: {
                    fontSize: "14px",
                    fontFamily: "Urbanist"
                }
            }
        }
    };
    const checkBoxStyles: Partial<ICheckboxStyles> = {
        text: {
            fontFamily: "Urbanist"
        },
        checkbox: {
            background: "white",
            border: "1px solid #333",
            color: "white",
            borderColor: "#333", // Border color when unchecked
            selectors: {
                ":after": {
                    content: '""',
                    borderColor: "#333",
                    background: "#333",
                    color: "#333"
                },
                ":hover": {
                    border: "3px solid #333"
                },
                ":focus": {
                    border: "3px solid #333"
                },
                ":active": {
                    border: "3px solid #333"
                }
            }
        },
        checkmark: {
            color: "white", // Color of the checkmark
            background: "#333",
            border: "3px solid #333",
            selectors: {
                ":hover": {
                    color: "white", // Hover checkmark color
                    border: "3px solid white"
                }
            }
        },
        root: {
            fontFamily: "Urbanist",
            selectors: {
                ":hover .ms-Checkbox-checkbox": {
                    background: "#7e7e7e",
                    border: "3px solid #7e7e7e"
                },
                ":hover .ms-Checkbox-checkmark": {
                    background: "#7e7e7e", // Hover checkmark color
                    border: "1px solid #7e7e7e"
                },
                ".is-checked .ms-Checkbox-checkbox": {
                    background: "#7e7e7e",
                    border: "3px solid #7e7e7e"
                }
            }
        }
    };
    const stackStyles: Partial<IStackStyles> = {
        root: { gap: 10 }
    };
    const tooltipStyles = {
        styles: {
            root: {
                borderRadius: "5px"
            },
            beak: {
                left: "20px !important",
                right: "auto !important"
            },
            beakCurtain: {
                borderRadius: "5px"
            },
            calloutMain: {
                borderRadius: "5px"
            }
        }
    };
    const sliderStyles: Partial<ISliderStyles> = {
        titleLabel: {
            fontFamily: "Urbanist"
        },
        valueLabel: {
            fontFamily: "Urbanist"
        },
        slideBox: {
            borderRadius: "5px",
            color: "#333",
            borderColor: "#333",

            selectors: {
                ":hover": {
                    color: "#333"
                },
                ":active": {
                    color: "#333"
                },
                ":hover .ms-Slider-active": {
                    background: "rgb(241, 100, 30) !important"
                },
                ":hover .ms-Slider-thumb": {
                    borderColor: "orange !important",
                    background: "white !important"
                }
            }
        },

        thumb: {
            selectors: {
                borderColor: "orange !important",
                backrgoundColor: "orange",
                color: "orange",
                ":hover": {
                    borderColor: "orange !important",
                    backrgoundColor: "orange",
                    color: "orange"
                },
                ":active": {
                    borderColor: "orange !important"
                }
            }
        },
        line: {
            selectors: {
                ":hover": {
                    background: "orange",
                    backrgoundColor: "orange",
                    color: "orange"
                },

                ":active": {
                    color: "#333",
                    borderColor: "#333",
                    background: "#333",
                    backgroundColor: "#333"
                }
            }
        },
        lineContainer: {
            selectors: {
                ":hover": {
                    selectors: {
                        ".ms-Slider-active": {
                            background: "orange",
                            backrgoundColor: "orange",
                            borderColor: "#333",
                            color: "orange"
                        },
                        ":active": {
                            color: "#333",
                            borderColor: "#333",
                            background: "#333",
                            backgroundColor: "#333"
                        }
                    }
                }
            }
        }
    };
    const dropdownStyles: Partial<IDropdownStyles> = {
        root: {
            padding: "0",
            margin: "0",
            borderRadius: "5px !important",
            color: "#333",
            borderColor: "#333",
            selectors: {
                ":focus": {
                    borderRadius: "5px !important",
                    borderColor: "#333 !important",
                    color: "#333"
                },
                ":hover": {
                    borderRadius: "5px !important",
                    borderColor: "#333 !important",
                    color: "#333"
                },
                ":active": {
                    borderRadius: "5px !important",
                    borderColor: "#333 !important",
                    color: "#333"
                },
                ":after": {
                    borderRadius: "5px !important",
                    borderColor: "#333 !important",
                    color: "#333"
                },
                ":before": {
                    borderRadius: "5px !important",
                    borderColor: "#333 !important",
                    color: "#333"
                }
            }
        },
        label: {
            fontFamily: "Urbanist",
            fontSize: "15px",
            borderRadius: "5px !important",
            borderColor: "#333",
            width: "100%",
            marginBottom: "5px"
        },
        title: {
            fontFamily: "Urbanist",
            borderRadius: "5px !important",
            borderColor: "#333",
            fontSize: "12px",
            height: "30px",
            width: "100%"
        },

        dropdown: {
            borderRadius: "5px !important",
            color: "#333",
            borderColor: "#333",
            selectors: {
                ":focus": {
                    borderRadius: "5px !important",
                    borderColor: "#333 !important",
                    color: "#333"
                },
                ":hover": {
                    borderRadius: "5px !important",
                    borderColor: "#333 !important",
                    color: "#333"
                },
                ":active": {
                    borderRadius: "5px !important",
                    borderColor: "#333 !important",
                    color: "#333"
                },
                ":after": {
                    borderRadius: "5px !important",
                    borderColor: "#333 !important",
                    color: "#333"
                },
                ":before": {
                    borderRadius: "5px !important",
                    borderColor: "#333 !important",
                    color: "#333"
                }
            }
        },
        dropdownItem: {
            fontFamily: "Urbanist",
            borderRadius: "5px !important",
            fontSize: "12px",
            height: "30px",
            color: "#333"
        },

        dropdownItemSelected: {
            fontFamily: "Urbanist",
            borderRadius: "5px !important",

            fontSize: "12px",
            height: "30px",
            color: "#333"
        },
        caretDown: {
            borderRadius: "5px !important",
            color: "#333"
        }
    };
    const handleChange = (field: keyof gactivecomp, value: string | number | boolean) => {
        setEditedComp(prev => ({ ...prev, [field]: value }));
    };

    const handleSave = () => {
        setIsLoadingAPI(true);
        console.log("editedComp", editedComp);
        fetch("/qEditComp", {
            method: "POST",
            headers: getHeader(),
            body: JSON.stringify({
                companyid: activeComp.companyid,
                licensingkind: editedComp.licensingkind
            })
        }).then(resp => {
            if (!resp.ok) {
                toast.error("Error Saving Company");
            } else {
                toast.success("Company Saved");
            }
            setIsLoadingAPI(false);
        });
    };
    const compTierOptions: IDropdownOption[] = [
        { key: "0", text: "Credit Card Licensing" },
        { key: "1", text: "Business Licensing" }
    ];
    return (
        <div className={styles.editModalWrap}>
            <Stack styles={stackStyles}>
                <div className={styles.editFormSectionTitle}>
                    <span>General Configuration</span>
                </div>

                <TooltipHost content="Licensing Tier" directionalHint={DirectionalHint.bottomLeftEdge} calloutProps={tooltipStyles}>
                    <Dropdown
                        label="Licensing Tier"
                        options={compTierOptions}
                        selectedKey={editedComp.licensingkind.toString()}
                        onChange={(_, option) => handleChange("licensingkind", Number(option?.key))}
                        styles={dropdownStyles}
                    />
                </TooltipHost>
                <div className={styles.modalBottomButs}>
                    <PrimaryButton
                        text="Save"
                        onClick={() => {
                            handleSave();
                        }}
                        disabled={isLoadingAPI}
                        className={styles.saveSpaceButton}
                    />
                </div>
            </Stack>
        </div>
    );
};

export default CompEditForm;
