import React, { useState, useContext, FormEvent, ChangeEvent, useRef, useEffect } from "react";
import { Button } from "@fluentui/react-components";
import { TiDeleteOutline } from "react-icons/ti";
import { Callout, ActionButton, PrimaryButton, Label, Text } from "@fluentui/react";
import { CgWebsite } from "react-icons/cg";
import { FiYoutube } from "react-icons/fi";
import { CgFileAdd } from "react-icons/cg";
import { IUploadResponse, addURLApi, uploadFileApi } from "../../api";
import MultiTagURLInput from "./multiTagURLInput";
import { GlobalContext } from "../../GlobalContext";
import { gselectedgroup, gingestioniteminfo } from "../../interfaces";
import styles from "./AddURLs.module.css";
import { getHeaderG } from "../../api/qpi";
interface Item {
    id: string;
    value: string;
}
interface Props {
    className?: string;
    updateCatsState: (newValue: string) => void;
    butName: string;
    calloutLabelText: string;
    cTy: string;
    listRefreshRequest: () => void;
    callOutVisibility: (isVisible: boolean) => void;
    selectedGroup: gselectedgroup;
    hideCallout?: boolean;
}
// @UI ¦ AddURLs Component
export const AddURLs: React.FC<Props> = ({
    className,
    updateCatsState,
    butName,
    calloutLabelText,
    cTy,
    listRefreshRequest,
    selectedGroup,
    callOutVisibility,
    hideCallout
}) => {
    const { user, activeComp, loggedInUser } = useContext(GlobalContext);

    const [isYoutubeCalloutVisible, setIsYoutubeCalloutVisible] = useState<boolean>(false);

    const [isYoutubeLoading, setIsYoutubeLoading] = useState<boolean>(false);
    const [textYWInput, setTextYWInput] = useState<string>("");
    const [items, setItems] = useState<Item[]>([]);

    const trgtCllrRef = useRef<any>();
    //const [trgtCllrRef, setTrgtCllrRef] = useState<string | undefined>();
    const [isPDFCalloutVisible, setIsPDFCalloutVisible] = useState<boolean>(false);
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [uploadedFile, setUploadedFile] = useState<IUploadResponse>();

    const handleUploadTypeButtonClick = () => {
        trgtCllrRef.current = document.getElementById(`calloutButton_${cTy}`);

        if (cTy == "pdf") {
            setIsPDFCalloutVisible(true);
        } else {
            setIsYoutubeCalloutVisible(true);
        }
    };
    const handleProgButtonClick = () => {
        console.log("handleProgButtonClick¦cTy", cTy);
        trgtCllrRef.current = document.getElementById(`upldCrd_${cTy}`);

        if (cTy == "pdf") {
            setIsPDFCalloutVisible(true);
        } else {
            setIsYoutubeCalloutVisible(true);
        }
    };
    // WEB/YOUTUBE
    const handleTextSubmit = async (ev: React.FormEvent) => {
        ev.preventDefault();
        setIsYoutubeLoading(true);

        console.log("handleTextSubmit¦user", user, "cTy", cTy);
        setIsYoutubeCalloutVisible(false);
        try {
            let itemInfo: gingestioniteminfo[] = createInfoListForWebURL(textYWInput ? textYWInput.split(";") : [], selectedGroup);

            const response: IUploadResponse = await addURLApi(textYWInput, selectedGroup, activeComp.companyid, user.userId, cTy, itemInfo, listRefreshRequest);
            setItems([]);
            if (textYWInput.includes("youtube.com") && cTy == "youtube") {
                updateCatsState(textYWInput);
            } else if (textYWInput.includes("https") && cTy == "web") {
                updateCatsState(textYWInput);
            } else {
                updateCatsState(`${textYWInput} ¦Unsuported URL ⇛ Only Youtube Supported for Now`);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsYoutubeLoading(false);
            setIsYoutubeCalloutVisible(!isYoutubeCalloutVisible);
        }
    };

    // PDF
    const createInfoListForWebURL = (selectedFiles: string[], selectedGroup: gselectedgroup): gingestioniteminfo[] => {
        let fileInfoArray: any[] = [];
        selectedFiles.forEach((itmName, index) => {
            let fileInfo = {
                fileSpace: selectedGroup.selectionId,
                company: activeComp.companyid,
                userId: user.userId,
                fdFlName: itmName,
                fdFlSize: "0",
                sectionoverlap: selectedGroup?.sectionoverlap?.toString(),
                maxsectionlength: selectedGroup?.maxsectionlength?.toString(),
                sentencesearchlimit: selectedGroup?.sentencesearchlimit?.toString(),
                invoicesgroup: selectedGroup?.invoicesgroup?.toString(),
                usedocintel: selectedGroup?.usedocintel?.toString()
            };
            console.log("¦UL_FL¦", fileInfo);
            fileInfoArray.push(fileInfo);
        });

        return fileInfoArray;
    };
    const createInfoListForFiles = (selectedFiles: File[], selectedGroup: gselectedgroup): gingestioniteminfo[] => {
        let fileInfoArray: any[] = [];
        selectedFiles.forEach((file, index) => {
            let fileInfo = {
                fileSpace: selectedGroup.selectionId,
                company: activeComp.companyid,
                userId: user.userId,
                fdFlName: file.name,
                fdFlSize: file.size.toString(),
                sectionoverlap: selectedGroup?.sectionoverlap?.toString(),
                maxsectionlength: selectedGroup?.maxsectionlength?.toString(),
                sentencesearchlimit: selectedGroup?.sentencesearchlimit?.toString(),
                invoicesgroup: selectedGroup?.invoicesgroup?.toString(),
                usedocintel: selectedGroup?.usedocintel?.toString()
            };
            console.log("¦UL_FL¦", fileInfo);
            fileInfoArray.push(fileInfo);
        });

        return fileInfoArray;
    };

    const handleUploadClick = async (ev: FormEvent) => {
        await handleUploadFile(ev, listRefreshRequest);
    };
    const handleUploadFile = async (ev: FormEvent, listRefreshRequest?: () => void) => {
        ev.preventDefault();
        setIsLoading(true);
        const formData = new FormData();
        const fileList = new Array();
        selectedFiles.forEach((file, index) => {
            formData.append(`files`, file);
            fileList.push(file.name);
        });
        console.log("UPL¦", selectedGroup);
        let fileInfoArray: gingestioniteminfo[] = createInfoListForFiles(selectedFiles, selectedGroup);

        formData.append("fileInfo", JSON.stringify(fileInfoArray));
        try {
            const request: FormData = formData;
            setIsPDFCalloutVisible(false);

            uploadFileApi(request, loggedInUser, listRefreshRequest);

            setUploadedFile(undefined);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
        updateCatsState(fileList.join(";"));
        setSelectedFiles([]);
    };
    const onItemsChange = (items: Item[]) => {
        setItems(items);
        setTextYWInput(items.map(item => item.value).join(";"));
    };
    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        setUploadedFile(undefined);
        const selectedFileList: File[] = [];
        if (e.target.files) {
            for (let i = 0; i < e.target.files.length; i++) {
                selectedFileList.push(e.target.files.item(i)!);
            }
        }
        setSelectedFiles(selectedFileList);
    };
    const handleRemoveFile = (fileToRemove: File) => {
        const filteredFiles = selectedFiles.filter(file => file !== fileToRemove);
        setSelectedFiles(filteredFiles);
    };

    return (
        <div className={`${styles.container} ${className ?? ""}`}>
            <div>
                <ActionButton
                    className={styles.btn_action}
                    allowDisabledFocus
                    onClick={handleUploadTypeButtonClick}
                    disabled={
                        cTy == "pdf"
                            ? false
                            : cTy == "youtube" && selectedGroup.allowyoutube == 1
                            ? false
                            : cTy == "web" && selectedGroup.allowweb == 1
                            ? false
                            : true
                    }
                >
                    <span className={styles.iconCntnr}>
                        {cTy == "youtube" ? <FiYoutube size={20} /> : cTy == "web" ? <CgWebsite size={20} /> : <CgFileAdd size={20} />}
                    </span>
                </ActionButton>
                <div id={`hidB_${cTy}`} className={styles.hiddenButTrig} onClick={handleProgButtonClick}></div>
                {isYoutubeCalloutVisible && hideCallout != true && (
                    <Callout
                        styles={{
                            root: {
                                borderRadius: "5px"
                            },
                            calloutMain: {
                                borderRadius: "5px"
                            },
                            beakCurtain: { borderRadius: "5px" },
                            beak: { borderRadius: "1px" }
                        }}
                        role="dialog"
                        gapSpace={0}
                        className={styles.callout}
                        target={trgtCllrRef.current}
                        onDismiss={() => setIsYoutubeCalloutVisible(false)}
                    >
                        <form onSubmit={handleTextSubmit}>
                            <MultiTagURLInput initialItems={items} onItemsChange={onItemsChange} cTy={cTy} />

                            <div className={styles.addButAndLoad}>
                                <PrimaryButton
                                    styles={{
                                        rootHovered: {
                                            // Styles When Hovered and NOT Disabled
                                            backgroundColor: "#f1a41e",
                                            border: "1px solid rgba(241, 100, 30, 0)",
                                            selectors: {
                                                "&:not(.is-disabled)": {
                                                    backgroundColor: "#f1a41e"
                                                }
                                            }
                                        }
                                    }}
                                    type="submit"
                                    className={styles.submit}
                                    disabled={isYoutubeLoading || items.length < 1}
                                >
                                    Add
                                </PrimaryButton>
                                {isYoutubeLoading && (
                                    <div className={styles.typingDots}>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                )}
                            </div>
                        </form>
                    </Callout>
                )}
                {isPDFCalloutVisible && hideCallout != true && (
                    <Callout
                        role="dialog"
                        gapSpace={0}
                        styles={{
                            root: {
                                borderRadius: "5px"
                            },
                            calloutMain: {
                                borderRadius: "5px"
                            },
                            beakCurtain: { borderRadius: "5px" },
                            beak: { borderRadius: "1px" }
                        }}
                        className={styles.calloutPDF}
                        target={trgtCllrRef.current}
                        onDismiss={() => setIsPDFCalloutVisible(false)}
                    >
                        <form onSubmit={handleUploadClick} encType="multipart/form-data">
                            {selectedFiles.length === 0 && (
                                <>
                                    <div className={styles.btnPDF}>
                                        <Label>Choose Files</Label>
                                        <input accept=".pdf" className={styles.chooseFilesPDF} type="file" multiple onChange={handleFileChange} />
                                    </div>
                                    <Text className={styles.info}>Please upload only pdf</Text>
                                </>
                            )}

                            {selectedFiles.length > 0 && (
                                <div className={styles.SubmitContainerPDF}>
                                    <div className={styles.submitLabelPDF}>
                                        <Label>Selected Files ({selectedFiles.length}) </Label>
                                    </div>

                                    <PrimaryButton className={styles.submitPDF} type="submit" disabled={isLoading}>
                                        Submit
                                    </PrimaryButton>
                                </div>
                            )}

                            {isLoading && (
                                <div className={styles.typingDots}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            )}
                            {uploadedFile && <div className={styles.padding8}>{uploadedFile.message}</div>}

                            {selectedFiles.map((item, index) => {
                                return (
                                    <div key={index} className={styles.listPDF}>
                                        <div className={`${styles.itemPDF} ${isPDFCalloutVisible ? styles.itemPDFVisible : ""}`}>{item.name}</div>

                                        <Button
                                            className={styles.removeFromListButtonPDF}
                                            icon={<TiDeleteOutline />}
                                            onClick={() => handleRemoveFile(item)}
                                        ></Button>
                                    </div>
                                );
                            })}
                        </form>
                    </Callout>
                )}
            </div>
        </div>
    );
};
