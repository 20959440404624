import {
    Stack,
    TextField,
    TooltipHost,
    Checkbox,
    PrimaryButton,
    ITextFieldStyles,
    IStackStyles,
    ICheckboxStyles,
    ISliderStyles,
    DirectionalHint,
    IconButton,
    Slider,
    IDropdownOption,
    Dropdown,
    IDropdownStyles,
    ITextFieldProps,
    IRenderFunction,
    IIconProps
} from "@fluentui/react";

import { createTheme } from "@fluentui/react";

export const textFieldStyles: Partial<ITextFieldStyles> = {
    wrapper: {
        selectors: {
            label: {
                fontFamily: "Urbanist"
            }
        }
    },
    root: {
        borderRadius: "5px"
    },
    fieldGroup: {
        width: "90vw",
        maxWidth: "800px",
        borderRadius: "5px",
        selectors: {
            "::after": {
                borderRadius: "5px ",
                border: "2px solid #404040"
            }
        }
    },
    subComponentStyles: {
        label: {
            root: {
                fontSize: "14px",
                fontFamily: "Urbanist"
            }
        }
    }
};
export const textFieldStylesMultiline: Partial<ITextFieldStyles> = {
    wrapper: {
        selectors: {
            label: {
                fontFamily: "Urbanist"
            }
        }
    },
    root: {
        borderRadius: "8px"
    },
    fieldGroup: {
        width: "90vw",
        maxWidth: "800px",
        height: 100,
        borderRadius: "8px",
        selectors: {
            "::after": {
                borderRadius: "8px ",
                border: "2px solid #333"
            }
        }
    },
    subComponentStyles: {
        label: {
            root: {
                fontSize: "14px",
                fontFamily: "Urbanist"
            }
        }
    }
};
export const checkBoxStyles: Partial<ICheckboxStyles> = {
    text: {
        fontFamily: "Urbanist"
    },
    checkbox: {
        background: "white",
        border: "1px solid #333",
        color: "white",
        borderColor: "#333", // Border color when unchecked
        selectors: {
            ":after": {
                content: '""',
                borderColor: "#333",
                background: "#333",
                color: "#333"
            },
            ":hover": {
                border: "3px solid #333"
            },
            ":focus": {
                border: "3px solid #333"
            },
            ":active": {
                border: "3px solid #333"
            }
        }
    },
    checkmark: {
        color: "white", // Color of the checkmark
        background: "#333",
        border: "3px solid #333",
        selectors: {
            ":hover": {
                color: "white", // Hover checkmark color
                border: "3px solid white"
            }
        }
    },
    root: {
        fontFamily: "Urbanist",
        selectors: {
            ":hover .ms-Checkbox-checkbox": {
                background: "#7e7e7e",
                border: "3px solid #7e7e7e"
            },
            ":hover .ms-Checkbox-checkmark": {
                background: "#7e7e7e", // Hover checkmark color
                border: "1px solid #7e7e7e"
            },
            ".is-checked .ms-Checkbox-checkbox": {
                background: "#7e7e7e",
                border: "3px solid #7e7e7e"
            }
        }
    }
};
export const stackStyles: Partial<IStackStyles> = {
    root: {
        gap: 10,
        border: "1px solid #9baebc",
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 0,
        paddingBottom: 20,
        marginBottom: "20px",
        borderRadius: "15px",
        width: "calc(100% + 20px)"
    }
};
export const tooltipStyles = {
    styles: {
        root: {
            borderRadius: "5px"
        },
        beak: {
            left: "20px !important",
            right: "auto !important"
        },
        beakCurtain: {
            borderRadius: "5px"
        },
        calloutMain: {
            borderRadius: "5px"
        }
    }
};
export const sliderStyles: Partial<ISliderStyles> = {
    root: { marginTop: "10px" },
    titleLabel: {
        fontFamily: "Urbanist"
    },
    valueLabel: {
        fontFamily: "Urbanist"
    },
    slideBox: {
        borderRadius: "5px",
        color: "#333",
        borderColor: "#333",

        selectors: {
            ":hover": {
                color: "#333"
            },
            ":active": {
                color: "#333"
            },
            ":hover .ms-Slider-active": {
                background: "rgb(241, 100, 30) !important"
            },
            ":hover .ms-Slider-thumb": {
                borderColor: "orange !important",
                background: "white !important"
            }
        }
    },

    thumb: {
        selectors: {
            borderColor: "orange !important",
            backrgoundColor: "orange",
            color: "orange",
            ":hover": {
                borderColor: "orange !important",
                backrgoundColor: "orange",
                color: "orange"
            },
            ":active": {
                borderColor: "orange !important"
            }
        }
    },
    line: {
        selectors: {
            ":hover": {
                background: "orange",
                backrgoundColor: "orange",
                color: "orange"
            },

            ":active": {
                color: "#333",
                borderColor: "#333",
                background: "#333",
                backgroundColor: "#333"
            }
        }
    },
    lineContainer: {
        selectors: {
            ":hover": {
                selectors: {
                    ".ms-Slider-active": {
                        background: "orange",
                        backrgoundColor: "orange",
                        borderColor: "#333",
                        color: "orange"
                    },
                    ":active": {
                        color: "#333",
                        borderColor: "#333",
                        background: "#333",
                        backgroundColor: "#333"
                    }
                }
            }
        }
    }
};
export const dropdownStyles: Partial<IDropdownStyles> = {
    root: {
        padding: "0",
        margin: "0",
        borderRadius: "5px !important",
        color: "#333",
        borderColor: "#333",
        selectors: {
            ":focus": {
                borderRadius: "5px !important",
                borderColor: "#333 !important",
                color: "#333"
            },
            ":hover": {
                borderRadius: "5px !important",
                borderColor: "#333 !important",
                color: "#333"
            },
            ":active": {
                borderRadius: "5px !important",
                borderColor: "#333 !important",
                color: "#333"
            },
            ":after": {
                borderRadius: "5px !important",
                borderColor: "#333 !important",
                color: "#333"
            },
            ":before": {
                borderRadius: "5px !important",
                borderColor: "#333 !important",
                color: "#333"
            }
        }
    },
    label: {
        fontFamily: "Urbanist",
        fontSize: "15px",
        borderRadius: "5px !important",
        borderColor: "#333",
        width: "100%",
        marginBottom: "5px"
    },
    title: {
        fontFamily: "Urbanist",
        borderRadius: "5px !important",
        borderColor: "#333",
        fontSize: "12px",
        height: "30px",
        width: "100%"
    },

    dropdown: {
        borderRadius: "5px !important",
        color: "#333",
        borderColor: "#333",
        selectors: {
            ":focus": {
                borderRadius: "5px !important",
                borderColor: "#333 !important",
                color: "#333"
            },
            ":hover": {
                borderRadius: "5px !important",
                borderColor: "#333 !important",
                color: "#333"
            },
            ":active": {
                borderRadius: "5px !important",
                borderColor: "#333 !important",
                color: "#333"
            },
            ":after": {
                borderRadius: "5px !important",
                borderColor: "#333 !important",
                color: "#333"
            },
            ":before": {
                borderRadius: "5px !important",
                borderColor: "#333 !important",
                color: "#333"
            }
        }
    },
    dropdownItem: {
        fontFamily: "Urbanist",
        borderRadius: "5px !important",
        fontSize: "12px",
        height: "30px",
        color: "#333"
    },

    dropdownItemSelected: {
        fontFamily: "Urbanist",
        borderRadius: "5px !important",

        fontSize: "12px",
        height: "30px",
        color: "#333"
    },
    caretDown: {
        borderRadius: "5px !important",
        color: "#333"
    }
};

export const dropdownDetailsListStyles: Partial<IDropdownStyles> = {
    root: {
        marginTop: "-5px",
        marginBottom: "-5px",
        marginLeft: "-5px",
        borderRadius: "5px !important",
        color: "#333",
        borderColor: "#333",
        minWidth: "90px",
        selectors: {
            ":focus": {
                borderRadius: "5px !important",
                borderColor: "#333 !important",
                color: "#333"
            },
            ":hover": {
                borderRadius: "5px !important",
                borderColor: "#333 !important",
                color: "#333"
            },
            ":active": {
                borderRadius: "5px !important",
                borderColor: "#333 !important",
                color: "#333"
            },
            ":after": {
                borderRadius: "5px !important",
                borderColor: "#333 !important",
                color: "#333"
            },
            ":before": {
                borderRadius: "5px !important",
                borderColor: "#333 !important",
                color: "#333"
            }
        }
    },
    label: {
        borderRadius: "5px !important",
        borderColor: "#333",
        width: "100%"
    },
    title: {
        font: "normal normal 13px/30px Urbanist,Roboto",
        borderRadius: "5px !important",
        paddingLeft: "6px",
        marginTop: "-4px",
        marginBottom: "-4px",
        borderColor: "#333",
        fontSize: "12px",
        height: "30px",
        width: "100%"
    },

    dropdown: {
        borderRadius: "5px !important",
        color: "#333",
        borderColor: "#333",
        selectors: {
            ":focus": {
                borderRadius: "5px !important",
                borderColor: "#333 !important",
                color: "#333"
            },
            ":hover": {
                borderRadius: "5px !important",
                borderColor: "#333 !important",
                color: "#333"
            },
            ":active": {
                borderRadius: "5px !important",
                borderColor: "#333 !important",
                color: "#333"
            },
            ":after": {
                borderRadius: "5px !important",
                borderColor: "#333 !important",
                color: "#333"
            },
            ":before": {
                borderRadius: "5px !important",
                borderColor: "#333 !important",
                color: "#333"
            }
        }
    },
    dropdownItem: {
        fontFamily: "Urbanist",
        borderRadius: "5px !important",
        fontSize: "12px",
        height: "30px",
        color: "#333"
    },

    dropdownItemSelected: {
        fontFamily: "Urbanist",
        borderRadius: "5px !important",

        fontSize: "12px",
        height: "30px",
        color: "#333"
    },
    caretDown: {
        borderRadius: "5px !important",
        color: "#333"
    }
};

export const detailsListTheme = createTheme({
    palette: {
        themePrimary: "#00b977",
        themeLighterAlt: "#00b029"
    }
});

export const dropdownStylesSpaceUserListV2: Partial<IDropdownStyles> = {
    root: {
        padding: "0",
        margin: "0",
        borderRadius: "5px",
        selectors: {
            ":focus": {
                borderRadius: "5px"
            },
            ":hover": {
                borderRadius: "5px"
            },
            ":active": {
                borderRadius: "5px"
            }
        }
    },
    label: {
        fontFamily: "Urbanist",
        fontSize: "7px",
        borderRadius: "5px"
    },
    title: {
        fontFamily: "Urbanist",
        borderRadius: "5px",
        fontSize: "12px",
        height: "30px"
    },

    dropdown: {
        borderRadius: "5px",
        selectors: {
            ":focus": {
                borderRadius: "5px !important",
                borderWidth: "2px !important",
                borderColor: "#333 !important"
            },
            ":hover": {
                borderRadius: "5px !important",
                borderWidth: "2px !important",
                borderColor: "#333 !important"
            },
            ":active": {
                borderRadius: "5px !important",
                borderWidth: "2px !important",
                borderColor: "#333 !important"
            },
            ":after": {
                borderRadius: "5px !important",
                borderWidth: "2px !important",
                borderColor: "#333 !important"
            },
            ":before": {
                borderRadius: "5px !important",
                borderWidth: "2px !important",
                borderColor: "#333 !important"
            }
        }
    },
    dropdownItem: {
        fontFamily: "Urbanist",
        borderRadius: "5px",
        fontSize: "12px",
        height: "30px"
    },

    dropdownItemSelected: {
        fontFamily: "Urbanist",
        borderRadius: "5px",
        fontSize: "12px",
        height: "30px"
    },
    caretDown: {
        borderRadius: "5px"
    }
};

export const dropdownStylesInvUser: Partial<IDropdownStyles> = {
    root: {
        padding: "0",
        margin: "0",
        borderRadius: "5px",
        selectors: {
            ":focus": {
                borderRadius: "5px"
            },
            ":hover": {
                borderRadius: "5px"
            },
            ":active": {
                border: "none !important",
                outline: "none !important"
            }
        }
    },

    label: {
        fontFamily: "Urbanist",
        fontSize: "13px",
        borderRadius: "5px"
    },
    title: {
        fontFamily: "Urbanist",
        borderRadius: "5px",
        fontSize: "12px",
        height: "30px"
    },

    dropdown: {
        borderRadius: "5px",
        selectors: {
            ":focus": {
                borderRadius: "5px !important",
                borderWidth: "2px !important",
                borderColor: "#333 !important"
            },
            ":hover": {
                borderRadius: "5px !important",
                borderWidth: "2px !important",
                borderColor: "#333 !important"
            },
            ":active": {
                borderRadius: "5px !important",
                borderWidth: "2px !important",
                borderColor: "#333 !important"
            },
            ":after": {
                borderRadius: "5px !important",
                borderWidth: "2px !important",
                borderColor: "#333 !important"
            },
            ":before": {
                borderRadius: "5px !important",
                borderWidth: "2px !important",
                borderColor: "#333 !important"
            }
        }
    },
    dropdownItem: {
        fontFamily: "Urbanist",
        borderRadius: "5px",
        fontSize: "12px",
        height: "30px"
    },

    dropdownItemSelected: {
        fontFamily: "Urbanist",
        borderRadius: "5px",
        fontSize: "12px",
        height: "30px"
    },
    caretDown: {
        borderRadius: "5px"
    }
};

export const textFieldStylesSpaceUserInvListV2 = {
    root: {
        borderRadius: "5px"
    },
    fieldGroup: {
        borderRadius: "5px",
        selectors: {
            "::after": {
                borderRadius: "5px ",
                border: "2px solid #404040"
            }
        }
    },
    subComponentStyles: {
        label: {
            root: {
                fontSize: "14px",
                fontFamily: "Urbanist"
            }
        }
    }
};

// Not Used, kept for reference
const clipIcon: IIconProps = {
    iconName: "Attach",
    styles: {
        root: {
            color: "#333333",
            transform: "rotate(-20deg)", // Tilt the icon by -20 degrees
            fontSize: "20px"
        }
    }
};
