﻿import React, { useState, PropsWithChildren } from "react";
import { IUploadResponse, uploadFileApi } from "./api";
import { guser, gloggedinuser, gcontentlist, gpagemap, gfile, ggroup, gselectedgroup, gcompusrs, gallusrs, gcompmbrshps, gactivecomp } from "./interfaces";
// Adjust the context to include filelist, an array of the same type as user
export const GlobalContext = React.createContext<{
    user: guser;
    loggedInUser: gloggedinuser;
    permissionLevels: { [key: number]: string };
    category: string;
    fileList: Array<gfile>;
    groupList: Array<ggroup>;
    selectedGroup: gselectedgroup;
    compUsrs: Array<gcompusrs>;
    allUsrs: Array<gallusrs>;
    compMbrshps: Array<gcompmbrshps>;
    isCalloutVisible: boolean;
    selectedFiles: File[];
    isLoading: boolean;
    uploadedFile: IUploadResponse | undefined;
    isYoutubeCalloutVisible: boolean;
    textYoutubeInput: string;
    isYoutubeLoading: boolean;
    activeComp: gactivecomp;
    currentP: number;
    sourceHighlighting: boolean;
    docAudio: boolean;
    setUser: React.Dispatch<React.SetStateAction<guser>>;
    setLoggedInUser: React.Dispatch<React.SetStateAction<gloggedinuser>>;
    setPermissionLevels: React.Dispatch<React.SetStateAction<{ [key: number]: string }>>;
    setCategory: React.Dispatch<React.SetStateAction<string>>;
    setFileList: React.Dispatch<React.SetStateAction<Array<gfile>>>;
    setGroupList: React.Dispatch<React.SetStateAction<Array<ggroup>>>;
    setSelectedGroup: React.Dispatch<React.SetStateAction<gselectedgroup>>;
    setCompUsrs: React.Dispatch<React.SetStateAction<Array<gcompusrs>>>;
    setAllUsrs: React.Dispatch<React.SetStateAction<Array<gallusrs>>>;
    setCompMbrshps: React.Dispatch<React.SetStateAction<Array<gcompmbrshps>>>;
    setIsCalloutVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedFiles: React.Dispatch<React.SetStateAction<File[]>>;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setUploadedFile: React.Dispatch<React.SetStateAction<IUploadResponse | undefined>>;
    setIsYoutubeCalloutVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setTextYoutubeInput: React.Dispatch<React.SetStateAction<string>>;
    setIsYoutubeLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setActiveComp: React.Dispatch<React.SetStateAction<gactivecomp>>;
    setCurrentP: React.Dispatch<React.SetStateAction<number>>;
    setSourceHighlighting: React.Dispatch<React.SetStateAction<boolean>>;
    setDocAudio: React.Dispatch<React.SetStateAction<boolean>>;
}>({
    user: { userDisplayName: "", userId: "", userMail: "", companyid: "", name: "", tier: 0, subscriptionid: "", status: "" },
    loggedInUser: { userId: "", mail: "", token: "" },
    permissionLevels: {},
    category: "",
    fileList: [], // Initial value for filelist
    groupList: [],
    selectedGroup: {
        selectionId: "",
        selectionText: "",
        permissionlevel: 0,
        description: "",
        sharewithcode: false,
        filecount: 0,
        custaiinst: "",
        custgreetingmessage: "",
        isintegrateddata: 0,
        allowyoutube: 1,
        allowweb: 1,
        enabledocaudio: 1,
        retrievecount: 5,
        sectionoverlap: 200,
        maxsectionlength: 3000,
        sentencesearchlimit: 200,
        inscope: 1,
        temperature: 0.7,
        responsetokenlimit: 3024,
        disablecitationhighlighting: 0,
        strictness: 3,
        invoicesgroup: 0,
        questionsuggestions: 0,
        nosidebysideview: 0,
        isowner: 0,
        dailymsgs: 0,
        premiumby: "",
        oid: 0,
        tier: 0,
        premwhen: 0,
        companyid: "",
        apikey: "",
        producecontentlist: 0,
        producequestions: 0,
        assistanttype: "",
        showexamplequestionsonstart: 0,
        headerimageurl: "",
        manualquestionsuggestions: "",
        usedocintel: 0,
        extractionfieldlist: ""
    },
    compUsrs: [],
    allUsrs: [],
    compMbrshps: [],
    isCalloutVisible: false,
    selectedFiles: [],
    isLoading: false,
    uploadedFile: undefined,
    isYoutubeCalloutVisible: false,
    textYoutubeInput: "",
    isYoutubeLoading: false,
    activeComp: { companyid: "", companyname: "", permissionlevel: 0, licensingkind: 0 },
    currentP: 0,
    sourceHighlighting: true,
    docAudio: true,
    setUser: () => {},
    setLoggedInUser: () => {},
    setPermissionLevels: () => {},
    setCategory: () => {},
    setFileList: () => {},
    setGroupList: () => {},
    setSelectedGroup: () => {},
    setCompUsrs: () => {},
    setAllUsrs: () => {},
    setCompMbrshps: () => {},
    setIsCalloutVisible: () => {},
    setSelectedFiles: () => {},
    setIsLoading: () => {},
    setUploadedFile: () => {},
    setIsYoutubeCalloutVisible: () => {},
    setTextYoutubeInput: () => {},
    setIsYoutubeLoading: () => {},
    setActiveComp: () => {},
    setCurrentP: () => {},
    setSourceHighlighting: () => {},
    setDocAudio: () => {}
});

export const GlobalProvider: React.FunctionComponent<PropsWithChildren<{}>> = ({ children }) => {
    const [user, setUser] = useState<guser>({
        userDisplayName: "",
        userId: "",
        userMail: "",
        companyid: "",
        name: "",
        tier: 0,
        subscriptionid: "",
        status: ""
    });
    const [loggedInUser, setLoggedInUser] = useState<gloggedinuser>({
        userId: "",
        mail: "",
        token: ""
    });
    const [permissionLevels, setPermissionLevels] = useState<{ [key: number]: string }>({ 1: "viewer", 2: "member", 3: "owner" });
    const [category, setCategory] = useState<string>("");
    const [fileList, setFileList] = useState<Array<gfile>>([]); // State for filelist
    const [groupList, setGroupList] = useState<Array<ggroup>>([]);
    const [selectedGroup, setSelectedGroup] = useState<gselectedgroup>({
        selectionId: "",
        selectionText: "",
        permissionlevel: 0,
        description: "",
        sharewithcode: false,
        filecount: 0,
        custaiinst: "",
        custgreetingmessage: "",
        isintegrateddata: 0,
        allowyoutube: 1,
        allowweb: 1,
        enabledocaudio: 1,
        retrievecount: 5,
        sectionoverlap: 200,
        maxsectionlength: 3000,
        sentencesearchlimit: 200,
        inscope: 1,
        temperature: 0.7,
        responsetokenlimit: 3024,
        disablecitationhighlighting: 0,
        strictness: 3,
        invoicesgroup: 0,
        questionsuggestions: 0,
        nosidebysideview: 0,
        isowner: 0,
        dailymsgs: 0,
        premiumby: "",
        oid: 0,
        tier: 0,
        premwhen: 0,
        companyid: "",
        apikey: "",
        producecontentlist: 0,
        producequestions: 0,
        assistanttype: "",
        showexamplequestionsonstart: 0,
        headerimageurl: "",
        manualquestionsuggestions: "",
        usedocintel: 0,
        extractionfieldlist: ""
    });
    const [compUsrs, setCompUsrs] = useState<Array<gcompusrs>>([]);
    const [allUsrs, setAllUsrs] = useState<Array<gallusrs>>([]);
    const [compMbrshps, setCompMbrshps] = useState<Array<gcompmbrshps>>([]);
    const [isCalloutVisible, setIsCalloutVisible] = useState<boolean>(false);
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [uploadedFile, setUploadedFile] = useState<IUploadResponse>();
    const [isYoutubeCalloutVisible, setIsYoutubeCalloutVisible] = useState<boolean>(false);
    const [textYoutubeInput, setTextYoutubeInput] = useState<string>("");
    const [isYoutubeLoading, setIsYoutubeLoading] = useState<boolean>(false);
    const [activeComp, setActiveComp] = useState<gactivecomp>({
        companyid: "",
        companyname: "",
        permissionlevel: 0,
        licensingkind: 0
    });
    const [currentP, setCurrentP] = useState<number>(0);
    const [sourceHighlighting, setSourceHighlighting] = useState<boolean>(true);
    const [docAudio, setDocAudio] = useState<boolean>(true);

    const value = {
        user,
        loggedInUser,
        permissionLevels,
        category,
        fileList,
        groupList,
        selectedGroup,
        compUsrs,
        allUsrs,
        compMbrshps,
        isCalloutVisible,
        selectedFiles,
        isLoading,
        uploadedFile,
        isYoutubeCalloutVisible,
        textYoutubeInput,
        isYoutubeLoading,
        activeComp,
        currentP,
        sourceHighlighting,
        docAudio,

        setUser,
        setLoggedInUser,
        setPermissionLevels, // Add setPermissionLevels to the value object
        setCategory,
        setFileList,
        setGroupList,
        setSelectedGroup,
        setCompUsrs,
        setAllUsrs,
        setCompMbrshps,
        setIsCalloutVisible,
        setSelectedFiles,
        setIsLoading,
        setUploadedFile,
        setIsYoutubeCalloutVisible,
        setTextYoutubeInput,
        setIsYoutubeLoading,
        setActiveComp,
        setCurrentP,
        setSourceHighlighting,
        setDocAudio
    };

    return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
};
